<template>
    <div class="flex flex-nowrap flex-grow-1 justify-content-end ml-4 mr-4">
        <div v-if="isUserImpersonated" class="bg-red w-full p-2 flex align-items-center justify-center">
            <span class="text-white">
                Impersonating ({{ userEmail }})
            </span>
            <ToggleButton class="ml-3" v-model="showDebugTools" onLabel="Show Debug Tools" offLabel="Hide Debug Tools">
            </ToggleButton>
            <ToggleButton class="ml-3" v-model="fullAccessMode" v-on:update:model-value="updateFullAccessMode($event)"
                :disabled="isLoading" onLabel="Full Access Mode" offLabel="Read Only Mode">
            </ToggleButton>
            <Button icon="pi pi-sign-out" class="ml-3" label="Stop Impersonating" @click="goToAdminPanel()"></Button>

        </div>
    </div>
    <Dropdown ref="profileDropdown" v-if="selectedProfile != undefined" v-model="selectedProfile" :options="profiles ?? []"
        class="mr-3" @change="selectProfile($event.value)">
        <template #value="slotProps">
            {{ slotProps.value.name }}
        </template>
        <template #option="slotProps">
            {{ slotProps.option.name }}
        </template>
        <template #footer>
            <div class="flex shadow-1">
                <Button class="p-button-link mx-auto my-2 p-0" label="View All" @click="goToProfilesPage()"></Button>
            </div>
        </template>
    </Dropdown>
    <Button icon="pi pi-user" class="p-button-rounded p-button-info" @click="toggle" />

    <OverlayPanel ref="userPanel">
        <a href="/Auth/SignOut">Sign Out</a>
    </OverlayPanel>
</template>

<script lang="ts" setup>
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import ToggleButton from 'primevue/togglebutton';
import { showDebugTools } from '@/services/user.service';

</script>

<script lang="ts">
import { defineComponent, ref } from "vue";
import profileService from "@/services/profile.service";
import userService from "@/services/user.service";

interface ProfileItem {
    id: string;
    name: string;
}

interface Data {
    selectedProfile?: ProfileItem;
    profiles?: ProfileItem[];
    isUserImpersonated: boolean;
    userEmail?: string;
    fullAccessMode: boolean;
    isLoading: boolean;
}

import type OverlayPanel2 from 'primevue/overlaypanel';
import adminService from '@/services/admin.service';
import { ProfileStatus } from '@/models/profile-item';
const userPanel = ref<OverlayPanel2>();

export default defineComponent({
    data(): Data {
        return {
            selectedProfile: undefined,
            profiles: undefined,
            isUserImpersonated: false,
            userEmail: undefined,
            fullAccessMode: false,
            isLoading: false
        };
    },
    async created() {
        const userInfo = await userService.getUserInfo();
        this.isUserImpersonated = userInfo?.isImpersonated == true;
        this.userEmail = userInfo?.email;
        this.fullAccessMode = userInfo?.fullAccessMode == true;

        userService.setProfileId(userInfo?.selectedProfileId);

        try {
            const profiles = await profileService.reloadProfiles();
            this.profiles = profiles
                .filter(a => a.status === ProfileStatus.Active || a.status === ProfileStatus.Onboarding)
                .map(a => ({
                    id: a.profileId.toString(),
                    name: `${a.accountInfo.name} ${a.countryCode}`
                }));
        } catch (e) {
            this.profiles = [];
        }

        this.selectedProfile = this.profiles.find(a => a.id === userInfo?.selectedProfileId);
    },
    methods: {
        toggle(event: any) {
            userPanel.value?.toggle(event);
        },
        async selectProfile(selectedProfile: ProfileItem) {
            const result = await profileService.switchProfile(selectedProfile.id);
            window.location.href = result.redirectUrl;
        },
        async goToAdminPanel() {
            this.$router.push({ name: "Admin" });
        },
        async goToProfilesPage() {
            (this.$refs.profileDropdown as Dropdown).hide();
            this.$router.push({ name: "SettingProfiles" });
        },
        async updateFullAccessMode(value: boolean) {
            this.isLoading = true;
            try {
                if (this.userEmail) {
                    await adminService.impersonateUser(this.userEmail, value);
                }
            } finally {
                this.isLoading = false;
            }
        }
    }
});
</script>

<style lang="scss" scoped>
a {
    color: black;
    text-decoration: none;
}
</style>