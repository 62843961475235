import axios from "axios";
import type { JobRecord, UserRecord } from '@/models/admin';
import userService from "./user.service";

export default {
    async isLoggedIn() {
        return await axios.get<boolean>(`/api/admin/is-logged-in`).then(r => r.data).catch(err => false);
    },
    async getJobs() {
        return await axios.get<JobRecord[]>(`/api/admin/jobs`).then(r => r.data);
    },
    async getUsers() {
        return await axios.get<UserRecord[]>(`/api/admin/users`).then(r => r.data);
    },
    async impersonateUser(email: string, fullAccessMode: boolean) {
        await axios.post(`/api/admin/impersonate-user`, { email: email, fullAccessMode: fullAccessMode });
        await userService.forceRefresh();
        return;
    },
};
