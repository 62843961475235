<template>
    <div class="nav min-h-screen">
        <div class="flex">
            <div>
                <img src="@/assets/adjoli-logo-white-teal-orange.png" height="180" />
            </div>
            <div v-if="layoutStep != OnboardStep.Login" class="ml-6 mt-6">
                <h2 class="text-white text-5xl m-0">Onboarding Wizard</h2>
                <h3 class="text-lg text-3xl mt-1">{{ text }}</h3>
            </div>
        </div>
        <div class="flex-grow-1">
            <div class="content px-6 py-4">
                <slot />
            </div>
            <div class="footer px-6 pb-3">
                <AppFooter />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import AppFooter from "./components/AppFooter.vue";
import { OnboardStep, layoutStep } from "@/services/layout.service";

const text = computed(() => {
    switch (layoutStep.value) {
        case OnboardStep.Login:
            return "Step 1: Login with Amazon";
        case OnboardStep.SelectProfile:
            return "Step 2: Select Profile";
        case OnboardStep.ConfigureProfile:
            return "Step 3: Configure Profile for Onboarding";
        default:
            return "";
    }
});
</script>

<style lang="scss">
.nav {
    background-color: #fec05e;
    min-width: 240px;
}

.content h1 {
    text-align: center;
}
</style>
