import type { IItem } from "./metrics.service";

export enum MetricType {
    Money = "money",
    Count = "count",
    Percentage = "percent",
}

export interface MetricItem {
    title: string;
    attribute: string;
    type: MetricType;
    value?: number;
    prevValue?: number;
    tooltip?: string;
    reverseIndicator?: boolean;
}

export const commonKPIs = {
    totalSales: {
        title: "Total Sales",
        attribute: "totalSales",
        type: MetricType.Money,
        tooltip: 'kpi.totalSales',
    } as MetricItem,
    spend: {
        title: "PPC Spend",
        attribute: "cost",
        type: MetricType.Money,
        tooltip: "kpi.adSpend",
        reverseIndicator: true,
    } as MetricItem,
    sales: {
        title: "PPC Sales",
        attribute: "attributedSales30d",
        type: MetricType.Money,
        tooltip: "kpi.adSales",
    } as MetricItem,
    organicSales: { // = total markeplace - ppc sales
        title: "Organic Sales",
        attribute: "organicSales",
        type: MetricType.Money,
        tooltip: "kpi.organicSales",
    } as MetricItem,
    impressions: {
        title: "Impressions",
        attribute: "impressions",
        type: MetricType.Count,
        tooltip: "kpi.impressions",
    } as MetricItem,
    clicks: {
        title: "Clicks",
        attribute: "clicks",
        type: MetricType.Count,
        tooltip: "kpi.clicks",
    } as MetricItem,
    orders: {
        title: "Orders",
        attribute: "attributedConversions30d",
        type: MetricType.Count,
        tooltip: "kpi.orders",
    } as MetricItem,
    acos: {
        title: "ACoS",
        attribute: "acos",
        type: MetricType.Percentage,
        tooltip: "kpi.acos",
        reverseIndicator: true,
    } as MetricItem,
    conversionRate: {
        title: "CVR (Orders)",
        attribute: "conversionRate",
        type: MetricType.Percentage,
        tooltip: "kpi.cvr_orders",
    } as MetricItem,
    grossProfit: { // = unit price * orders * gross margin
        title: "Gross Profit",
        attribute: "grossProfit",
        type: MetricType.Money,
        tooltip: "kpi.grossProfit",
    } as MetricItem,
    tacos: { // = ad spend / total sales
        title: "TACoS",
        attribute: "tacos",
        type: MetricType.Percentage,
        tooltip: "kpi.tacos",
        reverseIndicator: true,
    } as MetricItem
};

const GrossMarginSetting = 0.27;

function updateKPIs(data: IItem) {
    commonKPIs.spend.value = data.cost;
    commonKPIs.totalSales.value = data.totalSales;
    commonKPIs.sales.value = data.attributedSales30d;
    commonKPIs.impressions.value = data.impressions;
    commonKPIs.clicks.value = data.clicks;
    commonKPIs.orders.value = data.attributedConversions30d;
    commonKPIs.acos.value = data.cost / data.attributedSales30d;
    commonKPIs.conversionRate.value = data.attributedConversions30d / data.clicks;

    commonKPIs.organicSales.value = data.totalSales != undefined ? data.totalSales - data.attributedSales30d : undefined;
    commonKPIs.grossProfit.value = data.avgUnitPrice != undefined ? data.avgUnitPrice * data.attributedUnitsOrdered30d * GrossMarginSetting : undefined;
    commonKPIs.tacos.value = data.totalSales != undefined ? data.cost / data.totalSales : undefined;
}

function updatePrevKPIs(data?: IItem) {
    commonKPIs.spend.prevValue = data?.cost;
    commonKPIs.totalSales.prevValue = data?.totalSales;
    commonKPIs.sales.prevValue = data?.attributedSales30d;
    commonKPIs.impressions.prevValue = data?.impressions;
    commonKPIs.clicks.prevValue = data?.clicks;
    commonKPIs.orders.prevValue = data?.attributedConversions30d;
    commonKPIs.acos.prevValue = data === undefined ? undefined : data.cost / data.attributedSales30d;
    commonKPIs.conversionRate.prevValue = data === undefined ? undefined : data.attributedConversions30d / data.clicks;

    commonKPIs.organicSales.prevValue = data?.totalSales != undefined ? data.totalSales - data.attributedSales30d : undefined;
    commonKPIs.grossProfit.prevValue = data?.avgUnitPrice != undefined ? data.avgUnitPrice * data.attributedUnitsOrdered30d * GrossMarginSetting : undefined;
    commonKPIs.tacos.prevValue = data?.totalSales != undefined ? data.cost / data.totalSales : undefined;
}

export default {
    updateCommonKPIs(data: IItem, prev?: IItem) {
        updateKPIs(data);
        updatePrevKPIs(prev);
        return commonKPIs;
    },
};

export function formatCount(value: number | null) {
    if (value == null) {
        return "";
    } else if (value >= 1_000_000) {
        return (
            (value / 1_000_000).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) + "M"
        );
    } else if (value >= 1_000) {
        return (
            (value / 1_000).toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            }) + "K"
        );
    } else {
        return value.toLocaleString("en-US");
    }
}

export function formatCurrency(value: number | null, defaultValue: string = "") {
    if (value == null) {
        return defaultValue;
    }
    return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
}

export function formatValue(type: MetricType, value?: number) {
    if (value === undefined) return '';

    switch (type) {
        case MetricType.Money: return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        case MetricType.Count: return value >= 1_000_000 ? (value / 1_000_000).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + 'M' : value.toLocaleString("en-US");
        case MetricType.Percentage: return value.toLocaleString('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        default: return value.toString();
    }
}