<template>
    <div class="flex flex-wrap  mb-5" style="gap: 1.5rem">
        <key-indicator-item v-for="(metric, i) in metrics" :key="metric.attribute == '' ? i : metric.attribute"
            :title="metric.title" :type="metric.type" :value="metric.value" :prev-value="metric.prevValue"
            :active="metric.attribute == attribute" :disabled="metric.attribute == ''"
            :loading="metric.attribute == attribute && isLoading" :tooltip="metric.tooltip"
            :reverse-indicator="metric.reverseIndicator"
            @click="metric.attribute != '' ? $emit('update:attribute', metric.attribute) : null" />
    </div>
</template>

<script lang="ts" setup>
import KeyIndicatorItem from "./KeyIndicatorItem.vue";
</script>

<script lang="ts">
import type { MetricItem } from "@/services/kpi.service";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    props: {
        metrics: { type: Object as PropType<MetricItem[]>, required: true },
        attribute: { type: String, required: true },
        isLoading: { type: Boolean, default: true },
    },
    emits: ['update:attribute'],
});
</script>

<style scoped></style>
