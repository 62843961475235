<template>
    <Dialog :modal="true" v-model:visible="visible">
        <template #header>
            <h1>{{ title }}</h1>
        </template>
        <DataTable :value="searchTermRows" :paginator="true" :rows="5" responsiveLayout="scroll" sortField="cost"
            :sortOrder="-1" :rowsPerPageOptions="[5, 10, 25, 100]" :loading="isLoading">
            <template #header>
                <div class="flex">
                    <Menubar :model="bulkActions" class="extra-wide-menu" />
                </div>
            </template>
            <Column headerStyle="width: 3rem">
                <template #header>
                    <TriStateCheckbox v-model="allSelected" :binary="true">
                        <template #uncheckicon>
                            <i class="pi pi-minus" style="color: white; font-size: 0.75rem;"></i>
                        </template>
                    </TriStateCheckbox>
                </template>
                <template #body="slotProps">
                    <Checkbox v-model="selectionState[slotProps.data.searchTerm]" v-if="!slotProps.data.managedState"
                        :binary="true"></Checkbox>
                </template>
            </Column>
            <Column headerStyle="width: 24rem" field="searchTerm" header="Search Term" :sortable="true">
                <template #body="slotProps">
                    <div class="flex flex-nowrap">
                        <div>{{ slotProps.data.searchTerm }}</div>
                        <AdjoliStateIcon class="ml-2" :state="slotProps.data.managedState" />
                    </div>
                </template>
            </Column>
            <Column field="impressions" header="Impressions" :sortable="true"></Column>
            <Column field="clicks" header="Clicks" :sortable="true"></Column>
            <Column field="cost" header="Spend" :sortable="true">
                <template #body="slotProps">{{ formatCurrency(slotProps.data.cost) }}</template>
            </Column>
            <Column field="attributedSales30d" header="Sales" :sortable="true">
                <template #body="slotProps">{{ formatCurrency(slotProps.data.attributedSales30d) }}</template>
            </Column>
            <Column field="attributedConversions30d" header="Units Sold" :sortable="true"></Column>
            <Column field="acos" header="ACoS" :sortable="true">
                <template #body="slotProps">{{ formatAcos(slotProps.data.acos) }}</template>
            </Column>
        </DataTable>
    </Dialog>
</template>


<script lang="ts" setup>
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import AdjoliStateIcon from "@/components/AdjoliStateIcon.vue";
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Checkbox from "primevue/checkbox";
import Menubar from 'primevue/menubar';
</script>


<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { formatCurrency } from "@/services/kpi.service";
import type { ActionZoneProductRow, ActionZoneUpdateModel, KeywordActionZoneRow, SearchTermRow } from "@/models/action-zone";
import type { MenuItem } from "primevue/menuitem";
import ActionZoneService from "@/services/action-zone.service";

export const formatAcos = (value: any, fallback = '-') => {
    if (value == null || value == undefined) {
        return fallback;
    } else {
        return (value * 100).toFixed(0).toString() + '%';
    }
}

export default defineComponent({
    data() {
        return {
            isLoading: false,
            visible: true,
            selectionState: {} as { [key: string]: boolean },
            bulkActions: [] as MenuItem[],
        };
    },
    props: {
        actionRow: { type: Object as PropType<KeywordActionZoneRow>, required: true },
        productRow: { type: Object as PropType<ActionZoneProductRow<KeywordActionZoneRow>>, required: true },
        testMode: Boolean
    },
    computed: {
        title() {
            return this.actionRow.query.original;
        },
        searchTermRows() {
            return this.productRow.searchTerms.filter(x => x.searchTerm.includes(this.actionRow.query.original));
        },
        allSelected: {
            get() {
                let values = new Set(Object.values(this.selectionState));
                if (values.size > 0 && !values.has(false)) {
                    // Everything is selected
                    return true;
                } else if (values.size > 0 && values.has(true)) {
                    // Some are selected
                    return false;
                } else {
                    // Nothing selected
                    return null;
                }
            },
            set(value: boolean) {
                for (let key in this.selectionState) {
                    this.selectionState[key] = value === true || value === null;
                }
            }
        }
    },
    async created() {
        this.initSelectionState();
        this.bulkActions = [
            {
                label: 'Actions...',
                items: [
                    {
                        label: `Negative Exact Search Term(s)`,
                        disabled: false,
                        command: this.negativeExact
                    }
                ]
            }
        ];
    },
    methods: {
        initSelectionState() {
            this.selectionState = this.searchTermRows.reduce((map, searchTermRow) => {
                if (!searchTermRow.managedState) {
                    map[searchTermRow.searchTerm] = false;
                }
                return map;
            }, {} as { [key: string]: boolean });
        },
        formatAcos: formatAcos,
        async negativeExact() {
            let rowsToProcess = [] as SearchTermRow[];
            for (let row of this.searchTermRows) {
                if (this.selectionState[row.searchTerm] && (row.managedState === undefined || row.managedState === null)) {
                    rowsToProcess.push(row);
                }
            }

            if (rowsToProcess.length === 0) {
                return;
            }

            var result = confirm(`Are you sure you want to negative exact ${rowsToProcess.length} search term(s)?`);
            if (!result) {
                return;
            }

            this.isLoading = true
            try {
                var updateModels = rowsToProcess.map(row => ({
                    type: 'negative-exact',
                    productId: this.productRow.productId,
                    query: row.searchTerm,
                    dryRun: this.testMode,
                }) as ActionZoneUpdateModel);

                await ActionZoneService.approveRows(updateModels);

                // Remove the processed rows from the search terms. They can be viewed
                // in the negative exact action zone list in the future.
                this.productRow.searchTerms = this.productRow.searchTerms.filter(x => !rowsToProcess.includes(x));

                // Reset the selection state
                this.initSelectionState();
            }
            finally {
                this.isLoading = false;
            }
        }
    }
});
</script>

<style>
.extra-wide-menu .p-submenu-list {
    width: 16rem !important;
}
</style>