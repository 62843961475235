<template>
    <div class="container">
        <div class="left-section">
            <router-link :to="{ name: 'SettingHome' }">Basic Settings</router-link>
            <router-link :to="{ name: 'SettingProfiles' }">Profiles</router-link>
            <router-link :to="{ name: 'SettingBilling' }">Billing</router-link>
            <router-link v-if="showDebugTools" :to="{ name: 'SettingAdvanced' }">Advanced</router-link>
        </div>
        <div class="right-section">
            <router-view />
        </div>
    </div>
</template>

<script setup lang="ts" >
import { showDebugTools } from '@/services/user.service';
</script>

<script lang="ts">
import userService from '@/services/user.service';
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            userInfo: userService.expectUserInfo(),
        };
    },
});
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
}

.left-section {
    min-width: 200px;
}

.right-section {
    min-width: 600px;
}

.left-section a {
    display: block;
    margin-bottom: 1rem;
}
</style>
