<template>
    <div ref="chart" :class="[inline ? 'inline-block' : '']"></div>
</template>

<script lang="ts">
import Highcharts from 'highcharts';
import type { Chart, Options, ChartCallbackFunction } from 'highcharts';
import { defineComponent, type PropType } from 'vue';

function doCopy(copy: any, original: any, copyArray: any): any {
    // Callback function to iterate on array or object elements
    function callback(value: any, key: any) {
        // Copy the contents of objects
        if (Highcharts.isObject(value, !copyArray) &&
            !Highcharts.isClass(value) &&
            !Highcharts.isDOMElement(value)
        ) {
            copy[key] = doCopy(copy[key] || Highcharts.isArray(value) ? [] : {}, value, copyArray)
        } else {
            // Primitives are copied over directly
            copy[key] = original[key]
        }
    }

    if (Highcharts.isArray(original)) {
        original.forEach(callback)
    } else {
        Highcharts.objectEach(original, callback)
    }
    return copy
}

const copyObject = function (obj: any, copyArray: any) {
    return doCopy({}, obj, copyArray)
}

export default defineComponent({
    props: {
        constructorType: {
            type: String,
            default: 'chart'
        },
        options: {
            type: Object as PropType<Options>,
            required: true,
        },
        inline: { type: Boolean, default: false },
        callback: Object as PropType<ChartCallbackFunction>,
        updateArgs: {
            type: Array<boolean | undefined>,
            default: () => [true, true]
        },
        deepCopyOnUpdate: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            chart: undefined as Chart | undefined,
        }
    },
    watch: {
        options: {
            handler(newValue) {
                this.chart?.update(copyObject(newValue, this.deepCopyOnUpdate), ...this.updateArgs);
            },
            deep: true
        }
    },
    mounted() {
        // Check whether the chart configuration object is passed, as well as the constructor is valid.
        if (this.options && Highcharts.chart != null) {
            this.chart = Highcharts.chart(
                this.$refs.chart as HTMLElement,
                copyObject(this.options, true), // Always pass the deep copy when generating a chart. #80
                this.callback ? this.callback : undefined
            )
        } else {
            (!this.options) ? console.warn('The "options" parameter was not passed.') : console.warn(`'${this.constructorType}' constructor-type is incorrect. Sometimes this error is caused by the fact, that the corresponding module wasn't imported.`);
        }
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
});
</script>