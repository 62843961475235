<template>
    <div class="col-3 mx-auto">
        <q-list bordered separator class="bg-white">
            <q-item v-for="profile in profiles" clickable v-ripple @click="selectProfile(profile)">
                <q-item-section class="flex flex-row p-2">
                    <div class="mr-auto text-left">
                        <div>
                            <strong>{{ profile.accountInfo.name }} - {{ profile.countryCode }}</strong>

                            <span v-if="(profile.percentComplete ?? 1) < 1" :class="profileStatusColor(profile)"
                                class="ml-2">
                                {{ profile.percentComplete! * 100 }}%
                            </span>
                        </div>
                        <!-- <div>Ad Spend: <i class="pi pi-question-circle"></i></div> -->
                    </div>
                    <div class="flex align-items-center" :class="profileStatusColor(profile)">
                        <span class="mr-2">{{ profile.status }}</span>
                        <i class="pi text-3xl" :class="iconClass(profile)"></i>
                    </div>
                </q-item-section>
            </q-item>
        </q-list>
    </div>
</template>

<script lang="ts" setup>
</script>

<script lang="ts">
import { defineComponent } from "vue";
import profileService from "@/services/profile.service";
import type { ProfileItem } from "@/models/profile-item";
import { ProfileStatus } from "@/models/profile-item";
import { OnboardStep, layoutStep } from "@/services/layout.service";
import { notify } from "@kyvg/vue3-notification";

interface Data {
    profiles?: ProfileItem[];
    selectedProfile?: ProfileItem;
    refreshInterval?: number;
}

export default defineComponent({
    data(): Data {
        return {
            profiles: undefined,
            selectedProfile: undefined,
            refreshInterval: undefined,
        };
    },
    async created() {
        layoutStep.value = OnboardStep.SelectProfile;

        const profileId = this.$route.query.profileId;
        this.profiles = await profileService.reloadProfiles();
        this.selectedProfile = this.profiles.find(a => a.profileId.toString() == profileId) ?? this.profiles[0];

        // auto refresh for percent complete number
        if (this.anyOnboardProfiles()) {
            this.refreshInterval = setInterval(async () => {
                this.profiles = await profileService.reloadProfiles();

                if (!this.anyOnboardProfiles()) {
                    clearInterval(this.refreshInterval);
                }
            }, 30 * 1000);
        }
    },
    methods: {
        selectProfile: async function (profile: ProfileItem) {
            switch (profile.status) {
                case ProfileStatus.Active:
                    const result = await profileService.switchProfile(profile.profileId.toString());
                    window.location.href = result.redirectUrl;
                    return;
                case ProfileStatus.Inactive:
                    this.$router.push({ name: "Onboard", query: { profileId: profile.profileId } });
                    return;
                case ProfileStatus.Onboarding:
                    notify({ title: "Profile is currently being onboarded.", text: "Please wait until it's finished." });
                    return;
            }
        },
        iconClass: function (profile: ProfileItem) {
            switch (profile.status) {
                case ProfileStatus.Active: return 'pi-arrow-circle-right';
                case ProfileStatus.Inactive: return 'pi-wrench';
                case ProfileStatus.Onboarding: return 'pi-bolt';
            }
        },
        profileStatusColor: function (profile: ProfileItem) {
            switch (profile.status) {
                case ProfileStatus.Active: return 'text-green';
                case ProfileStatus.Inactive: return 'text-blue';
                case ProfileStatus.Onboarding: return 'text-orange';
            }
        },
        anyOnboardProfiles() {
            return this.profiles?.some(a => a.status == 'Onboarding');
        }
    },
});
</script>

<style lang="scss" scoped>
.p-listbox {
    height: 100%;
    overflow-y: scroll;
}
</style>
