<template>
    <div class="shadow-3">
        <Chart :options="chartOptions" />
    </div>
</template>

<script lang="ts" setup>
import Chart from "@/components/Chart.vue";
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import Highcharts, { type AnnotationsLabelsOptions, type AxisLabelsFormatterContextObject } from "highcharts";
import type { IDateRangeSelection, IGraphItem } from "@/services/metrics.service";
import { max, min } from "lodash-es";

import seriesLabel from "highcharts/modules/series-label";
seriesLabel(Highcharts);

import annotationsExtend from "highcharts/modules/annotations";
import { formatValue, MetricType } from "@/services/kpi.service";
annotationsExtend(Highcharts);

export interface IDataGrouping {
    name: string;
    data: IGraphItem[];
}

interface Data {
    chartOptions: Highcharts.Options;
}

export interface ChartAnnotation {
    date: Date;
    text: string;
}

export default defineComponent({
    props: {
        title: { type: String, required: true },
        type: { type: String as PropType<MetricType>, required: false },
        attribute: { type: String, required: true },
        data: { type: Object as PropType<Record<string, IGraphItem[]> | IDataGrouping[]>, required: false, default: undefined },
        dateRange: { type: Object as PropType<IDateRangeSelection>, required: false, default: undefined },
        showAnnotations: { type: Boolean, default: false },
        annotations: { type: Object as PropType<ChartAnnotation[]>, required: false, default: [] },
    },
    data(): Data {
        const self = this;

        return {
            chartOptions: {
                chart: {
                    numberFormatter: function (value: number, a, b, c) {
                        return self.type ? formatValue(self.type, value) : value.toString();
                    }
                },
                title: {
                    text: "",
                },
                yAxis: {
                    title: {
                        text: "",
                    },
                    labels: {
                        formatter: function (value: AxisLabelsFormatterContextObject) {
                            return self.type && typeof value.value == "number" ? formatValue(self.type, value.value) : value.value.toString();
                        }
                    },
                    type: "linear",
                },
                xAxis: [{
                    title: {
                        text: "Current Period",
                    },
                    type: "datetime",
                }, {
                    title: {
                        text: "Prev Period",
                        style: {
                            color: '#fec05e',
                        }
                    },
                    type: "datetime",
                    opposite: true
                }],
                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                    },
                },
                series: [],
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    layout: "horizontal",
                                    align: "center",
                                    verticalAlign: "bottom",
                                },
                            },
                        },
                    ],
                },
            },
        };
    },
    watch: {
        title: {
            handler(value: string) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.chartOptions.title!.text = value;
            },
            immediate: true
        },
        attribute: {
            handler(value: string) {
                const yAxisOptions = this.chartOptions.yAxis as Highcharts.YAxisOptions;

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                yAxisOptions.title!.text = value;
            },
            immediate: true
        },
        dateRange(value: IDateRangeSelection) {
            const xAxisOptions = this.chartOptions.xAxis as Array<Highcharts.XAxisOptions>;

            xAxisOptions[0].min = value?.value.start?.getTime() ?? null;
            xAxisOptions[0].max = value?.value.end?.getTime() ?? null;

            xAxisOptions[1].min = value?.prev?.start?.getTime() ?? null;
            xAxisOptions[1].max = value?.prev?.end?.getTime() ?? null;
        }
    },
    mounted() {
        this.$watch(() => [this.data, this.attribute], this.updateGraph);
        this.$watch(() => [this.data, this.attribute, this.annotations], this.updateAnnotations);
    },
    methods: {
        async updateGraph() {
            if (!this.data) {
                return;
            }

            const groupings = Array.isArray(this.data) ? this.data.map(a => [a.name, a.data] as [string, IGraphItem[]]) : Object.entries(this.data);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const values = groupings.flatMap(a => a.map(b => (b as any)[this.attribute] as number));
            let factor = (max(values) ?? 1) / (min(values) ?? 1);
            this.setYLinear(factor < 100);

            this.chartOptions.series = groupings.map(([name, data]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const dataSeries = data.map(a => [a.date.getTime(), (a as any)[this.attribute] as number]);
                const prevData = name.endsWith("- Last");
                const series: Highcharts.SeriesLineOptions = {
                    name: name,
                    type: "line",
                    data: dataSeries ?? [],
                    xAxis: prevData ? 1 : 0,
                    color: prevData ? '#fec05e' : undefined,
                }
                return series;
            });

            const xAxisOptions = this.chartOptions.xAxis as Array<Highcharts.XAxisOptions>;
            xAxisOptions[1].visible = this.chartOptions.series.some(a => a?.name?.endsWith("- Last"));
        },
        setYLinear(linear: boolean) {
            const yAxisOptions = this.chartOptions.yAxis as Highcharts.YAxisOptions;
            yAxisOptions.type = linear ? "linear" : "logarithmic";
        },
        updateAnnotations() {
            if (!this.data?.length || this.attribute == null)
                return;

            const groupings = Array.isArray(this.data) ? this.data.map(a => [a.name, a.data] as [string, IGraphItem[]]) : Object.entries(this.data);
            const data = groupings[0][1];
            const findYAxis = (x: Date) => {
                return (data.find(e => +e.date == +x) as any)?.[this.attribute] as number;
            }

            const labels: AnnotationsLabelsOptions[] = this.annotations.map(a => ({
                text: a.text,
                point: {
                    x: a.date.getTime(),
                    y: findYAxis(a.date) ?? 0,
                    xAxis: 0,
                    yAxis: 0
                }
            }));

            this.chartOptions.annotations = [{
                labels,
            }];
        },
    },
});
</script>

<style lang="scss" scoped>

</style>
