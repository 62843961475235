<template>
    <img v-tooltip="tooltip" class="adjoli-circle" src="@/assets/adjoli-appicon-01.png"
        :class="{ 'hidden': !isManaged(), 'grayscale': isPaused() }" />
</template>

<script lang="ts">

import { defineComponent, type PropType } from 'vue';
import { AmazonStateType } from '@/models/product-item';
import getTooltip from "@/services/tooltip.service";

export default defineComponent({
    props: {
        state: { type: String as PropType<AmazonStateType> }
    },
    methods: {
        getTooltip,
        isManaged() {
            return this.state === AmazonStateType.enabled || this.state === AmazonStateType.paused;
        },
        isPaused() {
            return this.state === AmazonStateType.paused;
        }
    },
    computed: {
        tooltip(): string {
            if (this.isPaused())
                return getTooltip('product.isPaused');

            if (this.isManaged())
                return getTooltip('product.isManaged');

            return '';
        }
    }
});

</script>

<style lang="scss" scoped>
.adjoli-circle {
    max-height: 1.5rem;
}

.grayscale {
    filter: grayscale(1);
}
</style>