import { markRaw, shallowRef, triggerRef } from "vue";
import type { ComponentCustomProps } from "vue";

const component = shallowRef<{
    component: any;
    props: any;
}>();

export default {
    show(modalComponent: ComponentCustomProps, params?: any) {
        component.value = markRaw({
            component: modalComponent,
            props: params,
        });
        triggerRef(component);
    },
    close() {
        component.value = undefined;
    },
    subscribe() {
        return component;
    },
};
