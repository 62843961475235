<template>
    <div class="bg-white col-8 col-offset-2 px-7 pb-5 shadow-1 text-left">
        <div v-if="profile" class="pt-2">
            <h2><strong>{{ profile.accountInfo.name }} - {{ profile.countryCode }}</strong></h2>
        </div>
        <ProfileCustomizeOptions v-if="isAuthorized != undefined" v-model="customize" :onboarding="true"
            :authorized="isAuthorized" />
        <div class="flex justify-between">
            <Button @click="goBack()" label="Back" icon="pi pi-angle-left"
                class="text-xl p-button-danger p-button-text -ml-3" />

            <Button v-if="isAuthorized" @click="startOnboard()" class="p-button-success p-button-lg" :disabled="loading"
                :loading="loading">
                Let's Go!
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ProfileCustomizeOptions from "@/components/ProfileCustomizeOptions.vue";
import Button from 'primevue/button';
</script>

<script lang="ts">
import { defineComponent } from "vue";
import profileService from "@/services/profile.service";
import { initEmptyProfileCustomOptions } from "@/models/profile-item";
import type { ProfileItem, ProfileCustomOptions } from "@/models/profile-item";
import { OnboardStep, layoutStep } from "@/services/layout.service";
import onboardService from "@/services/onboard.service";

interface Data {
    profile?: ProfileItem;
    loading: boolean;
    customize: ProfileCustomOptions;
    isAuthorized?: boolean;
}

export default defineComponent({
    data(): Data {
        return {
            profile: undefined,
            loading: false,
            customize: initEmptyProfileCustomOptions(),
            isAuthorized: undefined,
        };
    },
    async created() {
        layoutStep.value = OnboardStep.ConfigureProfile;

        const profileId = this.$route.query.profileId!.toString();
        this.profile = (await profileService.reloadProfiles()).find(a => a.profileId.toString() == profileId);
        this.isAuthorized = await profileService.checkIfAccountIsAuthorized(profileId);
    },
    methods: {
        goBack() {
            this.$router.push({ name: "Setup" });
        },
        startOnboard: async function () {
            try {
                this.loading = true;
                await onboardService.onboard(this.profile!, this.customize);
                this.$router.push({ name: "Setup" });
            } finally {
                this.loading = false;
            }
        }
    },
});
</script>