<template>
    <ProgressSpinner />
</template>

<script lang="ts" setup>
import ProgressSpinner from 'primevue/progressspinner';
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

