<template>
    <a v-if="link" :href="link" target="_blank">{{ asin }}</a>
    <span v-if="!link">{{ asin }}</span>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import ProfileService from '@/services/profile.service';
import UserService from '@/services/user.service';

export default defineComponent({
    props: {
        asin: { type: String }
    },
    data() {
        return {
            link: ''
        };
    },
    mounted() {
        var userInfo = UserService.expectUserInfo();
        var profiles = ProfileService.getCachedProfiles() || [];
        var selectedProfile = profiles.find(x => x.profileId.toString() == userInfo.selectedProfileId);

        if (selectedProfile?.countryCode == 'US') {
            this.link = `https://amazon.com/dp/${this.asin}`
        } else if (selectedProfile?.countryCode == 'CA') {
            this.link = `https://amazon.ca/dp/${this.asin}`
        } else if (selectedProfile?.countryCode == 'MX') {
            this.link = `https://amazon.com.mx/dp/${this.asin}`
        }
    }
});

</script>