<template>
    <div class="mx-5 flex justify-content-between align-items-center">
        <div class="d-block">
            <InputText type="text" v-model="filterString" placeholder="Enter value" style="width: 400px;" />
            <div class="inline ml-2">
                <Button label="30 days" @click="updateStartDate(30)" />
                <Button label="60 days" @click="updateStartDate(60)" class="ml-2" />
            </div>
        </div>
        <div>
            <label>
                <span>Threshold $ </span>
                <InputNumber v-model="dollarThreshold" :min="0" class="small-number"
                    @focus="e => (e.target as any).select()" />
            </label>
        </div>
        <div>
            <span class="mr-2">Unit Price: {{ formatCurrency(productInfo?.amount ?? 0) }}</span>
            <span>Target ACOS: {{ productInfo?.targetAcos }}%</span>
        </div>
    </div>

    <PlotFigure @click="legendClick" v-if="targetStats" :options="topPlotOptions" />
    <PlotFigure v-if="targetStats" :options="bottomPlotOptions" />
    <PlotFigure @click="legendClick" v-if="targetStats" :options="budgetPlotOptions" />
    <PlotFigure v-if="targetStats" :options="testPlotOptions" />

    <div>
        <q-table @row-click="tableRowClick" ref="table" v-if="targetStats" :rows="tableData" :columns="columns"
            :pagination="{ sortBy: 'spend', descending: true, rowsPerPage: 10 }">
            <template v-slot:top="props">
                <div>
                    Campaign Table</div>
                <q-space />
                <div v-if="tableSummary" class="flex">
                    <div v-if="tableSummary" class="mr-3">
                        Target:
                        {{ formatTwoFractions(tableSummary.targetRoas[0], tableSummary.targetRoas[1]) }} /
                        {{ formatTwoPercentage(tableSummary.targetAcos[0], tableSummary.targetAcos[1]) }}
                    </div>
                    <div class="mr-3">
                        Budget Util: {{ budgetUtil != null ? formatPercentage(budgetUtil) : 'undefined' }}
                    </div>
                    <div class="mr-3">
                        ACoS: {{ formatTwoPercentage(tableSummary.minAcos, tableSummary.maxAcos) }}
                    </div>
                    <div>
                        New ACoS:
                        {{ formatTwoPercentage(tableSummary.targetAcosBand[0], tableSummary.targetAcosBand[1]) }}
                    </div>
                </div>
            </template>
        </q-table>
    </div>
</template>

<script lang="ts" setup>
import * as Plot from "@observablehq/plot";
import PlotFigure from "../components/PlotFigure";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
</script>

<script lang="ts">
import type { IProductTargetStat, ICampaignBudgetStat, IBidEntry2, IProductTargetImpression } from '@/services/targetMetric.service';
import targetMetricService from '@/services/targetMetric.service';
import { defineComponent } from 'vue';
import type { PlotOptions } from "@observablehq/plot";
import { formatCurrency } from "@/services/kpi.service";
import { addDays, eachDayOfInterval, startOfDay, differenceInCalendarDays } from "date-fns";
import type { QTable, QTableProps } from "quasar";
import { plotRegression, linearRegressionBand } from "@/services/plot-modules";
import * as d3 from "d3";
import * as reg from "d3-regression";
import productService from "@/services/product.service";
import type { ProductItem } from "@/models/product-item";
import { differenceInDays } from "date-fns";

function round2(money: number) {
    return Math.round(money * 100) / 100;
}

function formatDeltaCurrency(money: number) {
    return money == 0 ? "" : formatCurrency(money).replace("$0.", "$.").replace("$", "Δ");
}

function formatPercentage(percent: number) {
    return percent > 9.99 ? '∞%' : percent.toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

function formatFraction(fraction: number) {
    const value = Math.round(fraction * 10) / 10;
    return value == 0 ? 'x' : value;
}

function formatTwoPercentage(a: number, b: number) {
    if (a > 9.99 && b > 9.99) return '∞%';
    return `${Math.round(a * 100)}-${formatPercentage(b)}`;
}

function formatTwoFractions(a: number, b: number) {
    return `${formatFraction(a)} - ${formatFraction(b)}x`;
}

function formatBid(rate: number) {
    return round2(rate).toFixed(2);
}

interface ITargetAd {
    bid: number;
    clicks: number;
    sales: number; // not used
    avgAdSpend: number;
    cvr?: number;
}

function calculateTargetBid(unitPrice: number, targetAcos: [number, number], currentAcos: [number, number], cvrBand: [number, number]):
    [[number, number], [number, number]] {
    const cvrMin = d3.min(cvrBand)!;
    const cvrMax = d3.max(cvrBand)!;

    const acosTargetMin = d3.min(targetAcos)!;
    const acosTargetMax = d3.max(targetAcos)!;

    const acosMin = d3.min([d3.min(currentAcos)!, acosTargetMin])!;
    const acosMax = d3.min([d3.max(currentAcos)!, acosTargetMax])!;

    const bidMin = round2(cvrMin * acosMax * unitPrice);
    const bidMax = round2(cvrMax * acosMin * unitPrice);

    const bids: [number, number] = [d3.min([bidMin, bidMax])!, d3.max([bidMin, bidMax])!];
    const acos: [number, number] = [acosMin, acosMax];
    return [bids, acos];
}

// (function () {
//     function assert(a: [number, number], b: [number, number]) {
//         console.log(a[0] == b[0] && a[1] == b[1], `${a} != ${b}`);
//     }

//     // within
//     assert(
//         calculateTargetBid(20, [0.5, 1.0], [0.4, 0.8], [0.1, 0.2]),
//         [1.6, 2.0]
//     );

//     // clamp top acos
//     assert(
//         calculateTargetBid(20, [0.5, 0.6], [0.4, 0.8], [0.1, 0.2]),
//         [1.6, 2.0]
//     );

//     // clamp bottom acos
//     assert(
//         calculateTargetBid(20, [0.3, 1.0], [0.4, 0.8], [0.1, 0.2]),
//         [1.6, 2.0]
//     );

//     // inside
//     assert(
//         calculateTargetBid(20, [0.6, 0.7], [0.4, 0.8], [0.1, 0.2]),
//         [1.6, 2.0]
//     );
// })();

const columns: QTableProps['columns'] = [
    {
        name: 'target',
        label: 'Target',
        field: 'keywordText',
        align: 'left',
    },
    {
        name: 'type',
        label: 'Type',
        field: 'matchType',
        sortable: true,
        align: 'left',
    },
    {
        name: 'profit',
        label: 'Profit',
        field: 'totalNetProfit',
        sortable: true,
        format: formatCurrency
    },
    {
        name: 'spend',
        label: 'Δ Spend',
        field: 'netSpend',
        sortable: true,
        format: formatDeltaCurrency
    },
    {
        name: 'origBid',
        label: 'Current Bid',
        field: 'currentBid',
        format: formatBid,
    },
    {
        name: 'cvr',
        label: 'CVR',
        field: 'currentCvr',
    },
    {
        name: 'roas',
        label: 'Current RoAS',
        field: 'currentRoas',
    },
    {
        name: 'origAcos',
        label: 'Current ACoS',
        field: 'currentAcos',
    },
    {
        name: 'targetAcos',
        label: 'Target ACoS',
        field: 'targetAcosString'
    },
    {
        name: 'bid',
        label: 'Target Bid',
        field: 'targetBid',
    },
    {
        name: 'bidDelta',
        label: 'Diff',
        field: 'targetBidDelta'
    }
];

export default defineComponent({
    props: {
        id: {
            required: true,
            type: String,
        },
    },
    data() {
        const today = addDays(new Date(), 0);
        return {
            productInfo: undefined as ProductItem | undefined,
            today: today,
            startDate: addDays(today, -30),
            endDate: addDays(today, 7),
            targetStats: undefined as IProductTargetStat[] | undefined,
            targetImpressions: undefined as IProductTargetImpression[] | undefined,
            targetBids: undefined as IBidEntry2[] | undefined,
            campaignBudgets: undefined as ICampaignBudgetStat[] | undefined,
            campaignTargetBids: undefined as { [k: string]: number | undefined } | undefined,
            filterString: 'healthy snacks - EXACT', // TODO: clear
            columns: columns,
            dollarThreshold: 20,
            keywordId: undefined as string | undefined,
            altView: false,
        };
    },
    async created() {
        this.targetStats = await targetMetricService.getProductTargetStats(this.id);
    },
    mounted() {
        this.$watch(() => [this.id], this.getProductInfo, { immediate: true });
        this.$watch(() => [this.filterString, this.targetStats], this.targetBidsAsync, { immediate: true });

        window.addEventListener('keydown', e => {
            if (e.shiftKey) {
                this.altView = !this.altView;
            }
        });
    },
    methods: {
        textFilter(d: IProductTargetStat) {
            const words = this.filterString.split(',').filter(a => a.length >= 3).map(a => a.trim().toLowerCase());
            return words.some(s => `${d.keywordText} - ${d.matchType}`.toLowerCase().startsWith(s))
        },
        tableRowClick(_evt: Event, row: any) {
            const key = `${row.keywordText} - ${row.matchType}`;
            this.filterString = key;
            console.log('row', row);
        },
        legendClick(event: Event) {
            const target = event.target as HTMLElement;
            if (!(typeof target.className == "string" && target.className.endsWith("-swatch"))) {
                return;
            }

            this.filterString = target.innerText;

            const table: QTable = this.$refs.table as any;
            const rowIndex = table?.filteredSortedRows.findIndex(this.textFilter);
            const perPage = table.pagination?.rowsPerPage ?? 5;
            table.setPagination({ page: Math.floor(rowIndex / perPage) + 1 });

            const tableElement = table.$el as HTMLElement;
            tableElement.querySelectorAll("tbody tr").forEach((a, i) =>
                i == rowIndex % perPage ? a.classList.add('selected') : a.classList.remove('selected'))
        },
        async targetBidsAsync() {
            const stat = this.targetStats?.find(a => this.textFilter(a));
            this.targetBids = undefined;

            if (!stat) {
                return;
            }

            [this.targetBids, this.campaignBudgets] = await Promise.all([
                targetMetricService.getProductTargetBids(this.id, stat.keywordText, stat.matchType),
                targetMetricService.getCampaignBudgets(stat.campaignId),
            ]);

            this.keywordId = stat.keywordId;
            targetMetricService.getProductTargetImpressions(this.id, stat.campaignId, this.keywordId).then(a => this.targetImpressions = a);

            const keywordIds = this.targetStats!
                .filter(a => a.campaignId == stat.campaignId)
                .map(a => a.keywordId);
            this.campaignTargetBids = await targetMetricService.getProductTargetLastBids(this.id, keywordIds);

            return this.targetBids;
        },
        async getProductInfo() {
            this.productInfo = await productService.getProductDetails(this.id);
        },
        updateStartDate(days: number) {
            this.startDate = addDays(this.today, -days);
        }
    },
    computed: {
        dateRange() {
            return [this.startDate, this.endDate];
        },
        campaignId() {
            const firstStat = this.targetStats?.find(a => this.textFilter(a));
            return firstStat?.campaignId;
        },
        budgetUtil() {
            if (!this.campaignBudgets) {
                return null;
            }

            const groups = d3.flatRollup(this.campaignBudgets, values => {
                return d3.max(values, a => a.budgetUtil)!;
            }, a => startOfDay(a.timeWindowStart));

            const end = addDays(this.today, -1);
            const start = addDays(end, -3); // 3 day weighted average

            const groups2 = groups
                .filter(([date, _]) => date >= start && date <= end)
                .map(a => ({
                    dateDiff: differenceInCalendarDays(a[0], start),
                    budgetUtil: a[1]
                }));

            if (groups2.length == 0) {
                return null;
            }

            const allSum = d3.sum(groups2, a => (a.dateDiff + 1) * a.budgetUtil);
            const denominator = d3.sum(groups2, a => (a.dateDiff + 1));
            return (allSum / denominator) / 100;
        },
        topPlotOptions(): PlotOptions {
            const _ = this.filterString;

            const keywordAmounts = d3.rollup(this.targetStats ?? [],
                (D) => d3.sum(D, a => a.cost) + d3.sum(D, a => a.attributedSales_30d),
                (d) => d.keywordId);

            const keywordIds = Array.from(keywordAmounts.entries()).filter(a => a[1] > this.dollarThreshold).map(a => a[0]);
            const stats = this.targetStats?.filter(a => keywordIds.includes(a.keywordId)) ?? [];

            return {
                width: 1280,
                caption: 'Net Profit',
                x: {
                    label: 'Date',
                    domain: this.dateRange,
                },
                y: {
                    grid: true,
                    label: 'Net Profit',
                },
                color: {
                    legend: true,
                },
                marks: [
                    Plot.ruleY([0]),
                    Plot.ruleX([this.today], {
                        stroke: 'red',
                        strokeDasharray: '10 2',
                    }),
                    Plot.lineY(stats, Plot.mapY('cumsum', {
                        x: 'timeWindowStart',
                        y: (d) => d.attributedSales_30d - d.cost,
                        stroke: (d) => `${d.keywordText} - ${d.matchType}`,
                        tip: 'xy',
                        sort: {
                            color: {
                                value: 'y', reduce: 'last', reverse: true
                            }
                        },
                        opacity: this.filterString.length >= 3 ? 0.5 : 1
                    })),
                    Plot.lineY(stats, Plot.mapY('cumsum', {
                        filter: this.textFilter,
                        x: 'timeWindowStart',
                        y: (d) => d.attributedSales_30d - d.cost,
                        stroke: (d) => `${d.keywordText} - ${d.matchType}`,
                        strokeWidth: 5,
                    })),
                    Plot.frame()
                ],
            };
        },
        bidPlotOptions(): PlotOptions {
            const bids = this.targetBids?.filter(a => a.keywordId == this.keywordId) ?? [];
            const impressions = this.targetImpressions ?? [];

            const stats = this.targetStats?.filter(this.textFilter)?.filter(a => a.keywordId == this.keywordId) ?? [];
            const clickStats = stats.flatMap(a => Array.from(
                { length: a.clicks },
                _ => ({ timeWindowStart: a.timeWindowStart, cost: a.cost, color: 'orange' })));

            let i = 0;
            const data = impressions.map(a => {
                const currentBid = bids[i];
                const nextBid = bids[i + 1];

                if (nextBid && nextBid.date < a.timeWindowStart) {
                    i++;
                    return undefined;
                }

                return {
                    timeWindowStart: a.timeWindowStart,
                    impressions: a.impressions,
                    placement: a.placement,
                    bid: currentBid?.bid,
                };
            }).filter(a => a != undefined);

            const groupedStats = d3.flatRollup(data,
                a => ({
                    timeWindowStart: startOfDay(a[0]!.timeWindowStart),
                    impressions: d3.sum(a, x => x!.impressions),
                    bid: a[0]!.bid,
                    placement: a[0]!.placement,
                }),
                d => d!.placement, d => startOfDay(d!.timeWindowStart)).map(a => a[2]);

            console.log(groupedStats);

            return {
                width: 1280,
                caption: 'Bid Stats',
                y: {
                    label: 'Count',
                    grid: true,
                },
                color: {
                    legend: true,
                },
                symbol: {
                    legend: true,
                },
                marks: [
                    Plot.ruleY([0]),
                    Plot.dot(groupedStats, { x: 'timeWindowStart', y: 'impressions', symbol: 'placement', stroke: 'bid', tip: true }),
                    Plot.lineY(groupedStats, { x: 'timeWindowStart', y: 'impressions', z: 'placement', strokeDasharray: '3 6' }),
                    Plot.dotX(clickStats, Plot.dodgeY({
                        x: 'timeWindowStart',
                        r: 'cost',
                        fill: 'color',
                        stroke: 'black',
                        sort: 'timeWindowStart',
                        tip: true,
                    })),
                    !this.targetBids ? null : Plot.text(this.targetBids.filter(a => a.date >= bids[0]?.date), {
                        title: d => formatCurrency(d.bid),
                        text: d => formatCurrency(d.bid),
                        dy: 10,
                        fontWeight: 'bold',
                        x: 'date',
                        frameAnchor: 'bottom',
                    }),
                ]
            };
        },
        bottomPlotOptions(): PlotOptions {
            const _ = this.filterString;
            const stats = this.targetStats?.filter(this.textFilter) ?? [];

            if (this.altView) {
                return this.bidPlotOptions;
            }

            const yesterday = addDays(this.today, -1);
            function cumulateStats(key: 'cost' | 'attributedSales_30d'): IProductTargetStat[] {
                const netStats = d3.cumsum(stats, a => a[key]);
                const cumulateArray = stats.map((a, i) => ({
                    ...a,
                    [key]: netStats[i],
                }));

                const lastValue = netStats[netStats.length - 1];
                if (cumulateArray.length > 0) {
                    const last = stats.slice(-1)[0];
                    const start = addDays(last.timeWindowStart, 1);
                    const end = yesterday;

                    if (end > start) {
                        eachDayOfInterval({
                            start: start,
                            end: end,
                        }).forEach(a => cumulateArray.push({
                            ...last,
                            timeWindowStart: a,
                            [key]: lastValue,
                        }));
                    }
                }

                return cumulateArray;
            }

            const netSpendStats = cumulateStats('cost');
            const netRevenueStats = cumulateStats('attributedSales_30d');

            const clickStats = stats.flatMap(a => Array.from(
                { length: a.clicks },
                _ => ({ timeWindowStart: a.timeWindowStart, cost: a.cost, color: 'orange' })));

            const saleStats = stats.flatMap(a => Array.from(
                { length: a.attributedConversions_30d },
                _ => ({ timeWindowStart: a.timeWindowStart, cost: a.attributedSales_30d, color: 'steelblue' })));

            const clickSaleStats = saleStats.concat(clickStats);

            return {
                width: 1280,
                caption: 'Ad Spend VS Revenue',
                x: {
                    label: 'Date',
                    domain: this.dateRange,
                },
                y: {
                    label: 'Dollars',
                    grid: true,
                },
                color: {
                    legend: true,
                },
                marks: [
                    Plot.ruleY([0]),
                    Plot.ruleX([this.today], {
                        stroke: 'red',
                        strokeDasharray: '10 2',
                    }),
                    Plot.dotX(clickSaleStats, Plot.dodgeY({
                        x: 'timeWindowStart',
                        r: 'cost',
                        fill: 'color',
                        sort: 'timeWindowStart',
                        tip: true,
                    })),
                    Plot.lineY(stats, Plot.mapY('cumsum', {
                        x: 'timeWindowStart',
                        y: 'cost',
                        stroke: d => "Spend",
                        tip: 'xy',
                    })),
                    Plot.lineY(stats, Plot.mapY('cumsum', {
                        x: 'timeWindowStart',
                        y: 'attributedSales_30d',
                        stroke: d => "Revenue",
                        tip: 'xy',
                    })),
                    Plot.line(netRevenueStats, plotRegression({
                        x: 'timeWindowStart',
                        y: 'attributedSales_30d',
                        strokeDasharray: '5 5',
                        type: 'loess',
                        bandwidth: 0.8,
                        // tip: true,
                    })),
                    Plot.line(netSpendStats, plotRegression({
                        x: 'timeWindowStart',
                        y: 'cost',
                        strokeDasharray: '5 5',
                        type: 'loess',
                        bandwidth: 0.5,
                        // tip: true,
                    })),
                    // Plot.crosshairX(this.targetStats, { x: 'timeWindowStart' }),
                    !this.targetBids ? null : Plot.text(this.targetBids, {
                        title: d => formatCurrency(d.bid),
                        text: d => formatCurrency(d.bid),
                        dy: 10,
                        fontWeight: 'bold',
                        x: 'date',
                        frameAnchor: 'bottom'
                    }),
                    Plot.frame()
                ]
            };
        },
        budgetPlotOptions(): PlotOptions {
            const _ = this.filterString;
            const stats = this.targetStats?.filter(a => this.campaignId && a.campaignId == this.campaignId) ?? [];

            function flattenGroup(data: IProductTargetStat[]): IProductTargetStat {
                return {
                    ...data[0],
                    timeWindowStart: startOfDay(data[0].timeWindowStart),
                    cost: d3.sum(data, a => a.cost),
                    attributedSales_30d: d3.sum(data, a => a.attributedSales_30d),
                    clicks: d3.sum(data, a => a.clicks),
                    attributedConversions_30d: d3.sum(data, a => a.attributedConversions_30d),
                };
            }

            const groupedStats = d3.flatRollup(stats,
                flattenGroup,
                d => `${d.keywordText} - ${d.matchType}`,
                a => startOfDay(a.timeWindowStart)).map(a => a[2]);

            return {
                width: 1280,
                caption: 'Budget Utilization',
                x: {
                    label: 'Date',
                    domain: this.dateRange,
                },
                y: {
                    label: 'Budget $',
                    grid: true,
                },
                color: {
                    legend: true,
                },
                marks: [
                    Plot.ruleY([0]),
                    Plot.ruleX([this.today], {
                        stroke: 'red',
                        strokeDasharray: '10 2',
                    }),
                    Plot.rectY(groupedStats, {
                        x: "timeWindowStart",
                        y: "cost",
                        interval: 'day',
                        fill: (d) => `${d.keywordText} - ${d.matchType}`,
                        tip: true,
                        sort: {
                            color: {
                                value: 'height', reduce: 'sum', reverse: false
                            }
                        },
                    }),
                    Plot.lineY(this.campaignBudgets ?? [], {
                        x: 'timeWindowStart',
                        y: 'budget',
                        interval: 'day',
                        // tip: 'x',
                        strokeDasharray: '5 5',
                    }),
                    Plot.frame()
                ]
            };
        },
        testPlotOptions(): PlotOptions {
            const _ = this.filterString;

            return {
                width: 1280,
                caption: 'Orders/Clicks',
                x: {
                    label: 'Clicks',
                },
                y: {
                    label: 'Orders',
                    grid: true
                },
                color: {
                    legend: true,
                },
                marks: [
                    Plot.ruleY([0]),
                    Plot.lineY(this.targetStats, Plot.map(
                        {
                            x: 'cumsum',
                            y: 'cumsum',
                        },
                        {
                            filter: this.textFilter,
                            x: 'clicks',
                            y: 'attributedConversions_30d',
                            tip: 'x',
                        }
                    )),
                    Plot.linearRegressionY(this.targetStats, Plot.map(
                        {
                            x: 'cumsum',
                            y: 'cumsum',
                        },
                        {
                            filter: this.textFilter,
                            x: 'clicks',
                            y: 'attributedConversions_30d',
                            stroke: 'red',
                        }
                    )),
                ]
            };
        },
        tableData() {
            if (!this.targetStats) {
                return;
            }

            const data = this.targetStats!;
            const indices = data.map((a, i) => i);

            const options = {
                x: 'timeWindowStart',
                z: (d: IProductTargetStat) => d.keywordId,
            }
            const netProfitTransformer = Plot.selectLast(Plot.mapY('cumsum', {
                y: (d: IProductTargetStat) => d.attributedSales_30d - d.cost,
                ...options
            }));

            const netRevenueTransformer = Plot.selectLast(Plot.mapY('cumsum', {
                y: (d: IProductTargetStat) => d.attributedSales_30d,
                ...options
            }));

            const netSpendTransformer = Plot.selectLast(Plot.mapY('cumsum', {
                y: (d: IProductTargetStat) => d.cost,
                ...options
            }));

            // const linRegTransformer: any = Plot.linearRegressionY(data, Plot.selectLast(Plot.map(
            //     {
            //         x: 'cumsum',
            //         y: 'cumsum',
            //     },
            //     {
            //         x: 'clicks',
            //         y: 'attributedConversions_30d',
            //         z: (d: IProductTargetStat) => `${d.keywordText} - ${d.matchType}`
            //     }
            // )));

            const ticksInDay = 86400000;
            const yesterday = addDays(this.today, -1);
            const netLookup = d3.rollup(this.targetStats!,
                stats => {
                    const key = `${stats[0].keywordText} - ${stats[0].matchType}`;
                    const costStats = stats.filter(a => a.cost != 0);
                    const lastDay = costStats.length > 0 ? costStats[costStats.length - 1] : undefined;

                    if (lastDay && differenceInDays(yesterday, lastDay!.timeWindowStart) > 0) {
                        eachDayOfInterval({
                            start: addDays(lastDay.timeWindowStart, 1),
                            end: yesterday,
                        }).forEach(a => costStats.push({
                            ...lastDay,
                            timeWindowStart: a,
                            cost: 0,
                        }));
                    }

                    const cumCost = d3.cumsum(costStats, a => a.cost);
                    const costRegression = reg.regressionLoess()
                        .bandwidth(0.5)
                        .x((i: number) => costStats[i].timeWindowStart)
                        .y((i: number) => cumCost[i])
                        (costStats.map((_, i) => i));

                    let costDiff = 0;
                    if (costRegression.length >= 2) {
                        const a = costRegression.slice(-1)[0];
                        const b = costRegression.slice(-2, -1)[0];
                        costDiff = (a[1] - b[1]) / ((a[0] / ticksInDay) - (b[0] / ticksInDay));
                    }

                    return {
                        costDiff,
                    };
                },
                d => `${d.keywordText} - ${d.matchType}`
            );

            const [timeResult] = [
                netProfitTransformer.transform(data, [indices]),
                // linRegTransformer.transform(data, [indices]),
                netRevenueTransformer.transform(data, [indices]),
                netSpendTransformer.transform(data, [indices])
            ];

            const netProfits: number[] = (netProfitTransformer as any).y.transform();
            const netRevenue: number[] = (netRevenueTransformer as any).y.transform();
            const netSpend: number[] = (netSpendTransformer as any).y.transform();

            const cvrLookup = d3.rollup(this.targetStats!,
                stats => {
                    const key = `${stats[0].keywordText} - ${stats[0].matchType}`;

                    const indices = stats.map((a, i) => i);
                    const clickStats = d3.cumsum(stats, a => a.clicks);
                    const orderStats = d3.cumsum(stats, a => a.attributedConversions_30d);
                    const bands = linearRegressionBand(indices, Array.from(clickStats), Array.from(orderStats));
                    const lastBand = bands.slice(-1)[0];
                    const lastBand2 = bands.slice(-2, -1)[0];

                    if (bands.length < 2 || (lastBand.y1 == 0 && lastBand.y2 == 0)) {
                        return [0, 1 / (lastBand.x + 1)];
                    }

                    // use difference to calculate slope, as y-intercept may not be at zero
                    const xdiff = lastBand.x - lastBand2.x;
                    return [
                        (lastBand.y2 - lastBand2.y2) / xdiff,
                        (lastBand.y1 - lastBand2.y1) / xdiff
                    ];
                },
                d => `${d.keywordText} - ${d.matchType}`
            );

            const targetAcos = this.productInfo?.targetAcos ? this.productInfo?.targetAcos / 100 : 0.30;
            const productPrice = this.productInfo?.amount ?? 0;

            const targetAcosRange: [number, number] = [targetAcos, targetAcos];

            return timeResult.facets![0].map(i => {
                const item = data[i];
                const key = `${item.keywordText} - ${item.matchType}`;
                const cvrBand = cvrLookup.get(key)!;
                const netValues = netLookup.get(key)!;
                const latestBid = this.campaignTargetBids?.[item.keywordId] ?? undefined;

                const minAcos = (latestBid ?? 0) / (cvrBand[1] * productPrice);
                const maxAcos = (latestBid ?? 0) / (cvrBand[0] * productPrice);

                const [[lowBid, highBid], newAcos] = calculateTargetBid(productPrice, targetAcosRange, [minAcos, maxAcos], [cvrBand[0], cvrBand[1]]);

                return {
                    campaignId: item.campaignId,
                    keywordText: item.keywordText,
                    matchType: item.matchType,
                    totalNetProfit: netProfits[i],
                    totalSpend: netSpend[i],
                    netSpend: netValues.costDiff,
                    totalRevenue: netRevenue[i],
                    currentBid: latestBid,
                    currentMinAcos: minAcos,
                    currentMaxAcos: maxAcos,
                    currentCvrBand: cvrBand,
                    currentCvr: formatTwoPercentage(cvrBand[0], cvrBand[1]).replace('0-', 'x-').replace('-', '/'),
                    currentAcos: formatTwoPercentage(minAcos, maxAcos),
                    currentRoas: formatTwoFractions(1 / maxAcos, 1 / minAcos),
                    currentMinRoas: d3.min([1 / maxAcos, 1 / minAcos].filter(a => a != 0))!,
                    targetBid: lowBid == highBid ? lowBid : `${lowBid == 0 ? 'x' : lowBid} - ${highBid}`,
                    targetBidDelta: round2(highBid - (latestBid ?? 0)),
                    targetAcos: newAcos,
                    targetAcosString: formatTwoPercentage(newAcos[0], newAcos[1]),
                };
            }).filter(a => this.campaignId && a.campaignId == this.campaignId);
        },
        tableSummary() {
            const data = this.tableData ?? [];

            const minRevenue = d3.sum(data.map(a => a.netSpend * (1 / a.currentMinAcos)).filter(a => a > 0.001));
            const maxRevenue = d3.sum(data.map(a => a.netSpend * (1 / a.currentMaxAcos)).filter(a => a > 0.001));

            const newMinRevenue = d3.sum(data.map(a => a.netSpend * (1 / a.targetAcos[0])).filter(a => a > 0.001));
            const newMaxRevenue = d3.sum(data.map(a => a.netSpend * (1 / a.targetAcos[1])).filter(a => a > 0.001));

            const netSpend = d3.sum(data, a => a.netSpend);

            const budgetTotal = d3.sum(data, a => a.netSpend);
            const impactData = data.filter(a => (a.netSpend / budgetTotal) >= 0.05);

            const acosArray1 = impactData.map(a => a.currentMinAcos).filter(a => a < 4);
            const acosArray2 = impactData.map(a => a.currentMaxAcos).filter(a => a < 4);
            const acosTarget1 = d3.quantile(acosArray1, 0.75) ?? 0;
            const acosTarget2 = d3.quantile(acosArray2, 0.75) ?? 0;

            return {
                targetAcos: [acosTarget1, acosTarget2],
                targetRoas: [1 / acosTarget2, 1 / acosTarget1],
                minAcos: netSpend / minRevenue,
                maxAcos: netSpend / maxRevenue,
                targetAcosBand: [netSpend / newMinRevenue, netSpend / newMaxRevenue],
            };
        }
    }
});
</script>

<style scoped>
:global(.p-inputnumber.small-number input) {
    width: 50px;
}
</style>