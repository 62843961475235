<template>Loading{{ elipses }}</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            halfSeconds: 0,
            intervalId: -1,
        };
    },
    computed: {
        elipses(): string {
            return ".".repeat((this.halfSeconds % 3) + 1);
        },
    },
    created() {
        this.intervalId = window.setInterval(() => (this.halfSeconds += 1), 500);
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
});
</script>
