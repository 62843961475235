<template>
    <DataTable v-model:filters="filters" :value="filteredKeywords" :paginator="true" :rows="10" filter-display="menu"
        :global-filter-fields="['keywordText']" :loading="keywords === undefined" sort-mode="multiple"
        :multi-sort-meta="multiSortMeta">
        <template #header>
            <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" type="text" placeholder="Target Search" />
                </span>
                <div class="align-self-center ml-auto">
                    <Checkbox inputId="showManagedOnly"
                        @change="managedOnly = !managedOnly; $emit('update:managedOnly', managedOnly)"
                        :modelValue="managedOnly" :binary="true" />
                    <label for="showManagedOnly">Show Managed Targets Only</label>
                </div>
                <div class="align-self-center ml-auto">
                    <SelectButton v-model="selectedCampaignType" :options="campaignTypeOptions" optionLabel="name"
                        optionValue="code" @update:model-value="$emit('update:campaignType', selectedCampaignType)" />
                </div>
            </div>
        </template>
        <Column field="keywordText" header="Target" :sortable="true">
            <template #body="slotProps">
                <div class="flex">

                    <Tag v-if="slotProps.data.targetType" :value="slotProps.data.targetType" class="mr-2"></Tag>

                    <span @click="selectKeyword(slotProps.data)">{{ slotProps.data.keywordText }}</span>
                    <AdjoliStateIcon class="ml-2" :state="slotProps.data.state" />
                </div>
            </template>
        </Column>
        <Column header="Target Types">
            <template #body="slotProps">
                <Tag value="B" rounded class="keyword-tag" title="Broad Keyword"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.KeywordTargetBroad]">
                </Tag>
                <Tag value="E" rounded class="keyword-tag" title="Exact Keyword"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.KeywordTargetExact]">
                </Tag>
                <Tag value="P" rounded class="keyword-tag" title="Phrase Keyword"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.KeywordTargetPhrase]">
                </Tag>
                <Tag value="PT" rounded class="keyword-tag" title="Exact Product"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.ProductTargetExact]">
                </Tag>
                <Tag value="CM" rounded class="keyword-tag" title="Auto Close Match"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.AutoCloseMatch]">
                </Tag>
                <Tag value="C" rounded class="keyword-tag" title="Auto Complements"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.AutoComplements]">
                </Tag>
                <Tag value="LM" rounded class="keyword-tag" title="Auto Loose Match"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.AutoLooseMatch]">
                </Tag>
                <Tag value="S" rounded class="keyword-tag" title="Auto Substitutes"
                    :class="keywordTagClass[slotProps.data.keywordText][TargetExpressionType.AutoSubstitutes]">
                </Tag>
            </template>
        </Column>
        <Column field="impressions" header="Impressions" :sortable="true">
            <template #body="slotProps">{{ formatCount(slotProps.data.impressions) }}</template>
        </Column>
        <Column field="clicks" header="Clicks" :sortable="true">
            <template #body="slotProps">{{ formatCount(slotProps.data.clicks) }}</template>
        </Column>
        <Column field="cost" header="Spend" :sortable="true">
            <template #body="slotProps">{{ formatCurrency(slotProps.data.cost) }}</template>
        </Column>
        <Column field="attributedSales30d" header="Sales" :sortable="true">
            <template #body="slotProps">{{ formatCurrency(slotProps.data.attributedSales30d) }}</template>
        </Column>
        <Column field="attributedUnitsOrdered30d" header="Orders" :sortable="true" />
    </DataTable>
</template>

<script setup lang="ts" >
import { showDebugTools } from '@/services/user.service';
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import DataTable from "primevue/datatable";
import type {
    DataTableFilterMetaData, DataTableSortMeta,
} from "primevue/datatable";
import Column from "primevue/column";
import Tag from 'primevue/tag';
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import { formatCount, formatCurrency } from "@/services/kpi.service";
import type { KeywordItemMetric } from "@/models/metrics";
import { CampaignMetricType } from "@/services/metrics2.service";
import Button from "primevue/button";
import Checkbox from 'primevue/checkbox';
import SelectButton from "primevue/selectbutton";
import AdjoliStateIcon from "./AdjoliStateIcon.vue";
import { TargetExpressionType, type KeywordItem, isAsin } from '@/models/keyword-item';
import { AmazonStateType } from '@/models/product-item';

export default defineComponent({
    components: {
        DataTable,
        Column,
        Tag,
        Button,
        Checkbox,
        SelectButton,
        InputText,
        AdjoliStateIcon,
    },
    props: {
        productId: { type: String, required: true },
        keywords: { type: Array as PropType<KeywordItemMetric[]>, required: false, default: undefined },
        originalData: { type: Array as PropType<KeywordItem[]>, required: false, default: undefined },
    },
    emits: ['update:managedOnly', 'update:campaignType'],
    data() {
        return {
            filters: {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                } as DataTableFilterMetaData
            },
            multiSortMeta: [
                { field: 'attributedSales30d', order: -1 },
                { field: 'clicks', order: -1 },
                { field: 'impressions', order: -1 },
            ] as DataTableSortMeta[],
            managedOnly: false,
            selectedCampaignType: CampaignMetricType.Product,
            campaignTypeOptions: [
                { name: "Sponsored Products", code: CampaignMetricType.Product },
                { name: "Sponsored Brands", code: CampaignMetricType.Brand },
            ]
        };
    },
    methods: {
        formatCurrency,
        formatCount,
        selectKeyword(metric: KeywordItemMetric) {
            if (!showDebugTools.value) {
                return;
            }

            this.$router.push({ name: "Keyword", params: { productId: this.productId, keywordText: metric.keywordText } })
        }
    },
    computed: {
        filteredKeywords() {
            return this.keywords?.filter(a => this.managedOnly ? a.isManaged : true);
        },
        keywordTagClass() {

            var asinTypes = [TargetExpressionType.ProductTargetExact];
            var keywordTypes = [
                TargetExpressionType.KeywordTargetBroad,
                TargetExpressionType.KeywordTargetExact,
                TargetExpressionType.KeywordTargetPhrase,
            ];
            var autoTypes = [
                TargetExpressionType.AutoCloseMatch,
                TargetExpressionType.AutoComplements,
                TargetExpressionType.AutoLooseMatch,
                TargetExpressionType.AutoSubstitutes
            ];


            return this.keywords?.reduce((map, keyword) => {
                var getClasses = (expressionType: TargetExpressionType) => {
                    if (keyword.keywordText === 'AUTO') {
                        if (!autoTypes.includes(expressionType)) {
                            return ['hidden'];
                        }
                    }
                    else if (isAsin(keyword.keywordText)) {
                        if (!asinTypes.includes(expressionType)) {
                            return ['hidden'];
                        }
                    }
                    else if (!keywordTypes.includes(expressionType)) {
                        return ['hidden'];
                    }

                    var managedKeyword = this.originalData?.find(x =>
                        ((keyword.keywordText === 'AUTO' && !x.keywordText) ||
                            (x.keywordText === keyword.keywordText))
                        && x.expressionType === expressionType);

                    if (managedKeyword?.isManaged) {
                        if (managedKeyword?.state === AmazonStateType.enabled) {
                            return ['opacity-100'];
                        } else {
                            return ['opacity-100', 'bg-grey']
                        }
                    } else {
                        return ['opacity-20'];
                    }
                };
                map[keyword.keywordText] = {
                    [TargetExpressionType.KeywordTargetBroad]: getClasses(TargetExpressionType.KeywordTargetBroad),
                    [TargetExpressionType.KeywordTargetExact]: getClasses(TargetExpressionType.KeywordTargetExact),
                    [TargetExpressionType.KeywordTargetPhrase]: getClasses(TargetExpressionType.KeywordTargetPhrase),
                    [TargetExpressionType.ProductTargetExact]: getClasses(TargetExpressionType.ProductTargetExact),
                    [TargetExpressionType.AutoCloseMatch]: getClasses(TargetExpressionType.AutoCloseMatch),
                    [TargetExpressionType.AutoComplements]: getClasses(TargetExpressionType.AutoComplements),
                    [TargetExpressionType.AutoLooseMatch]: getClasses(TargetExpressionType.AutoLooseMatch),
                    [TargetExpressionType.AutoSubstitutes]: getClasses(TargetExpressionType.AutoSubstitutes),
                };
                return map;
            }, {} as { [key: string]: { [key in TargetExpressionType]: string[] } }) ?? {};
        }
    }
});
</script>

<style>
.keyword-tag {
    min-width: 1.5rem !important;
    background: #2ca1aa !important;
    margin-left: 0.5rem;
    opacity: 0.25;
}
</style>