<template>
    <TabView v-bind:active-index="activeIndex" v-on:update:active-index="tabChanged($event)">
        <TabPanel header="Keywords">
            <p>
                This is the section where you can approve or reject all keyword suggestions from Adjoli. All suggestions
                are given to you on a per-product basis. Simply expand the subtable for each product to view all of our
                suggestions for it. After approving a keyword, Adjoli takes care of all of the heavy lifting to ensure
                that it's properly added into your campaign structure.
            </p>
            <SuggestionsTable />
        </TabPanel>
        <TabPanel header="Product Targets">
            <p>
                This is the section where you can approve or reject all product targeting suggestions from Adjoli. All
                suggestions are given to you on a per-product basis. Simply expand the subtable for each product to view
                all of our suggestions for it. If you wish to do so, you can click on the image to go to its Amazon
                listing and check for relevancy before approving it. After approving a product target, Adjoli takes care
                of all of the heavy lifting to ensure that it's properly added into your campaign structure.
            </p>
            <ProductTargetActionZone />
        </TabPanel>
        <TabPanel header="Budgets">
            <p>
                This is the section where you can approve or reject all budget suggestions. Adjoli routinely looks at
                campaign performance and suggests new budgets for you to set based on how much profit each campaign is
                generating. Since budget suggestions are heavily reliant on analyses of historical performance, it is
                recommended to approve/reject these suggestions as quickly as possible. If you wish for Adjoli to
                automate this process for you, you can enable it in your global settings.
            </p>
            <BudgetActionZone />
        </TabPanel>
        <TabPanel header="Negative Exacts">
            <p>
                In this section, you can approve or reject our negative exact keyword suggestions. We will suggest
                historically poor performing search terms for you to add as negative exact keywords so that you can
                block your ad dollars from being spent on them.
            </p>
            <NegativeExactTable />
        </TabPanel>
        <TabPanel header="Negative Phrases">
            <p>
                In this section, you can approve or reject our negative phrase keyword suggestions. Adjoli routinely
                analyzes your search term data and will suggest single words contained in historically poor performing
                search terms for you to add as negative phrase keywords into your campaigns. Use this tool to negate
                words or phrases that you are certain will not convert or are irrelevant to your product/brand.
            </p>
            <NegativePhraseTable />
        </TabPanel>
    </TabView>
</template>

<script lang="ts" setup>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import NegativePhraseTable from "@/components/action-zone/NegativePhrasesTable.vue";
import NegativeExactTable from "@/components/action-zone/NegativeExactsTable.vue";
import SuggestionsTable from "@/components/action-zone/KeywordSuggestionsTable.vue";
import BudgetActionZone from "@/components/action-zone/BudgetActionZone.vue";
import ProductTargetActionZone from "@/components/action-zone/ProductTargetActionZone.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";

const tabHashMap: { [key: number]: string } = {
    0: "keywords",
    1: "product-targets",
    2: "budgets",
    3: "negative-exacts",
    4: "negative-phrases"
};

export default defineComponent({
    data() {
        return {
            activeIndex: 0
        };
    },
    created() {

        var entry = Object.entries(tabHashMap).find(x => '#' + x[1] == this.$router.currentRoute.value.hash);
        if (entry) {
            this.activeIndex = Number.parseInt(entry[0]);
        }

    },
    methods: {
        tabChanged(index: number) {
            this.$router.replace(`/action-zone?#${tabHashMap[index]}`);
        }
    }
});
</script>

<style scoped>
[role="tabpanel"]>p {
    text-align: left;
    margin-top: 0;
    margin-bottom: 2rem;
    max-width: 1000px;
}
</style>