<template>
    <ul>
        <li>
            <router-link to="/dashboard" :class="{ active: isActive('/dashboard', true) }">
                <img class="icon" src="@/assets/icons/menu-inactive-overview.png" />
                Overview
            </router-link>
        </li>
        <li>
            <router-link to="/">
                <img class="icon" src="@/assets/icons/menu-performance-inactive.png" />
                Performance
            </router-link>
            <ul>
                <li>
                    <router-link to="/" :class="{ active: isActive('/', true) }">
                        Product Overview
                    </router-link>
                </li>
                <li>
                    <router-link to="/action-zone" :class="{ active: isActive('/action-zone', false) }">
                        Action Zone
                    </router-link>
                </li>
            </ul>
        </li>
        <!-- <li>
            <router-link to="/automation" :class="{ active: isActive('/automation') }">
                <img class="icon" src="@/assets/icons/menu-inactive-automation.png" />
                Automation
            </router-link>
        </li>-->
        <li>
            <router-link to="/settings" :class="{ active: isActive('/settings') }">
                <img class="icon" src="@/assets/icons/menu-inactive-account.png" />
                Account
            </router-link>
        </li>
    </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        isActive(url: string, exact = false) {
            const route = this.$router.currentRoute.value;
            return exact ? route.fullPath == url : route.fullPath.startsWith(url);
        }
    }
});
</script>

<style lang="scss" scoped>
ul {
    text-align: left;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 0.75rem;
    }
}

ul ul {
    margin-top: 0.75rem;
    margin-left: 3rem;
}

ul ul a {
    font-weight: normal;
}

a {
    text-decoration: none;
    color: #c5541b;
    font-weight: bold;

    &.active {
        color: white;
    }
}

.icon {
    max-height: 1rem;
    margin-right: 0.25rem;
}

a.active .icon {
    filter: brightness(0) invert(1);
}
</style>
