import type { IStats } from "@/models/metrics";
import axios from "axios";
import { formatISO, parse } from "date-fns";
import { groupBy, isDate, mapValues, meanBy, padStart, sortBy, sumBy } from "lodash-es";
import dateRangeService from "./date-range.service";
import type { IDateRange, IDateRangeSelection } from "./date-range.service";
import type { IOrderMetrics } from "./dashboard.service";

export type { IDateRange, IDateRangeSelection } from "./date-range.service";

export enum TargetType {
    Keyword = 'KW',
    Product = 'PT',
    Query = 'ST',
    Auto = 'A',
}

export interface IItem {
    impressions: number;
    clicks: number;
    cost: number;
    attributedConversions30d: number;
    attributedUnitsOrdered30d: number;
    attributedSales30d: number;

    avgUnitPrice?: number;
    totalSales?: number;
}

export interface IGraphItem extends IItem {
    date: Date;

    acos: number | null;
    conversionRate: number | null;
    targetType?: TargetType;
}

const GrossMarginSetting = 0.27;

const defaultDate = new Date(2010, 0, 1);
class GraphItem implements IGraphItem {
    date!: Date;

    impressions!: number;
    clicks!: number;
    cost!: number;
    attributedConversions30d!: number;
    attributedUnitsOrdered30d!: number;
    attributedSales30d!: number;

    // order metrics
    avgUnitPrice?: number;
    totalSales?: number;

    constructor(data: IGraphItem, date: string, orderMetric?: IOrderMetrics) {
        this.date = date == "total" ? null! : parse(date, "yyyyMMdd", defaultDate);
        this.impressions = data.impressions;
        this.clicks = data.clicks;
        this.cost = data.cost;
        this.attributedConversions30d = data.attributedConversions30d;
        this.attributedUnitsOrdered30d = data.attributedUnitsOrdered30d;
        this.attributedSales30d = data.attributedSales30d;

        if (orderMetric) {
            this.avgUnitPrice = orderMetric.averageUnitPrice.amount;
            this.totalSales = orderMetric.totalSales.amount;
        }
    }

    get acos() {
        return this.attributedSales30d == 0 ? null : this.cost / this.attributedSales30d;
    }

    get conversionRate() {
        return this.clicks == 0 ? null : this.attributedConversions30d / this.clicks;
    }

    get organicSales() {
        return this.totalSales != undefined ? this.totalSales - this.attributedSales30d : undefined;
    }

    get grossProfit() {
        return this.avgUnitPrice != undefined ? this.avgUnitPrice * this.attributedUnitsOrdered30d * GrossMarginSetting : undefined;
    }

    get tacos() {
        return this.totalSales != undefined ? this.cost / this.totalSales : undefined;
    }
}

export type RecordTable = {
    total: IGraphItem;
    [k: string]: IGraphItem;
};

export type RecordMap = Record<string, RecordTable>;

function dateToKey(date: Date | string) {
    const parsedDate = isDate(date) ? date : new Date(date);
    return [
        parsedDate.getFullYear(),
        padStart((parsedDate.getMonth() + 1).toString(), 2, '0'),
        padStart((parsedDate.getDate()).toString(), 2, '0'),
    ].join('');
}

function converOrderMetricsTable(orderMetrics: IOrderMetrics[]) {
    const orderMap = mapValues(groupBy(orderMetrics, a => dateToKey(a.startDate)), a => a[0]);
    orderMap.total = {
        averageUnitPrice: {
            amount: meanBy(Object.values(orderMap), a => a.averageUnitPrice.amount)
        },
        startDate: "",
        totalSales: {
            amount: sumBy(Object.values(orderMap), a => a.totalSales.amount),
        }
    };
    return orderMap;
}

export function mergeRecordMaps(...data: RecordMap[]) {
    const newMap: RecordMap = {};
    for (const [key, value] of data.flatMap(a => Object.entries(a))) {
        newMap[key] = value;
    }
    return newMap;
}

function mapRecordMap(data: RecordMap) {
    for (const [key, value] of Object.entries(data)) {
        data[key] = mapRecordTable(value);
    }
    return data;
}

function mapRecordTable(data: RecordTable, orderMetrics?: IOrderMetrics[]) {
    const orderMap = converOrderMetricsTable(orderMetrics ?? []);

    for (const [date, value] of Object.entries(data)) {
        data[date] = new GraphItem(value, date, orderMap[date] ?? undefined);
    }
    return data;
}

function getData<T>(url: string, range: IDateRange) {
    return axios.get<T>(url, {
        params: {
            start: formatISO(range.start, { representation: "date" }),
            end: formatISO(range.end, { representation: "date" }),
        },
    });
}

export default {
    // async getProfileData(range: IDateRange) {
    //     const [response, orderInfo] = await Promise.all([
    //         getData<RecordTable>(`/api/metrics/profile`, range),
    //         dashboardService.getOrderInfo(range)
    //     ]);

    //     return mapRecordTable(response.data, orderInfo);
    // },
    // async getProductData(range: IDateRange, productId: string) {
    //     const [response, orderInfo] = await Promise.all([
    //         getData<RecordTable>(`/api/metrics/product/${productId}`, range),
    //         dashboardService.getOrderInfo(range, productId)
    //     ]);

    //     return mapRecordTable(response.data, orderInfo);
    // },
    // async getProfileProducts(range: IDateRange) {
    //     const response = await getData<RecordMap>(`/api/metrics/profile/products`, range);
    //     return mapRecordMap(response.data);
    // },
    // async getProductKeywords(range: IDateRange, productId: string) {
    //     const response = await getData<RecordMap>(`/api/metrics/product/${productId}/keywords`, range);
    //     return mapRecordMap(response.data);
    // },
    // async getAllProductKeywordSuggestions(range: IDateRange) {
    //     const response = await getData<RecordMap>(`/api/metrics/profile/products/keywordSuggestions`, range);
    //     return mapRecordMap(response.data);
    // },
    getTop5(map: RecordMap) {
        return sortBy(Object.entries(map), ([_, data]) => data.total.attributedSales30d)
            .reverse()
            .slice(0, 5)
            .map(([key, _]) => key);
    },
    copyMetrics(item: IStats, data: IGraphItem) {
        item.impressions = data.impressions;
        item.clicks = data.clicks;
        item.cost = data.cost;
        item.attributedConversions30d = data.attributedConversions30d;
        item.attributedUnitsOrdered30d = data.attributedUnitsOrdered30d;
        item.attributedSales30d = data.attributedSales30d;
    },
    sumMetrics(item: IStats, items: IStats[]) {
        item.impressions = sumBy(items, (a) => a.impressions);
        item.clicks = sumBy(items, (a) => a.clicks);
        item.cost = sumBy(items, (a) => a.cost);
        item.attributedConversions30d = sumBy(items, (a) => a.attributedConversions30d);
        item.attributedUnitsOrdered30d = sumBy(items, (a) => a.attributedUnitsOrdered30d);
        item.attributedSales30d = sumBy(items, (a) => a.attributedSales30d);
    },
    convertToGraphData(data: RecordTable): IGraphItem[] {
        return Object.values(data).filter((a) => a.date != null); // filter "total"
    },
    getDateRange(): IDateRangeSelection {
        return dateRangeService.getDateRange();
    },
};