<template>
    <h1>Advanced Settings</h1>
    <div>
        <button @click="triggerDailyJobs">Trigger Daily Jobs</button>
        <button @click="redoCache">Redo Cache</button>
        <button @click="fixSkew">Fix Skew</button>
        <button @click="triggerBidUpdates">Trigger Bid Updates</button>
        <button @click="triggerDayparting">Trigger Dayparting</button>
        <SplitButton label="Reload Data" icon="pi pi-refresh" :model="dayOptions">
        </SplitButton>
    </div>
    <SQSEventsTable :data="queueEvents || []" style="margin-top: 50px" />
</template>

<script lang="ts" setup>
import SQSEventsTable from "@/components/SQSEventsTable.vue";
import SplitButton from 'primevue/splitbutton';
</script>

<script lang="ts">
import { defineComponent } from "vue";
import type { SQSEvent } from "@/models/event";
import userService from "@/services/user.service";
import advancedService from "@/services/advanced.service";
import type { MenuItem } from "primevue/menuitem";

interface Data {
    queueEvents?: SQSEvent[];
    dayOptions: MenuItem[];
}

export default defineComponent({
    data(): Data {
        return {
            queueEvents: undefined,
            dayOptions:
                [1, 7, 14, 30, 60, 90].map(days =>
                ({
                    label: days + ' days',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.processHistoricalData(days);
                    }
                })),
        };
    },
    async created() {
        this.queueEvents = await advancedService.querySQSEvents();
    },
    methods: {
        async triggerDailyJobs() {
            await advancedService.triggerDailyJobs();
        },
        async redoCache() {
            await advancedService.redoCache();
        },
        async processHistoricalData(days: number) {
            await advancedService.processHistoricalData(days);
        },
        async triggerBidUpdates() {
            await advancedService.triggerBidUpdates();
        },
        async fixSkew() {
            await advancedService.fixSkew();
        },
        async triggerDayparting() {
            await advancedService.triggerDayparting();
        }
    },
});
</script>

<style scoped>
button {
    margin-right: 0.5rem;
}
</style>