<template>
    <Dialog :modal="true" v-model:visible="visible" header="Bid History">
        <SelectButton v-model="selectedCampaign" :options="campaignOptions">
            <template #option="slotProps">
                <div :title="slotProps.option.campaignId">{{ slotProps.option.keywordType }}</div>
            </template>
        </SelectButton>

        <DataTable :value="items">
            <Column field="date" header="Date">
                <template #body="slotProps">{{ formatDate(slotProps.data.date) }}</template>
            </Column>
            <Column field="bid" header="Bid">
                <template #body="slotProps">{{ formatCurrency(slotProps.data.bid) }}</template>
            </Column>
            <Column field="bidDelta" header="Change" body-class="p-0">
                <template #body="slotProps">
                    <div class="p-3" :class="deltaChangeClass(slotProps.data.bidDelta)">
                        {{ formatCents(slotProps.data.bidDelta) }}
                    </div>
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SelectButton from "primevue/selectbutton";
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { isEqual, uniqWith } from "lodash-es";
import { format } from "date-fns";
import { formatCurrency } from "@/services/kpi.service";
import historyService, { type IHistoryRecord } from "@/services/history.service";

interface CampaignOption {
    campaignId: string;
    keywordType: string;
}

export default defineComponent({
    data() {
        return {
            visible: true,
            selectedCampaign: null as CampaignOption | null,
            campaignOptions: [] as CampaignOption[] | undefined,
            items: undefined as { keywordId: string, bid: number, bidDelta: number, date: Date }[] | undefined,
            allItems: undefined as IHistoryRecord[] | undefined,
        }
    },
    props: {
        keywordIds: { type: Object as PropType<string[]>, required: true }
    },
    async created() {
        const bids = await historyService.getBidHistory(this.keywordIds);
        this.allItems = bids.events;

        const metas = uniqWith(bids.events.map(a => a.metadata), isEqual);
        this.campaignOptions = metas;

        this.selectedCampaign = metas.length ? metas[0] : null;

    },
    watch: {
        selectedCampaign(campaignId: CampaignOption) {
            this.items = this.allItems?.filter(a => isEqual(a.metadata, campaignId)).map(a => ({
                keywordId: a.entityId,
                bid: parseFloat(a.newValue),
                bidDelta: parseFloat(a.newValue ?? 0) - parseFloat(a.previousValue ?? 0),
                date: new Date(a.timestamp)
            }));
        },
    },
    methods: {
        formatDate(date: Date) {
            return format(date, 'Pp');
        },
        formatCurrency: formatCurrency,
        formatCents(amount: number) {
            return `${amount > 0 ? '+' : '-'}${Math.abs(amount * 100).toFixed(0)}c`;
        },
        deltaChangeClass(amount: number) {
            const ranges = [0.05, 0.10, 0.20, Number.MAX_VALUE];
            const values = [50, 100, 200, 300];
            const index = ranges.findIndex(a => a >= Math.abs(amount));

            return `bg-${amount >= 0 ? 'orange' : 'blue'}-${values[index]}`;
        }
    }
});
</script>