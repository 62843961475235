const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tooltips: Record<string, any> = {
    kpi: {
        impressions: "An impression is counted each time your ad is shown on the Amazon Search Results page.",
        clicks: "The number of times your ads were clicked.",
        orders: "The total number of orders attributed to your ads in this marketplace. **Orders** and **Units Sold** differ in the sense that you can have multiple units of the same product within the same order.",
        unitsSold: "The total number of units sold.",
        avgCpc: "This is your **average cost per click**; the average amount of money spent per click in Sponsored Products and Sponsored Brands.",
        acos: "Stands for **Advertising Cost of Sales**. It is a ratio, represented as a percentage, of your ad spend over ad sales. ",
        roas: "Stands for **Return on Advertising Spend**. This is represented as either a number or percentage and is calculated by taking your ad sales and dividing it by your ad spend.",
        tacos: "Stands for **Total Advertising Cost of Sales**, commonly known in other industries as the **Advertising-to-Sales Ratio**. This is represented as a percentage and is calculated by taking your ad spend and dividing it by your total sales.",
        adSpend: "The amount of money spend on ads.",
        adSales: "The amount of sales, in the marketplace currency, that were attributed to all of your ads on Amazon.",
        organicSales: "All sales that came in organically from the Amazon Search Results pages. This does not include ad sales.",
        cvr_units: "This is your **unit session percentage** or **unit conversion rate**. In other words, this is the number of units you’ve sold divided by the number of clicks you got.",
        cvr_orders: "This is your **order conversion rate**. In other words, this is the number of PPC orders divided by the number of clicks you got.",
        cpu: "This is your **cost per unit sold**. This number tells you the average amount of money spent in order to sell one unit of your inventory",
        cpo: "This is your **cost per PPC order**. This number tells you the average amount of money spent in order to generate one order for your business.",
        grossProfit: "Profit after subtracting COGS from revenue. Does not take into account overhead, variable expenses, or other operational expenses.",
        totalSales: "Total sales in this marketplace. This includes both organic sales and ad sales.",
        adRevenuePercentage: "This number tells you what percentage of your total sales is comprised of ad sales. For example, if our total sales are $1,000 and our ad sales were $100 out of that $1,000, then our ad revenue % would be 10%.",
    },
    products: {
        overview: lorem,
    },
    product: {
        activeKeywords: lorem,
        isManaged: "Automation is enabled",
        isPaused: "Automation is paused",
    },
    onboarding: {
        customize: {
            pauseExistingCampaigns: lorem,
            automateBudgetRebalance: lorem,
            automateKeywordAdditions: lorem,
            automateNegativeExactKeywordAdditions: lorem,
            defaultTargetAcos: lorem,
            defaultBidMax: lorem,
        },
    },
};

function interpret(value: string) {
    return value?.replaceAll("**", "");
}

export default function getTooltip(identifier: string): string {
    const parts = identifier.split(".");

    if (parts.length == 1) {
        return interpret(tooltips[identifier]) ?? identifier;
    } else if (parts.length == 2) {
        return interpret(tooltips[parts[0]][parts[1]]) ?? identifier;
    } else if (parts.length == 3) {
        return interpret(tooltips[parts[0]][parts[1]][parts[2]]) ?? identifier;
    }

    return identifier;
}
