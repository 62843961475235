import { CampaignType, type ActionZoneProductRow, type ActionZoneRow, type BudgetActionZoneRow, type KeywordActionZoneRow } from "@/models/action-zone";
import { AmazonStateType } from "@/models/product-item";
import { addSeconds } from "date-fns";

export function generateTestData(isBudget: boolean) {
  var products = [] as ActionZoneProductRow<ActionZoneRow>[];

  var randomStateType = () => {
    if (Math.random() > 0.5) {
      return undefined;
    }
    return Math.random() > 0.5 ? AmazonStateType.enabled : AmazonStateType.paused;
  };

  for (var i = 1; i <= 10; i++) {
    products.push({
      actionRows: [],
      filteredActionRows: [],
      imageUrl: `https://picsum.photos/seed/random-${i}/50/50`,
      productName: 'Product ' + i,
      productId: i.toString(),
      productState: randomStateType() ?? AmazonStateType.enabled,
      sku: i.toString(),
      searchTerms: [],
    });

    if (isBudget) {
      var campaignTypes = [CampaignType.Auto, CampaignType.ExactKeywords, CampaignType.BroadKeywords, CampaignType.PhraseKeywords, CampaignType.ProductTargeting];
      for (var campaignType of campaignTypes) {
        for (var j = 0; j < 3; j++) {
          for (var k = 0; k < (j == 0 ? 1 : 5); k++) {
            var campaignBudget = Math.ceil(Math.random() * 100);
            var budgetRow: BudgetActionZoneRow = {
              productId: products[i - 1].productId,
              approved: j == 1,
              ignored: j == 2,
              isSelected: false,
              isLoading: false,
              managedState: AmazonStateType.enabled,
              campaignType: campaignType,
              campaignBudget: campaignBudget,
              dailyBudgetUtilization: {
                min: Math.random(),
                max: Math.random(),
                avg: Math.random()
              },
              suggestedBudget: Math.max(2, campaignBudget + Math.ceil(Math.random() * 20 - Math.random() * 30)),
              cost: Math.ceil(Math.random() * 10),
              clicks: Math.ceil(Math.random() * 100),
              attributedSales30d: Math.ceil(Math.random() * 10),
              acos: Math.random() > 0.5 ? Math.random() : undefined,
              lastActionDate: j == 0 ? undefined : addSeconds(new Date(), -1 * Math.random() * 1e7)
            };
            products[i - 1].actionRows.push(budgetRow);
          }
        }
      }
    } else {
      for (var j = 1; j <= 30; j++) {
        products[i - 1].searchTerms.push({
          searchTerm: 'prefix keyword"' + j,
          managedState: randomStateType(),
          attributedConversions30d: Math.ceil(Math.random() * 10),
          attributedUnitsOrdered30d: Math.ceil(Math.random() * 10),
          attributedSales30d: Math.ceil(Math.random() * 10),
          clicks: Math.ceil(Math.random() * 10),
          cost: Math.ceil(Math.random() * 10),
          impressions: Math.ceil(Math.random() * 10),
        });

        var approved = Math.random() > 0.5;
        var keywordRow: KeywordActionZoneRow = {
          productId: products[i - 1].productId,
          approved: approved,
          ignored: !approved && Math.random() > 0.5,
          query: {
            original: 'keyword"' + j,
            sanitized: 'keyword ' + j,
          },
          isSelected: false,
          isLoading: false,
          managedState: randomStateType(),
          initialBid: Math.ceil(Math.random() * 4),
          cost: Math.ceil(Math.random() * 10),
          clicks: Math.ceil(Math.random() * 100),
          impressions: Math.ceil(Math.random() * 100),
          attributedConversions30d: Math.ceil(Math.random() * 10),
          attributedSales30d: Math.ceil(Math.random() * 10),
          attributedUnitsOrdered30d: Math.ceil(Math.random() * 10),
          acos: Math.random() > 0.5 ? Math.random() : undefined,
        };
        products[i - 1].actionRows.push(keywordRow);
      }
    }
  }

  return products;
}