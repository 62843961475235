import type { App } from "vue";

/**
 * Handles for empty JSON that comes back as 204
 */
export function AxiosNoContentPlugin(app: App) {
    app.axios.interceptors.response.use(function (response) {
        if (response.status == 204) {
            response.data = undefined;
        }

        return response;
    });
}