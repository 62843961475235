<template>
    <KPIList v-model:attribute="attribute" :metrics="metrics" :is-loading="isLoading" />

    <MetricsChart :title="chartTitle" :attribute="attribute" :type="metricItemType" :data="data" :date-range="dateRange" />

    <div class="mt-3">
        <div class="inline mr-3">
            <Button v-if="topProducts" label="Top Sales" title="Select top products by sales" class="p-button-outlined"
                @click="selectTop()" />
        </div>

        <AutoComplete v-model="selectedProducts" placeholder="Select products..." :multiple="true"
            :suggestions="filteredAutoComplete" @complete="searchProducts($event)"
            @item-select="addProduct($event.value)" @item-unselect="removeProduct($event.value)" />

        <div class="inline ml-3">
            <Button title="Clear" icon="pi pi-times" class="p-button-rounded p-button-outlined"
                @click="clearProducts()" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import KPIList from "./KPIList.vue";
import MetricsChart from "./MetricsChart.vue";
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import metricsService from "@/services/metrics.service";
import type { IDateRangeSelection, IGraphItem, RecordMap } from "@/services/metrics.service";
import type { IDataGrouping } from "./MetricsChart.vue";
import kpiService, { commonKPIs } from "@/services/kpi.service";
import { isEqual } from "lodash-es";
import type { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import searchService from "@/services/search.service";
import metrics2Service, { CampaignMetricType } from "@/services/metrics2.service";

export default defineComponent({
    props: {
        dateRange: { type: Object as PropType<IDateRangeSelection>, required: true },
        autocomplete: { type: Array as PropType<string[]>, default: () => [] },
        topProducts: { type: Array as PropType<string[]>, required: false, default: undefined },
        productData: { type: Object as PropType<RecordMap>, required: true, default: () => ({}) },
        productIds: { type: Array as PropType<string[]>, required: false, default: undefined },
    },
    data() {
        return {
            isLoading: false,
            attribute: "impressions",
            metrics: Object.values(commonKPIs),
            profileData: [] as IGraphItem[],
            profileDataLast: [] as IGraphItem[],
            data: [] as IDataGrouping[],
            selectedProducts: [] as string[],
            filteredAutoComplete: [] as string[],
        };
    },
    computed: {
        chartTitle() {
            let identifier: string;
            if (this.selectedProducts.length == 0) {
                identifier = "Marketplace";
            } else if (isEqual(this.selectedProducts, this.topProducts)) {
                identifier = "Top Products";
            } else {
                identifier = "Products";
            }

            return `${identifier} by ${this.attribute}`;
        },
        metricItemType() {
            return Object.values(commonKPIs).find(a => a.attribute == this.attribute)?.type;
        }
    },
    mounted() {
        this.$watch(() => [this.dateRange, this.productIds], this.getGraphData, { immediate: true });
    },
    watch: {
        productData() { this.updateGraph(); },
        selectedProducts: {
            handler(products: string[]) {
                this.toggleProfileData(products.length == 0);
            },
            immediate: true
        }
    },
    methods: {
        async getGraphData() {
            // const data = await metricsService.getProfileData(this.dateRange.value);
            const data = await metrics2Service.getOneProfileData(this.dateRange.value, this.productIds, CampaignMetricType.Product);
            this.profileData = metricsService.convertToGraphData(data);

            // const lastData = await metricsService.getProfileData(this.dateRange.prev!);
            const lastData = await metrics2Service.getOneProfileData(this.dateRange.prev!, this.productIds, CampaignMetricType.Product);
            this.profileDataLast = metricsService.convertToGraphData(lastData);

            this.updateGraph();

            kpiService.updateCommonKPIs(data.total, lastData.total);
            this.metrics = { ...this.metrics }; // force kpi's to refresh
        },
        async updateGraph() {
            if (this.selectedProducts.length == 0) {
                this.toggleProfileData(true);
            } else {
                this.selectedProducts.forEach(a => this.addProduct(a))
            }
        },
        searchProducts(event: AutoCompleteCompleteEvent) {
            this.filteredAutoComplete = searchService.filterSuggestions(this.autocomplete, event.query);
        },
        async toggleProfileData(show: boolean) {
            if (show) {
                this.data = [{ name: 'Marketplace', data: this.profileData }, { name: 'Marketplace - Last', data: this.profileDataLast }];
            } else {
                this.data = [...this.data.filter(a => !a.name.startsWith('Marketplace'))];
            }
        },
        async addProduct(productId: string) {
            const productData = (this.productData ?? {})[productId];
            const data = metricsService.convertToGraphData(productData ?? {});

            this.removeProduct(productId);
            this.data.push({
                name: productId,
                data,
            });
        },
        removeProduct(productId: string) {
            this.data = [...this.data.filter(a => a.name != productId)];
        },
        selectTop() {
            this.selectedProducts = this.topProducts ?? [];
            this.updateGraph();
        },
        clearProducts() {
            this.selectedProducts = [];
            this.updateGraph();
        }
    },
});
</script>

<style lang="scss" scoped>

</style>
