import type { AmazonStateType } from "./product-item";

export interface SanitizedKeyword {
    original: string;
    sanitized: string;
}

export interface TargetMetrics {
    impressions: number;
    clicks: number;
    cost: number;
    attributedConversions30d: number;
    attributedUnitsOrdered30d: number;
    attributedSales30d: number;
    acos?: number;
}

export interface ActionZoneProductRow<T extends ActionZoneRow> {
    productId: string;
    imageUrl: string;
    sku: string;
    productName: string;
    productState: AmazonStateType;
    actionRows: T[];
    searchTerms: SearchTermRow[];
    // Client side only data    
    filteredActionRows: T[];
}

export type ActionZoneRowState = 'pending' | 'approved' | 'ignored';

export interface ActionZoneRow {
    ignored: boolean;
    approved: boolean;
    managedState?: AmazonStateType;

    // Client side value only
    productId: string,
    isSelected: boolean
    isLoading: boolean
}

export interface KeywordActionZoneRow extends ActionZoneRow, TargetMetrics {
    query: SanitizedKeyword;
    initialBid?: number;
}

export enum CampaignType {
    Auto = 'Auto',
    ProductTargeting = 'ProductTargeting',
    ExactKeywords = 'ExactKeywords',
    PhraseKeywords = 'PhraseKeywords',
    BroadKeywords = 'BroadKeywords'
}

export interface BudgetSuggestion {
    campaignType: CampaignType,
    campaignBudget?: number;
    suggestedBudget?: number;

    clicks: number;
    cost: number;
    attributedSales30d: number;
    acos?: number;

    // The last time an action was taken on this row
    lastActionDate?: Date;
    dailyBudgetUtilization: BudgetUtilization;
}

export interface BudgetActionZoneRow extends ActionZoneRow, BudgetSuggestion {
}

export interface BudgetUtilization {
    min: number;
    max: number;
    avg: number;
}

export interface ActionZoneUpdateModel {
    type: string;
    productId: string;
    dryRun: boolean;

    // For keywords
    query?: string;
    initialBid?: number;

    // For budgets
    budgetSuggestion?: BudgetSuggestion;
}

export interface SearchTermRow extends TargetMetrics {
    searchTerm: string;
    managedState?: AmazonStateType;
}
