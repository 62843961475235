import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import userService from "@/services/user.service";
import adminService from "@/services/admin.service";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from) => {
  // Admin login
  if (to.name == "Admin") {
    const result = await adminService.isLoggedIn();
    if (!result) {
      window.location.href = "/api/admin/login";
    }
    return;
  }

  const userInfo = await userService.getUserInfo();
  const loggedIn = !!userInfo;
  const onboarded = userInfo && userInfo.selectedProfileId;

  // on the login page?
  if (to.name == "Login") {
    if (loggedIn) return "/";
    else return;
  } else if (to.name == "Setup") {
    return;
  }

  // elsewhere
  if (!loggedIn) return "/login";
  else if (!onboarded && !(to.name == "Setup" || to.name == "Onboard")) return "/setup";
});

export default router;
