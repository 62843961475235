<template>
    <div class="flex justify-content-between">
        <div>
            <div class="flex">
                <h1>
                    {{ product?.sku ?? id }}: Overview
                </h1>
            </div>
            <p>{{ product?.title }}</p>
        </div>

        <DateRangePicker v-model="dateRange" />
    </div>

    <div>
        <KeywordGraph :id="id" :date-range="dateRange" :autocomplete="autocomplete" :top-keywords="topKeywords"
            :keyword-data="keywordMetrics" :product-image="product?.imageUrl" />
    </div>

    <div>
        <div class="flex">
            <h2>
                Active Targets
                <Tooltip id="products.overview" />
            </h2>
        </div>

        <KeywordTable :productId="id" :keywords="data" :originalData="originalData" v-model:campaign-type="campaignType" />
    </div>
</template>

<script lang="ts" setup>
import { CampaignMetricType as CampaignMetricType2 } from "@/services/metrics2.service";
import { showDebugTools } from '@/services/user.service';
</script>

<script lang="ts">
import { defineComponent } from "vue";
import productService from "@/services/product.service";
import metricsService, { mergeRecordMaps, TargetType } from "@/services/metrics.service";
import type { IDateRange, IGraphItem, RecordMap } from "@/services/metrics.service";
import Tooltip from "@/components/Tooltip.vue";
import type { KeywordItemMetric } from "@/models/metrics";
import { TargetExpressionType, type KeywordItem } from "@/models/keyword-item";
import DateRangePicker from "@/components/DateRangePicker.vue";
import KeywordTable from "@/components/KeywordTable.vue";
import KeywordGraph from "@/components/KeywordGraph.vue";
import Button from 'primevue/button';
import { difference } from "lodash-es";
import historyService from "@/services/history.service";
import metrics2Service, { CampaignMetricType } from "@/services/metrics2.service";
import { AmazonStateType, type ProductItem } from "@/models/product-item";
import DaypartingModal from "@/components/modals/DaypartingModal.vue";
import BudgetHistoryModalV2 from "@/components/modals/BudgetHistoryModalV2.vue";

const nullMetric: IGraphItem = {
    date: null!,
    impressions: 0,
    clicks: 0,
    cost: 0,
    attributedConversions30d: 0,
    attributedUnitsOrdered30d: 0,
    attributedSales30d: 0,
    acos: null,
    conversionRate: null,
};

export default defineComponent({
    components: {
        Tooltip,
        DateRangePicker,
        KeywordTable,
        KeywordGraph,
        Button
    },
    props: {
        id: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            product: undefined as ProductItem | undefined,
            dateRange: metricsService.getDateRange(),
            data: undefined as KeywordItemMetric[] | undefined,
            originalData: undefined as KeywordItem[] | undefined,
            managedTargets: undefined as KeywordItem[] | undefined,
            keywordMetrics: undefined as RecordMap | undefined,
            campaignType: CampaignMetricType.Product,
        };
    },
    computed: {
        autocomplete(): string[] {
            return this.managedTargets?.map(x => x.keywordText) ?? [];
        },
        topKeywords(): string[] {
            return metricsService.getTop5((this.keywordMetrics ?? {}) as RecordMap);
        },
    },
    async created() {
        [this.product, this.managedTargets] = await Promise.all([
            productService.getProductDetails(this.id),
            productService.getManagedTargets(this.id)
        ]);
    },
    mounted() {
        this.$watch(() => [this.managedTargets, this.keywordMetrics], this.update);
        this.$watch(() => [this.dateRange, this.campaignType], this.getData, { immediate: true });
    },
    methods: {
        update() {
            // Some of the managed keywords will not have metrics. Also handle for duplicates.
            var allKeywords = new Set(Object.keys(this.keywordMetrics ?? {}).concat(this.managedTargets?.filter(x => x.keywordText).map(x => x.keywordText) ?? []));

            this.data = [];
            allKeywords.forEach((keywordText) => {
                const totalMetric = (this.keywordMetrics?.[keywordText]?.total ?? nullMetric) as IGraphItem;

                let managedTargets = (this.managedTargets ?? []);

                // AUTO is a special keyword that has a different way of managing targets
                if (keywordText === 'AUTO') {
                    managedTargets = managedTargets.filter(x =>
                        x.expressionType == TargetExpressionType.AutoCloseMatch ||
                        x.expressionType == TargetExpressionType.AutoComplements ||
                        x.expressionType == TargetExpressionType.AutoLooseMatch ||
                        x.expressionType == TargetExpressionType.AutoSubstitutes
                    );
                } else {
                    managedTargets = managedTargets.filter(x => x.keywordText === keywordText);
                }

                let isManaged = managedTargets.length > 0;
                var keywordItem = {
                    keywordText: keywordText,
                    isManaged: isManaged,
                    state: isManaged
                        ? (managedTargets.some(x => x.state == AmazonStateType.enabled) ? AmazonStateType.enabled : AmazonStateType.paused)
                        : undefined,
                    expressionType: undefined,
                    targetType: totalMetric.targetType
                } as KeywordItemMetric;
                metricsService.copyMetrics(keywordItem, totalMetric);
                this.data!.push(keywordItem);
            });

            this.originalData = [...(this.managedTargets || [])];
        },
        async getData() {
            if (this.campaignType == CampaignMetricType2.Product) {
                const [keywordData, productTargetData, autoTargetData] =
                    await Promise.all([
                        metrics2Service.getKeywordData(this.dateRange.value, this.id, this.campaignType),
                        metrics2Service.getProductTargetData(this.dateRange.value, this.id),
                        metrics2Service.getAutoTargetData(this.dateRange.value, this.id)]
                    );
                // Object.values(keywordData).forEach(a => a.total.targetType = TargetType.Keyword);
                // Object.values(productTargetData).forEach(a => a.total.targetType = TargetType.Product);
                // Object.values(autoTargetData).forEach(a => a.total.targetType = TargetType.Auto);

                this.keywordMetrics = mergeRecordMaps(keywordData, productTargetData, autoTargetData);
            } else {
                const keywordData = await metrics2Service.getKeywordData(this.dateRange.value, this.id, this.campaignType);
                this.keywordMetrics = keywordData;
            }
        }
    },
});
</script>

<style scoped></style>
