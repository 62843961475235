import type { ProfileCustomOptions, ProfileItem } from "@/models/profile-item";
import axios from "axios";

var cachedProfiles: ProfileItem[] | null = null;

export default {
    async reloadProfiles() {
        const response = await axios.get<ProfileItem[]>("/api/profiles");
        cachedProfiles = response.data;
        return cachedProfiles;
    },
    getCachedProfiles(): ProfileItem[] | null {
        return cachedProfiles;
    },
    async checkIfAccountIsAuthorized(profileId: string) {
        const response = await axios.get<boolean>(`/api/profiles/authorized?profileId=${profileId}`);
        return response.data;
    },
    async getProfileSettings(profileId: string) {
        const response = await axios.get<ProfileCustomOptions | undefined>(`/api/profiles/settings?profileId=${profileId}`);
        return response.data;
    },
    async updateProfileSettings(profileId: string, profileConfig: ProfileCustomOptions) {
        const response = await axios.post<void>(`/api/profiles/settings?profileId=${profileId}`, profileConfig);
        return response.data;
    },
    async switchProfile(profileId: string) {
        const response = await axios.get<{ success: boolean; redirectUrl: string }>("/api/profiles/switch", {
            params: { profileId },
        });
        return response.data;
    },
    async deactivateProfile(profileId: string) {
        const response = await axios.post<void>(`/api/profiles/deactivate?profileId=${profileId}`);
        return response.data;
    }
};
