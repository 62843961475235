<template>
    <div class="flex justify-content-between">
        <h1 class="inline-block">Main Dashboard</h1>
        <DateRangePicker v-model="dateRange" />
    </div>

    <div class="flex" style="gap: 1.5rem">
        <KeyIndicatorItem title="Total Sales" :type="MetricType.Money" :value="orderInfo && totalSales(orderInfo)"
            :prev-value="prevOrderInfo && totalSales(prevOrderInfo)" :loading="isLoading" />

        <KeyIndicatorItem title="CVR" :type="MetricType.Percentage" :value="profileData && calcCVR(profileData)"
            :prev-value="prevProfileData && calcCVR(prevProfileData)" :loading="isLoading" />

        <KeyIndicatorItem title="Orders" :type="MetricType.Count" :value="profileData && calcOrders(profileData)"
            :prev-value="prevProfileData && calcOrders(prevProfileData)" :loading="isLoading" />
    </div>

    <div v-if="profileData && prevProfileData" class="mt-3">
        <div class="flex" style="gap: 1rem">
            <SimpleChart :prev-series-data="prevProfileData" :series-data="profileData" :type="DataAttribute.AdSales" />
            <SimpleChart :prev-series-data="prevProfileData" :series-data="profileData" :type="DataAttribute.AdSpend" />
            <SimpleChart :prev-series-data="prevProfileData" :series-data="profileData" :type="DataAttribute.ACOS" />
            <SimpleChart :prev-series-data="prevProfileData" :series-data="profileData" :type="DataAttribute.ROAS" />
        </div>
    </div>

    <div v-if="saleBreakdownChartOptions" class="mt-3 flex flex-row-reverse">
        <Chart :options="saleBreakdownChartOptions" :inline="true" />
    </div>
</template>

<script lang="ts" setup>
import DateRangePicker from "@/components/DateRangePicker.vue";
import { MetricType, formatCurrency } from "@/services/kpi.service";
import KeyIndicatorItem from "@/components/KeyIndicatorItem.vue";
import SimpleChart, { DataAttribute } from "@/components/SimpleChart.vue";
import Chart from "@/components/Chart.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";

import { sumBy } from "lodash-es";
import metricsService, { type IDateRange, type RecordTable } from "@/services/metrics.service";
import dashboardService, { type IOrderMetrics } from "@/services/dashboard.service";
import metrics2Service from "@/services/metrics2.service";
import type Highcharts from 'highcharts';

export default defineComponent({
    data() {
        return {
            dateRange: metricsService.getDateRange(),
            orderInfo: undefined as IOrderMetrics[] | undefined,
            prevOrderInfo: undefined as IOrderMetrics[] | undefined,
            profileData: undefined as RecordTable | undefined,
            prevProfileData: undefined as RecordTable | undefined,
            isLoading: false,
            saleBreakdownChartOptions: undefined as Highcharts.Options | undefined,
        };
    },
    watch: {
        dateRange: {
            async handler() {
                this.isLoading = true;

                this.orderInfo = await dashboardService.getProfileOrderInfo(this.dateRange.value as IDateRange);
                this.prevOrderInfo = await dashboardService.getProfileOrderInfo(this.dateRange.prev as IDateRange);

                this.profileData = await metrics2Service.getOneProfileData(this.dateRange.value);
                this.prevProfileData = await metrics2Service.getOneProfileData(this.dateRange.prev!);

                this.saleBreakdownChartOptions = this.calcSaleBreakdownChartOptions();

                this.isLoading = false;
            },
            immediate: true
        },
    },
    methods: {
        totalSales(data: IOrderMetrics[]) {
            return sumBy(data, a => a.totalSales.amount);
        },
        calcCVR(data: RecordTable) {
            return data.total.conversionRate ?? 0;
        },
        calcOrders(data: RecordTable) {
            return data.total.attributedUnitsOrdered30d;
        },
        calcSaleBreakdownChartOptions(): Highcharts.Options {
            const totalSales = this.totalSales(this.orderInfo!);
            const adSales = this.profileData!.total.attributedSales30d;
            const organicSales = totalSales - adSales;

            const series: Highcharts.SeriesPieOptions = {
                type: 'pie',
                data: [
                    { name: 'Organic Sales', y: organicSales, color: '#7cb5ec' },
                    { name: 'Ad Sales', y: adSales, color: '#fec05e' },
                ]
            };

            return {
                title: {
                    text: "Total Sales Breakdown",
                    align: "left"
                },
                chart: {
                    style: {
                        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
                    },
                    numberFormatter: function (number) {
                        return formatCurrency(number);
                    }
                },
                series: [series]
            };
        }
    }
});
</script>