<template>
    <Dialog :modal="true" v-model:visible="visible" :closable="true" :class="'activation-modal'">
        <template #header>
            <div>
                <span class="text-2xl font-medium" style="color: #ffa03e">Product Keywords Wizard</span>
                <br>
                <span v-if="step == 1">Step 1 - Keyword/Target Configuration</span>
                <span v-if="step == 2">Step 2 - Final Check</span>
                <span v-if="step == 3">Step 3 - Finish</span>
            </div>
        </template>
        <template #footer>
            <div class="progress-bar" :style="{ width: (step / totalSteps) * 100 + '%' }"></div>
        </template>
        <div class="activation-body">
            <div v-if="step == 1" class="step-1">
                <span class="font-medium">Add keywords or product targets to your existing campaigns.</span>
                <div class="grid-container mt-4">
                    <Listbox v-model="selectedProducts" :options="allProducts" :multiple="true">
                        <template #header>
                            <div class="p-listbox-header">
                                <Button v-if="!allSelected" label="Select All" v-on:click="selectAllProducts(true)" />
                                <Button v-if="allSelected" label="Deselect All"
                                    class="p-button-outlined p-button-secondary"
                                    v-on:click="selectAllProducts(false)" />

                                <ToggleButton v-model="allIncluded" class="ml-auto" onLabel="All Included"
                                    offLabel="All Included" onIcon="pi pi-check" offIcon="pi pi-times" />
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="product-selection flex align-items-center select-none">
                                <img :src="slotProps.option.imageUrl" />
                                <div class="flex flex-column ml-2">
                                    <span>{{ slotProps.option.sku }}</span>
                                    <span class="product-title">{{ slotProps.option.title }}</span>
                                </div>
                                <ToggleButton v-model="slotProps.option.selected" class="ml-auto" onLabel="Included"
                                    offLabel="Included" onIcon="pi pi-check" offIcon="pi pi-times"
                                    v-on:click="$event.stopPropagation()" />
                            </div>
                        </template>
                    </Listbox>
                    <div class="flex flex-column" :style="{ 'opacity': noProductSelected ? 0.5 : 1 }">
                        <div class="flex white-space-nowrap">
                            <div class="flex flex-column">
                                <label class="pl-0 pt-0" for="initialBid">Initial Bid</label>
                                <InputNumber inputId="initialBid" v-model="initialBid" mode="decimal"
                                    :minFractionDigits="2" prefix="$"
                                    v-tooltip.bottom="'The initial bid to user for all keywords'"
                                    :disabled="noProductSelected" />
                            </div>
                            <div class="flex flex-column ml-2">
                                <label class="pl-0 pt-0" for="exactTarget">Exact</label>
                                <Checkbox inputId="exactTarget" v-model="exactTarget" :binary="true" />
                            </div>
                            <div class="flex flex-column">
                                <label class="pl-0 pt-0" for="phraseTarget">Phrase</label>
                                <Checkbox inputId="phraseTarget" v-model="phraseTarget" :binary="true" />
                            </div>
                            <div class="flex flex-column">
                                <label class="pl-0 pt-0" for="broadTarget">Broad</label>
                                <Checkbox inputId="broadTarget" v-model="broadTarget" :binary="true" />
                            </div>
                            <div class="flex flex-column">
                                <label class="pl-0 pt-0" for="productTarget">Product</label>
                                <Checkbox inputId="productTarget" v-model="productTarget" :binary="true" />
                            </div>
                        </div>
                        <Textarea class="mt-2" v-model="keywords"
                            :placeholder="'Enter a list of keywords/ASINs separated by new lines'"
                            :disabled="noProductSelected">
                </Textarea>
                    </div>
                </div>
                <Button label="Next Step" class="mt-4" :disabled="numProductsToActivate == 0" @click="nextStep()" />
            </div>
            <div v-if="step == 2" class="step-2">
                <span class="font-medium">You are about to modify {{ numProductsToActivate }} product(s) in bulk.</span>
                <br />
                <br />
                <span class="font-medium">This is your last chance to go back and confirm all of the new keywords that
                    you would
                    like to be included in the campaign structure.</span>
                <div class="mt-8">
                    <Button label="Go Back" @click="prevStep()" class="p-button-outlined"></Button>
                    <Button label="Add Keywords" @click="nextStep()" :disabled="numProductsToActivate == 0"
                        class="ml-4"></Button>
                </div>
            </div>
            <div v-if="step == 3" class="step-3">
                <ProgressSpinner v-if="updatingProducts" />
                <template v-if="updatingProducts">
                    <div class="mt-4">
                        Your product(s) are being updated in the background. You can close this modal or wait for it to
                        complete.
                    </div>
                </template>
                <template v-if="!updatingProducts && errorUpdatingProducts">
                    There was an error updating the product(s). Please close this modal and try again.
                </template>
                <template v-if="!updatingProducts && !errorUpdatingProducts">
                    <div class="font-medium" style="font-size: 3rem;">You're Done!</div>
                    <Button label="Close Wizard" @click="close()" class="p-button-outlined mt-4"></Button>
                </template>
            </div>
        </div>
    </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "primevue/dialog";
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import Listbox from 'primevue/listbox';
import ToggleButton from 'primevue/togglebutton';
import ProgressSpinner from 'primevue/progressspinner';
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ProductItem, ProductItemUpdate } from "@/models/product-item";
import productService from "@/services/product.service";
import modalService from "@/services/modal.service";

export default defineComponent({
    data() {
        return {
            visible: true,
            step: 1,
            totalSteps: 3,
            updatingProducts: false,
            errorUpdatingProducts: false,
            allProducts: [] as ProductItemUpdate[],
            selectedProducts: [] as ProductItemUpdate[]
        };
    },
    computed: {
        noProductSelected() {
            return this.selectedProducts.length === 0;
        },
        oneProductSelected() {
            return this.selectedProducts.length === 1;
        },
        allSelected: {
            get(): boolean {
                return this.selectedProducts.length == this.allProducts.length;
            },
            set(val: boolean) {
                if (val) {
                    this.selectedProducts = this.allProducts;
                } else {
                    this.selectedProducts = [];
                }
            }
        },
        allIncluded: {
            get(): boolean {
                return this.allProducts.every(x => x.selected);
            },
            set(val: boolean) {
                for (var product of this.allProducts) {
                    product.selected = val;
                }
            }
        },
        numProductsToActivate() {
            return this.allProducts.filter(x => x.selected).length;
        },
        initialBid: {
            get(): number | undefined {
                var set = new Set(this.selectedProducts.map(x => x.initialBid));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: number) {
                for (var product of this.selectedProducts) {
                    product.initialBid = val;
                }
            }
        },
        keywords: {
            get(): string | undefined {
                var set = new Set(this.selectedProducts.map(x => x.keywords));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: string) {
                for (var product of this.selectedProducts) {
                    product.keywords = val;
                }
            }
        },
        exactTarget: {
            get(): boolean | undefined {
                var set = new Set(this.selectedProducts.map(x => x.exactTarget));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: boolean) {
                for (var product of this.selectedProducts) {
                    product.exactTarget = val;
                }
            }
        },
        broadTarget: {
            get(): boolean | undefined {
                var set = new Set(this.selectedProducts.map(x => x.broadTarget));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: boolean) {
                for (var product of this.selectedProducts) {
                    product.broadTarget = val;
                }
            }
        },
        phraseTarget: {
            get(): boolean | undefined {
                var set = new Set(this.selectedProducts.map(x => x.phraseTarget));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: boolean) {
                for (var product of this.selectedProducts) {
                    product.phraseTarget = val;
                }
            }
        },
        productTarget: {
            get(): boolean | undefined {
                var set = new Set(this.selectedProducts.map(x => x.productTarget));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: boolean) {
                for (var product of this.selectedProducts) {
                    product.productTarget = val;
                }
            }
        },
    },
    props: {
        products: { type: Object as PropType<ProductItem[]>, required: true }
    },
    mounted() {
        this.allProducts = this.products
            // Only include managed products
            .filter(x => x.isManaged)
            .map(x => ({
                ...x,
                selected: false,
                // TODO: Get better default values
                targetAcos: 18.00,
                maxBid: 4.00,
                initialBid: 1.00,
                grossMargin: undefined,
                keywords: '',
                topKeywords: [],
                exactTarget: false,
                broadTarget: true,
                phraseTarget: true,
                productTarget: true
            }));
    },
    methods: {
        nextStep() {
            this.step++;

            // Start loading the top keywords
            if (this.step == 3) {
                this.updatingProducts = true;
                this.errorUpdatingProducts = false;

                var productsToUpdate = this.allProducts.filter(x => x.selected);

                productService
                    .updateProducts(productsToUpdate)
                    .then(() => {
                        this.updatingProducts = false;
                        this.errorUpdatingProducts = false;
                    }).catch(() => {
                        this.updatingProducts = false;
                        this.errorUpdatingProducts = true;
                    });
            }
        },
        prevStep() {
            this.step--;
        },
        selectAllProducts: function (selected: boolean) {
            if (selected) {
                this.selectedProducts = this.allProducts;
            } else {
                this.selectedProducts = [];
            }
        },
        close() {
            modalService.close();
        }
    }
});
</script>

<style scoped>
.activation-body {
    width: 48rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
}

.activation-body .step-1 {
    text-align: center;
}

.activation-body .step-2 {
    text-align: center;
}

.activation-body .step-3 {
    text-align: center;
}

.progress-bar {
    background-color: #ffa03e;
    height: 1.5rem;
}

.grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 8px;
    text-align: left;
}

.activation-body .step-1 textarea {
    width: 100%;
    min-width: 20rem;
    height: 12rem;
    resize: none;
}
</style>

<style>
.activation-modal.p-dialog .p-dialog-footer {
    padding: 0;
}

.activation-body .step-1 .p-inputnumber-input {
    width: 6rem;
}

.activation-body .step-1 .p-listbox {
    height: 18rem;
    overflow-y: scroll;
}

.activation-body .step-1 .product-selection img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    display: flex;
}

.activation-body .step-1 .product-selection .product-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 14rem;
}

.activation-body .step-1 .p-togglebutton {
    padding: 0.25rem 0.5rem;
}

.activation-body .step-1 .p-listbox-header {
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #ced4da;
}

.activation-body .step-1 .p-listbox-header .p-button {
    padding: 0.25rem 0.5rem;
}
</style>
