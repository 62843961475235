<template>
    <component :is="modalRef?.component" :key="count" v-bind="modalRef?.props" />
</template>

<script lang="ts" setup>
import modalService from "@/services/modal.service";
import { ref, watchEffect } from "vue";

const modalRef = modalService.subscribe();
const count = ref(0);

watchEffect(() => {
    const _value = modalRef.value;
    count.value++;
})
</script>