<template>
    <DataTable v-model:filters="filters" v-model:selection="selectedProducts" v-model:expandedRows="expandedRows"
        :value="products" :paginator="true" :rows="10" filter-display="menu" sort-field="attributedSales30d"
        :sort-order="-1" :global-filter-fields="['sku', 'asin', 'title']" :loading="products === undefined"
        :row-class="rowClass">

        <template #header>
            <div class="product-table-header flex justify-content-between">
                <Menubar :model="bulkActions" />

                <div class="align-self-center ml-auto">
                    <Checkbox inputId="showManagedOnly" v-model="managedOnly" :binary="true" />
                    <label for="showManagedOnly">Show Managed Products Only</label>
                </div>
                <div class="align-self-center ml-auto mr-5">
                    <SelectButton v-model="selectedCampaignType" :options="campaignTypeOptions" optionLabel="name"
                        optionValue="code" @update:model-value=" $emit('update:campaignType', selectedCampaignType)" />
                </div>

                <InputGroup class="align-self-center">
                    <InputGroupAddon>
                        <i class="pi pi-search"></i>
                    </InputGroupAddon>
                    <InputText v-model="filters['global'].value" type="text" placeholder="Search products..." />
                </InputGroup>
            </div>
        </template>


        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column v-if="groupings" :expander="true" header-style="width: 3rem" />
        <Column field="imageUrl" header="Image">
            <template #body="slotProps">
                <router-link :to="getLink(slotProps.data)">
                    <img :src="slotProps.data.imageUrl || 'https://picsum.photos/id/1/200'" />
                </router-link>
            </template>
        </Column>
        <Column field="sku" header="Sku" :sortable="true" />
        <Column field="asin" header="Asin" :sortable="true">
            <template #body="slotProps">
                <div class="flex flex-nowrap">
                    <AsinLink :asin="slotProps.data.asin" />
                    <AdjoliStateIcon class="ml-2" :state="slotProps.data.state" />
                </div>
            </template>
        </Column>
        <Column field="title" :header="groupings ? 'Product Name' : 'Variant Name'" :sortable="true">
            <template #body="slotProps">
                <div class="product-desc" :title="slotProps.data.title">{{ slotProps.data.title }}</div>
            </template>
        </Column>
        <template v-if="!groupings">
            <Column field="impressions" header="Impressions" :sortable="true">
                <template #body="slotProps">{{ formatCount(slotProps.data.impressions) }}</template>
            </Column>
            <Column field="clicks" header="Clicks" :sortable="true">
                <template #body="slotProps">{{ formatCount(slotProps.data.clicks) }}</template>
            </Column>
        </template>
        <Column field="cost" header="Spend" :sortable="true">
            <template #body="slotProps">{{ formatCurrency(slotProps.data.cost) }}</template>
        </Column>
        <Column field="attributedSales30d" header="Sales" :sortable="true">
            <template #body="slotProps">{{ formatCurrency(slotProps.data.attributedSales30d) }}</template>
        </Column>
        <Column field="isManaged" header="Status" :sortable="true">
            <template #body="slotProps">{{ slotProps.data.isManaged ? "Enabled" : "Disabled" }}</template>
        </Column>
        <Column field="actions">
            <template #body="slotProps">
                <router-link :to="getLink(slotProps.data)">View</router-link>
            </template>
        </Column>

        <template #expansion="groupProps">
            <DataTable :global-filter-fields="['sku', 'asin', 'title']" :value="groupProps.data.children"
                :paginator="true" responsive-layout="scroll" :rows="5" sort-field="attributedSales30d" :sort-order="-1">
                <Column header-style="width: 3em" />
                <Column field="imageUrl" header="Image">
                    <template #body="slotProps">
                        <router-link :to="getLink(slotProps.data)">
                            <img :src="slotProps.data.imageUrl || 'https://picsum.photos/id/1/200'" />
                        </router-link>
                    </template>
                </Column>
                <Column field="sku" header="Sku" :sortable="true" />
                <Column field="asin" header="Asin" :sortable="true">
                    <template #body="slotProps">
                        <div class="flex flex-nowrap">
                            <AsinLink :asin="slotProps.data.asin" />
                            <AdjoliStateIcon class="ml-2" :state="slotProps.data.state" />
                        </div>
                    </template>
                </Column>
                <Column field="title" header="Product Name" :sortable="true">
                    <template #body="slotProps">
                        <div class="product-desc" :title="slotProps.data.title">{{ slotProps.data.title }}</div>
                    </template>
                </Column>
                <!-- <Column field="impressions" header="Impressions" :sortable="true">
                    <template #body="slotProps">{{ formatCount(slotProps.data.impressions) }}</template>
                </Column>
                <Column field="clicks" header="Clicks" :sortable="true">
                    <template #body="slotProps">{{ formatCount(slotProps.data.clicks) }}</template>
                </Column> -->
                <Column field="cost" header="Spend" :sortable="true">
                    <template #body="slotProps">{{ formatCurrency(slotProps.data.cost) }}</template>
                </Column>
                <Column field="attributedSales30d" header="Sales" :sortable="true">
                    <template #body="slotProps">{{ formatCurrency(slotProps.data.attributedSales30d) }}</template>
                </Column>
                <Column field="isManaged" header="Status" :sortable="true">
                    <template #body="slotProps">{{ slotProps.data.isManaged ? "Enabled" : "Disabled" }}</template>
                </Column>
                <Column field="actions">
                    <template #body="slotProps">
                        <router-link :to="getLink(slotProps.data)">View</router-link>
                    </template>
                </Column>
            </DataTable>
        </template>
    </DataTable>
</template>

<script lang="ts">
import { defineComponent, watchEffect, type PropType } from "vue";
import type { ProductItem, ProductItemPlus } from "@/models/product-item";
import DataTable, {
    type
        DataTableFilterMetaData,
} from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import type { ProductItemPlusMetric } from "@/models/metrics";
import { formatCount, formatCurrency } from "@/services/kpi.service";
import Tooltip from 'primevue/tooltip';
import Menubar from 'primevue/menubar';
import getTooltip from "@/services/tooltip.service";
import modalService from "@/services/modal.service";
import ProductActivationModal from './modals/ProductActivationModal.vue';
import type { MenuItem } from "primevue/menuitem";
import ProductUpdateModal from './modals/ProductUpdateModal.vue';
import ProductSettingsModal from './modals/ProductSettingsModal.vue';
import { CampaignMetricType } from "@/services/metrics2.service";
import Checkbox from "primevue/checkbox";
import SelectButton from "primevue/selectbutton";
import { notify } from "@kyvg/vue3-notification";
import AdjoliStateIcon from "./AdjoliStateIcon.vue";
import AsinLink from "./AsinLink.vue";
import { showDebugTools } from "@/services/user.service";
import productService from "@/services/product.service";

export default defineComponent({
    directives: {
        Tooltip
    },
    components: {
        DataTable,
        Column,
        InputText,
        Checkbox,
        SelectButton,
        Menubar,
        AdjoliStateIcon,
        AsinLink,
    },
    props: {
        products: { type: Array as PropType<ProductItemPlusMetric[]>, required: false, default: undefined },
        groupings: { type: Boolean, default: true }
    },
    emits: ['update:managedOnly', 'update:campaignType'],
    data() {
        return {
            selectedProducts: [] as ProductItemPlus[],
            expandedRows: [] as ProductItem[],
            filters: {
                ["isManaged"]: {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS
                } as DataTableFilterMetaData,
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                } as DataTableFilterMetaData,
            },
            bulkActions: [] as MenuItem[],
            managedOnly: false,
            selectedCampaignType: CampaignMetricType.Product,
            campaignTypeOptions: [
                { name: "Sponsored Products", code: CampaignMetricType.Product },
                { name: "Sponsored Brands", code: CampaignMetricType.Brand },
            ]
        };
    },
    methods: {
        getTooltip,
        formatCount,
        formatCurrency,
        rowClass(rowData: ProductItemPlus) {
            return rowData.isGroup ? "" : "no-expander";
        },
        getLink(item: ProductItemPlus) {
            return { name: item.isGroup ? 'Group' : 'Product', params: { id: item.asin } };
        }
    },
    watch: {
        managedOnly(value: boolean) {
            // Show either managed or everything
            this.filters['isManaged'].value = value ? true : null;
            this.$emit('update:managedOnly', this.managedOnly);
        }
    },
    mounted() {
        var addBulkActionItem = (
            opts: {
                label: string,
                errorMessage: string,
                forManagedProducts: boolean,
                command: (a: ProductItem[]) => void
            }) => {
            this.bulkActions[0].items!.push({
                label: opts.label,
                command: () => {
                    // Get the children for parent products
                    var selectedProducts = this.selectedProducts
                        .flatMap(x => x.children?.length > 0 ? x.children : [x]);
                    if (selectedProducts.length == 0) return;

                    // Filter the selected products based on the options
                    if (opts.forManagedProducts) {
                        selectedProducts = selectedProducts.filter(x => x.isManaged);
                    } else {
                        selectedProducts = selectedProducts.filter(x => !x.isManaged);
                    }

                    if (selectedProducts.length == 0) {
                        notify({ title: opts.errorMessage, type: 'error' });
                        return;
                    } else {
                        opts.command(selectedProducts);
                    }
                },
            });
        }

        watchEffect(() => {
            this.bulkActions = [{
                label: 'Bulk Actions...',
                items: []
            }];

            addBulkActionItem({
                label: 'Activate product for automation',
                errorMessage: 'Only new products can be activated!',
                forManagedProducts: false,
                command: (products) => {
                    modalService.show(ProductActivationModal, { products });
                },
            });

            addBulkActionItem({
                label: 'Add keywords to product',
                errorMessage: 'Only managed products can be modified!',
                forManagedProducts: true,
                command: (products) => {
                    modalService.show(ProductUpdateModal, { products });
                }
            });

            addBulkActionItem({
                label: 'Override product settings',
                errorMessage: 'Only managed products can be modified!',
                forManagedProducts: true,
                command: (products) => {
                    modalService.show(ProductSettingsModal, { products });
                }
            });

            addBulkActionItem({
                label: 'Set up dayparting rules',
                errorMessage: 'Only managed products can be modified!',
                forManagedProducts: true,
                command: (products) => {
                    this.$router.push({ name: "Dayparting", query: { asins: products.map(x => x.asin) } });
                }
            });

            if (showDebugTools.value) {
                addBulkActionItem({
                    label: 'Reset product for automation',
                    errorMessage: 'Only managed products can be modified!',
                    forManagedProducts: true,
                    command: async (products) => {
                        var result = confirm(`Are you sure you want to reset all Adjoli settings on ${products.length} product(s)?`);
                        if (result) {
                            await productService.reset(products);
                        }
                    }
                });
            }
        });
    }
});
</script>

<style scoped>
.p-datatable :deep(td) {
    vertical-align: middle;
}

img {
    max-height: 64px;
}


.product-desc {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px;
    overflow: hidden;
}

:deep(.p-datatable-row-expansion)>td {
    padding: 0 !important;
}

:deep(.p-datatable-row-expansion) th {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

:deep(.p-datatable-tbody) .no-expander .p-row-toggler {
    display: none;
}

:deep(.product-table-header) .p-submenu-list {
    width: 18rem;
}
</style>
