<template>
    <Dialog :modal="true" v-model:visible="visible" :closable="true" :class="'activation-modal'">
        <template #header>
            <div>
                <span class="text-2xl font-medium" style="color: #ffa03e">Product Activation Wizard</span>
                <br>
                <span v-if="step == 1">Step 1 - Confirmation</span>
                <span v-if="step == 2">Step 2 - Keyword/Target Configuration</span>
                <span v-if="step == 3">Step 3 - Final Check</span>
                <span v-if="step == 4">Step 4 - Finish</span>
            </div>
        </template>
        <template #footer>
            <div class="progress-bar" :style="{ width: (step / totalSteps) * 100 + '%' }"></div>
        </template>
        <div class="activation-body">
            <div v-if="step == 1" class="step-1">
                <span class="font-medium">Adjoli will get the top 20% of keywords and search terms that these products
                    showed up
                    in over the last 90 days. By activating a product on Adjoli, this product will be paused in all
                    campaigns
                    that
                    it is contained in.</span>
                <br>
                <br>
                <span class="font-medium">This can be reverted at any time.</span>
                <div class="mt-8">
                    <Button label="Skip Top Keywords" @click="nextStep(true)" class="p-button-outlined"></Button>
                    <Button label="Next Step" @click="nextStep()" class="ml-4"></Button>
                </div>
            </div>
            <div v-if="step == 2" class="step-2">
                <ProgressSpinner v-if="loadingTopKeywords" />
                <template v-if="!loadingTopKeywords && errorLoadingTopKeywords">
                    There was an error retrieving your top keywords. Please close this modal and try again.
                </template>
                <template v-if="!loadingTopKeywords && !errorLoadingTopKeywords">
                    <span class="font-medium">Add any additional keywords or product targets that you would like to be
                        included.</span>
                    <div class="grid-container mt-4">
                        <Listbox v-model="selectedProducts" :options="allProducts" :multiple="true">
                            <template #header>
                                <div class="p-listbox-header">
                                    <Button v-if="!allSelected" label="Select All"
                                        v-on:click="selectAllProducts(true)" />
                                    <Button v-if="allSelected" label="Deselect All"
                                        class="p-button-outlined p-button-secondary"
                                        v-on:click="selectAllProducts(false)" />

                                    <ToggleButton v-model="allIncluded" class="ml-auto" onLabel="All Included"
                                        offLabel="All Included" onIcon="pi pi-check" offIcon="pi pi-times" />
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div class="product-selection flex align-items-center select-none">
                                    <img :src="slotProps.option.imageUrl" />
                                    <div class="flex flex-column ml-2">
                                        <span>{{ slotProps.option.sku }}</span>
                                        <span class="product-title">{{ slotProps.option.title }}</span>
                                    </div>
                                    <ToggleButton v-model="slotProps.option.selected" class="ml-auto" onLabel="Included"
                                        offLabel="Included" onIcon="pi pi-check" offIcon="pi pi-times"
                                        v-on:click="$event.stopPropagation()" />
                                </div>
                            </template>
                        </Listbox>
                        <div class="flex flex-column" :style="{ 'opacity': noProductSelected ? 0.5 : 1 }">
                            <div class="flex white-space-nowrap flex-nowrap">
                                <div class="flex flex-column">
                                    <label class="pl-0 pt-0" for="targetAcos">Target ACoS</label>
                                    <InputNumber inputId="targetAcos" v-model="targetAcos" mode="decimal"
                                        :minFractionDigits="2" suffix="%" v-tooltip.bottom="'What is an ACos?'"
                                        :disabled="noProductSelected" />
                                </div>
                                <div class="flex flex-column ml-4">
                                    <label class="pl-0 pt-0" for="maxBid">Max Bid</label>
                                    <InputNumber inputId="maxBid" v-model="maxBid" mode="decimal" :minFractionDigits="2"
                                        prefix="$" v-tooltip.bottom="'What is a Max Bid?'"
                                        :disabled="noProductSelected" />
                                </div>
                                <div class="flex flex-column ml-4">
                                    <label class="pl-0 pt-0" for="grossMargin">Gross Margin</label>
                                    <InputNumber inputId="grossMargin" v-model="grossMargin" mode="decimal"
                                        :minFractionDigits="2" suffix="%" v-tooltip.bottom="'What is Gross Margin?'"
                                        :disabled="noProductSelected" />
                                </div>
                            </div>
                            <Textarea class="mt-4" v-model="keywords"
                                :placeholder="'Enter a list of keywords/ASINs separated by new lines'"
                                :disabled="noProductSelected">
            </Textarea>
                        </div>
                    </div>
                    <Button label="Next Step" class="mt-4" :disabled="numProductsToActivate == 0" @click="nextStep()" />
                </template>
            </div>
            <div v-if="step == 3" class="step-3">
                <span class="font-medium">You are about to activate {{ numProductsToActivate }} product(s) in
                    bulk.</span>
                <br />
                <br />
                <span class="font-medium">This is your last chance to go back and confirm all of the additional keywords
                    that
                    you would like to be included in the campaign structure.</span>
                <div class="mt-8">
                    <Button label="Go Back" @click="prevStep()" class="p-button-outlined"></Button>
                    <Button label="Activate Product(s)" @click="nextStep()" :disabled="numProductsToActivate == 0"
                        class="ml-4"></Button>
                </div>
            </div>
            <div v-if="step == 4" class="step-4">
                <ProgressSpinner v-if="activatingProducts" />
                <template v-if="activatingProducts">
                    <div class="mt-4">
                        Your product is activating in the background. You can close this modal or wait for it to
                        complete.
                    </div>
                </template>
                <template v-if="!activatingProducts && errorActivatingProducts">
                    There was an error activating the products. Please close this modal and try again.
                </template>
                <template v-if="!activatingProducts && !errorActivatingProducts">
                    <div class="font-medium" style="font-size: 3rem;">You're Done!</div>
                    <Button label="Close Wizard" @click="close()" class="p-button-outlined mt-4"></Button>
                </template>
            </div>
        </div>
    </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "primevue/dialog";
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Listbox from 'primevue/listbox';
import ToggleButton from 'primevue/togglebutton';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ProductItem, ProductItemActivation } from "@/models/product-item";
import productService from "@/services/product.service";
import modalService from "@/services/modal.service";

export default defineComponent({
    data() {
        return {
            visible: true,
            step: 1,
            totalSteps: 4,
            loadingTopKeywords: false,
            errorLoadingTopKeywords: false,
            activatingProducts: false,
            errorActivatingProducts: false,
            allProducts: [] as ProductItemActivation[],
            selectedProducts: [] as ProductItemActivation[]
        };
    },
    computed: {
        noProductSelected() {
            return this.selectedProducts.length === 0;
        },
        oneProductSelected() {
            return this.selectedProducts.length === 1;
        },
        allSelected: {
            get(): boolean {
                return this.selectedProducts.length == this.allProducts.length;
            },
            set(val: boolean) {
                if (val) {
                    this.selectedProducts = this.allProducts;
                } else {
                    this.selectedProducts = [];
                }
            }
        },
        allIncluded: {
            get(): boolean {
                return this.allProducts.every(x => x.selected);
            },
            set(val: boolean) {
                for (var product of this.allProducts) {
                    product.selected = val;
                }
            }
        },
        numProductsToActivate() {
            return this.allProducts.filter(x => x.selected).length;
        },
        targetAcos: {
            get(): number | undefined {
                var set = new Set(this.selectedProducts.map(x => x.targetAcos));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: number) {
                for (var product of this.selectedProducts) {
                    product.targetAcos = val;
                }
            }
        },
        maxBid: {
            get(): number | undefined {
                var set = new Set(this.selectedProducts.map(x => x.maxBid));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: number) {
                for (var product of this.selectedProducts) {
                    product.maxBid = val;
                }
            }
        },
        grossMargin: {
            get(): number | undefined {
                var set = new Set(this.selectedProducts.map(x => x.grossMargin));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: number) {
                for (var product of this.selectedProducts) {
                    product.grossMargin = val;
                }
            }
        },
        keywords: {
            get(): string | undefined {
                var set = new Set(this.selectedProducts.map(x => x.keywords));
                return set.size === 1 ? [...set.values()][0] : undefined;
            },
            set(val: string) {
                for (var product of this.selectedProducts) {
                    product.keywords = val;
                }
            }
        },
    },
    props: {
        products: { type: Object as PropType<ProductItem[]>, required: true }
    },
    mounted() {
        this.allProducts = this.products.map(x => ({
            ...x,
            selected: false,
            // TODO: Get better default values
            targetAcos: 18.00,
            maxBid: 4.00,
            grossMargin: undefined,
            keywords: '',
            topKeywords: [],
        }));
    },
    methods: {
        nextStep(skipAction = false) {
            this.step++;
            if (skipAction) {
                return;
            }

            // Start loading the top keywords
            if (this.step == 2) {
                this.loadingTopKeywords = true;
                this.errorLoadingTopKeywords = false;
                productService
                    .getTopKeywordsForProducts(this.products.map(x => x.asin))
                    .then((result) => {
                        this.loadingTopKeywords = false;
                        if (result != null) {
                            this.allProducts.forEach(product => {
                                product.keywords = result[product.asin].map(x => x.keywordText).join('\n');
                                product.topKeywords = result[product.asin];
                            });
                        }
                    }).catch(() => {
                        this.loadingTopKeywords = false;
                        this.errorLoadingTopKeywords = true;
                    });
            }
            else if (this.step == 4) {
                this.activatingProducts = true;
                this.errorActivatingProducts = false;

                // Right before sending the products to activate make sure to set the checkbox
                // value of each product.
                var productsToActivate = this.allProducts.filter(x => x.selected);

                productService
                    .activateProducts(productsToActivate)
                    .then(() => {
                        this.activatingProducts = false;
                        this.errorActivatingProducts = false;
                    }).catch(() => {
                        this.activatingProducts = false;
                        this.errorActivatingProducts = true;
                    });
            }
        },
        prevStep() {
            this.step--;
        },
        selectAllProducts: function (selected: boolean) {
            if (selected) {
                this.selectedProducts = this.allProducts;
            } else {
                this.selectedProducts = [];
            }
        },
        close() {
            modalService.close();
        }
    }
});
</script>

<style scoped>
.activation-body {
    width: 48rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
}

.activation-body .step-1 {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.activation-body .step-2 {
    text-align: center;
}

.activation-body .step-3 {
    text-align: center;
}

.activation-body .step-4 {
    text-align: center;
}

.progress-bar {
    background-color: #ffa03e;
    height: 1.5rem;
}

.grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 8px;
    text-align: left;
}

.activation-body .step-2 textarea {
    width: 100%;
    height: 10rem;
    resize: none;
}
</style>

<style>
.activation-modal.p-dialog .p-dialog-footer {
    padding: 0;
    display: flow;
}

.activation-body .step-2 .p-inputnumber-input {
    width: 3rem;
}

.activation-body .step-2 .p-listbox {
    height: 18rem;
    overflow-y: scroll;
}

.activation-body .step-2 .product-selection img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    display: flex;
}

.activation-body .step-2 .product-selection .product-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 14rem;
}

.activation-body .step-2 .p-togglebutton {
    padding: 0.25rem 0.5rem;
}

.activation-body .step-2 .p-listbox-header {
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #ced4da;
}

.activation-body .step-2 .p-listbox-header .p-button {
    padding: 0.25rem 0.5rem;
}
</style>
