import axios from "axios";
import { formatISO, parse } from "date-fns";
import type { IDateRange } from "./date-range.service";

function getData<T>(url: string, range: IDateRange, params = {}) {
    return axios.get<T>(url, {
        params: {
            start: formatISO(range.start, { representation: "date" }),
            end: formatISO(range.end, { representation: "date" }),
            ...params,
        },
    });
}

export interface IKWDailyStat {
    impressions: number;
    clicks: number;
    cost: number;
    attributedConversions_30d: number;
    attributedUnitsOrdered_30d: number;
    attributedSales_30d: number;

    startTime: Date;
    campaignId: string;
    keywordId: string;
}

export interface IKeywordInfo {
    bid: number;
}

export interface IProductTargetStat {
    campaignId: string;
    keywordId: string;
    keywordText: string;
    matchType: string;
    timeWindowStart: Date;
    cost: number;
    attributedSales_30d: number;
    clicks: number;
    attributedConversions_30d: number;
}

export interface IBidEntry {
    item1: Date;
    item2: number; // the bid
}

export interface IBidEntry2 {
    keywordId: string;
    date: Date;
    bid: number;
}

export interface ICampaignBudgetStat {
    campaignId: string;
    timeWindowStart: Date;
    budget: number;
    budgetUtil: number;
}

export interface IProductTargetImpression {
    timeWindowStart: Date;
    impressions: number;
    placement: string;
}

function fixDate(d: string | Date) {
    const now = new Date();
    return parse(d.toString().split("T")[0], "yyyy-MM-dd", now)
}

export default {
    async getProductPrice(productAsin: string) {
        const response = await axios.get<number>(`/api/metrics/product/${productAsin}/price`);
        return response.data;
    },
    async getDailyStats(range: IDateRange, productAsin: string, keywordText: string, matchType: string) {
        const response = await getData<IKWDailyStat[]>(`/api/metrics/product/${productAsin}/${keywordText}/${matchType}`, range, {});
        return response.data.map(a => { a.startTime = fixDate(a.startTime); return a; });
    },
    async getHistoricalDailyStats(productAsin: string, keywordText: string, matchType: string) {
        const response = await axios.get<IKWDailyStat[]>(`/api/metrics/product/${productAsin}/${keywordText}/${matchType}/historical`);
        return response.data.map(a => { a.startTime = fixDate(a.startTime); return a; });
    },
    async getBids(range: IDateRange, productAsin: string, keywordText: string, matchType: string) {
        const response = await getData<IBidEntry[]>(`/api/metrics/product/${productAsin}/${keywordText}/${matchType}/bids`, range, {});
        return response.data.map(a => { a.item1 = fixDate(a.item1); return a; });
    },
    async getKeywordCurrentBid(productAsin: string, keywordId: string) {
        const response = await axios.get<IKeywordInfo>(`/api/metrics/product/${productAsin}/keyword`, { params: { keywordId } });
        return response.data;
    },
    async getProductTargetStats(productId: string) {
        const response = await axios.get<IProductTargetStat[]>(`/api/debug/product-targets/stats`, { params: { productId } });
        response.data.forEach(a => a.timeWindowStart = new Date(a.timeWindowStart));
        return response.data;
    },
    async getProductTargetImpressions(productId: string, campaignId: string, keywordId: string) {
        const response = await axios.get<IProductTargetImpression[]>(`/api/debug/product-targets/impressions`, { params: { productId, campaignId, keywordId } });
        response.data.forEach(a => a.timeWindowStart = new Date(a.timeWindowStart));
        return response.data;
    },
    async getProductTargetBids(productId: string, keywordText: string, matchType: string) {
        const response = await axios.get<IBidEntry2[]>(`/api/debug/product-targets/bids`, { params: { productId, keywordText, matchType } });
        response.data.forEach(a => a.date = new Date(a.date));
        return response.data;
    },
    async getProductTargetLastBids(productId: string, keywordIds: string[]) {
        const response = await axios.get<IBidEntry2[]>(`/api/debug/product-targets/bids`, { params: { productId } });
        response.data.forEach(a => a.date = new Date(a.date));
        const results = response.data;

        return Object.fromEntries(keywordIds.flatMap(id => results.filter(a => a.keywordId == id).slice(-1)).map(a => [a.keywordId, a.bid]));
    },
    async getCampaignBudgets(campaignId: string) {
        const response = await axios.get<ICampaignBudgetStat[]>(`/api/debug/campaign/budgets`, { params: { campaignId } });
        return response.data.map(a => { a.timeWindowStart = fixDate(a.timeWindowStart); return a; });
    },
};