import type { TopKeyword } from "./top-keyword";

export enum AmazonStateType {
    enabled = 'enabled',
    paused = 'paused',
    archived = 'archived'
}

export interface ProductItem {
    profileId: string;
    asin: string;
    imageUrl: string;
    sku: string;
    title: string;
    isManaged: boolean;
    state: AmazonStateType;
    targetAcos?: number | null;
    grossMargin?: number | null;
    maxBid?: number | null;
    amount: number;
}

export interface ProductItemPlus extends ProductItem {
    children: ProductItem[];
    isGroup: boolean;
}

export interface ProductItemSelection extends ProductItem {
    selected: boolean;
}

export interface ProductItemActivation extends ProductItemSelection {
    targetAcos: number;
    maxBid: number;
    grossMargin: number | undefined;
    keywords: string;
    topKeywords: TopKeyword[];
}

export interface ProductItemUpdate extends ProductItemSelection {
    initialBid: number;
    keywords: string;
    exactTarget: boolean;
    phraseTarget: boolean;
    broadTarget: boolean;
    productTarget: boolean;
}
