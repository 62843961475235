<template>
  <DataTable :value="products" v-model:expandedRows="expandedRows" :paginator="true" :rows="10"
    :rowsPerPageOptions="[5, 10, 25, 100]" responsiveLayout="scroll" :loading="refreshingData" sortField="sku"
    :sortOrder="1">
    <template #header>
      <div class="flex">
        <Menubar :model="bulkActions" style="padding: 0;" />
        <div v-if="selectedRows.length > 0" class="ml-4 flex align-content-center font-normal">
          {{ selectedRows.length }} out of {{ totalCount }} suggestion(s) selected
        </div>
        <div class="flex flex-auto justify-content-end">
          <div v-if="showDebugTools" class="align-items-center flex justify-content-end mr-8">
            <Checkbox inputId="test-data" name="test-data" v-model="testMode" v-on:change="refreshData()"
              :binary="true" />
            <label for="test-data">Use Test Data</label>
          </div>
          <div v-if="showDebugTools" class="align-items-center flex justify-content-end mr-8">
            <Checkbox inputId="show-all" name="show-all" v-model="showAllSuggestions" v-on:change="refreshData()"
              :binary="true" />
            <label for="show-all">Include All Possible Suggestions</label>
          </div>
          <div class="align-items-center flex justify-content-end">
            <RadioButton inputId="state-pending" name="state" value="pending" v-model="state" />
            <label for="state-pending">Pending</label>
          </div>
          <div class="align-items-center flex justify-content-end">
            <RadioButton inputId="state-ignored" name="state" value="ignored" v-model="state" />
            <label for="state-ignored">Ignored</label>
          </div>
          <div class="align-items-center flex justify-content-end">
            <RadioButton inputId="state-approved" name="state" value="approved" v-model="state" />
            <label for="state-approved">Approved</label>
          </div>
        </div>
      </div>
    </template>

    <Column headerStyle="width: 3rem">
      <template #header>
        <TriStateCheckbox v-model="allSuggestionsSelected" :disabled="!allowActions" :binary="true">
          <template #uncheckicon>
            <i class="pi pi-minus" style="color: white; font-size: 0.75rem;"></i>
          </template>
        </TriStateCheckbox>
      </template>
      <template #body="slotProps">
        <TriStateCheckbox v-model="productSelectionState[slotProps.data.productId]"
          v-on:update:modelValue="productSelected(slotProps.data, $event)" :disabled="!allowActions" :binary="true">
          <template #uncheckicon>
            <i class="pi pi-minus" style="color: white; font-size: 0.75rem;"></i>
          </template>
        </TriStateCheckbox>
      </template>
    </Column>
    <Column :expander="true" headerStyle="width: 3rem" />

    <Column field="image" header="Image">
      <template #body="slotProps">
        <img :src="slotProps.data.imageUrl" />
      </template>
    </Column>
    <Column field="sku" header="Sku" :sortable="true" />
    <Column field="productId" header="Asin" :sortable="true">
      <template #body="slotProps">
        <div class="flex flex-nowrap">
          <AsinLink :asin="slotProps.data.productId" />
          <AdjoliStateIcon class="ml-2" :state="slotProps.data.productState" />
        </div>
      </template>
    </Column>
    <Column field="productName" header="Product" :sortable="true" />
    <Column header="Suggestions" :sortable="false">
      <template #body="slotProps">
        {{ slotProps.data.filteredActionRows.length }}
      </template>
    </Column>

    <template #expansion="slotProps">
      <KeywordActionZoneRowTable v-if="!isBudget" :isAsin="isAsin" :showModal="showModal" :showInitialBid="showInitialBid"
        :productRow="slotProps.data" :actionType="actionType" :state="state" v-on:update:filters="filtersUpdated"
        :filters="filters" v-on:suggestion:action="updateSuggestions($event.action, [$event.row])">
      </KeywordActionZoneRowTable>
      <BudgetActionZoneRowTable v-if="isBudget" :isAsin="isAsin" :showModal="showModal" :showInitialBid="showInitialBid"
        :productRow="slotProps.data" :actionType="actionType" :state="state" v-on:update:filters="filtersUpdated"
        :filters="filters" v-on:suggestion:action="updateSuggestions($event.action, [$event.row])">
      </BudgetActionZoneRowTable>
    </template>
  </DataTable>
</template>

<script lang="ts" setup>
import DataTable, { type DataTableFilterMeta, type DataTableFilterMetaData, type DataTableOperatorFilterMetaData } from "primevue/datatable";
import Column from "primevue/column";
import RadioButton from 'primevue/radiobutton';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Checkbox from "primevue/checkbox";
import Menubar from 'primevue/menubar';
import AdjoliStateIcon from "@/components/AdjoliStateIcon.vue";
import KeywordActionZoneRowTable from '@/components/action-zone/KeywordActionZoneRowTable.vue';
import BudgetActionZoneRowTable from '@/components/action-zone/BudgetActionZoneRowTable.vue';
</script>

<script lang="ts">
import type { ActionZoneProductRow, ActionZoneRow, ActionZoneRowState, ActionZoneUpdateModel, BudgetActionZoneRow, BudgetSuggestion, KeywordActionZoneRow, } from "@/models/action-zone";
import { defineComponent } from "vue";
import ActionZoneService from "@/services/action-zone.service";
import AsinLink from "@/components/AsinLink.vue";
import type { MenuItem } from "primevue/menuitem";
import type { Nullable } from "primevue/ts-helpers";
import jsonToCsv from "json-to-csv-export";
import modalService from "@/services/modal.service";
import ActionZoneSearchTermModal, { formatAcos } from "@/components/action-zone/ActionZoneSearchTermModal.vue";
import { showDebugTools } from '@/services/user.service';
import { formatCurrency } from "@/services/kpi.service";
import { generateTestData } from "./test-data-helper";
import cacheService from "@/services/cache.service";
import { notify } from "@kyvg/vue3-notification";
import { FilterMatchMode, FilterOperator, FilterService } from "primevue/api";
import { filterData } from '@/helpers/data-table.helper';


var filterOperator = (matchMode: string) => ({
  operator: FilterOperator.AND,
  constraints: [{
    value: null,
    matchMode: matchMode
  }],
}) as DataTableOperatorFilterMetaData;

export default defineComponent({
  data() {
    return {
      refreshingData: true,
      products: [] as ActionZoneProductRow<ActionZoneRow>[],
      expandedRows: [],
      state: 'pending' as ActionZoneRowState,
      bulkActions: [] as MenuItem[],
      filters: {
        // State management
        ["ignored"]: {
          value: false,
          matchMode: FilterMatchMode.EQUALS
        } as DataTableFilterMetaData,
        ["approved"]: {
          value: false,
          matchMode: FilterMatchMode.EQUALS
        } as DataTableFilterMetaData,
        // Keywords
        ["query.sanitized"]: filterOperator(FilterMatchMode.CONTAINS),
        ["query.original"]: filterOperator(FilterMatchMode.CONTAINS),
        ["initialBid"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["impressions"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["clicks"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["cost"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["attributedSales30d"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["attributedConversions30d"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["acos"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        // Budgets
        ["campaignType"]: filterOperator(FilterMatchMode.CONTAINS),
        ["suggestedBudget"]: filterOperator(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
        ["lastActionDate"]: filterOperator(FilterMatchMode.DATE_AFTER),
      },
      showAllSuggestions: false,
      testMode: false
    };
  },
  props: {
    actionType: String,
    showModal: Boolean,
    showInitialBid: Boolean,
    isAsin: Boolean,
    isBudget: Boolean
  },
  computed: {
    allowActions() {
      return !this.isBudget || this.state === 'pending';
    },
    allSuggestionsSelected: {
      get() {
        var selection = new Set(this.products.flatMap(x => x.filteredActionRows).map(x => x.isSelected));
        if (selection.has(true) && selection.has(false)) {
          // This is a mixed selection
          return false;
        } else if (selection.has(true)) {
          return true;
        } else {
          return null;
        }
      },
      set(value: boolean) {
        this.updateSelectionState(value === true || value === null);
      }
    },
    productSelectionState() {
      return this.products.reduce((map, product) => {
        // Check the selection state, is every action row seleted under this product?
        let actionRows = new Set(product.filteredActionRows.map(x => x.isSelected));
        if (actionRows.has(true)) {
          // Are all selected?
          map[product.productId] = !actionRows.has(false);
        } else {
          // Nothing selected
          map[product.productId] = null;
        }
        return map;
      }, {} as { [key: string]: Nullable<boolean> });
    },

    totalCount() {
      return this.products.reduce((count, product) => {
        return count + (product.filteredActionRows?.length || 0);
      }, 0);
    },
    selectedRows() {
      return this.products
        .flatMap(x => x.filteredActionRows)
        .filter(x => x.isSelected);
    }
  },
  watch: {
    state(value: string) {
      this.filters['ignored'].value = this.state == 'ignored';
      this.filters['approved'].value = this.state == 'approved';
      this.updateBulkActionStatus();
      this.filtersUpdated(this.filters);
    },
    showAllSuggestions(value: boolean) {
      cacheService.saveToStorage(`actionZone.showAllSuggestions.${this.actionType!}`, value);
    },
    testMode(value: boolean) {
      cacheService.saveToStorage(`actionZone.testMode.${this.actionType!}`, value);
    }
  },
  async created() {
    this.showAllSuggestions = cacheService.getFromStorage<boolean>(`actionZone.showAllSuggestions.${this.actionType!}`, this.showAllSuggestions);
    this.testMode = cacheService.getFromStorage<boolean>(`actionZone.testMode.${this.actionType!}`, this.testMode);

    this.bulkActions = [
      {
        label: 'Actions...',
        items: [
          {
            label: `Approve Suggestion(s)`,
            disabled: false,
            command: async () => {
              await this.updateSuggestions('approve', this.selectedRows);
            }
          },
          {
            label: `Ignore Suggestion(s)`,
            disabled: false,
            command: async () => {
              await this.updateSuggestions('ignore', this.selectedRows);
            }
          }
        ]
      }
    ];

    if (this.actionType === 'negative-phrase') {
      this.bulkActions[0].items?.push({
        label: `Export Search Terms`,
        disabled: false,
        command: this.exportSearchTerms
      });
    }

    await this.refreshData();
  },
  methods: {
    filtersUpdated(filters: DataTableFilterMeta) {
      for (let product of this.products) {
        product.filteredActionRows = filterData(product.actionRows, filters);
        for (let row of product.actionRows) {
          if (!product.filteredActionRows.includes(row)) {
            row.isSelected = false;
          }
        }
      }
      this.filters = filters as any;
    },
    async refreshData() {
      this.refreshingData = true;
      try {
        if (this.testMode) {
          this.fillWithTestData();
        } else {
          this.products = await ActionZoneService.getProductRows(this.actionType!, this.showAllSuggestions);
        }
      } finally {
        this.refreshingData = false;
      }
      this.updateSelectionState(false);
    },
    updateBulkActionStatus() {
      var menuItems = this.bulkActions[0].items || [];
      menuItems[0].disabled = !this.allowActions || this.state === 'approved';
      menuItems[1].disabled = !this.allowActions || this.state === 'ignored';
    },
    updateSelectionState(value: boolean) {
      for (let product of this.products) {
        for (let actionRow of product.filteredActionRows) {
          actionRow.isSelected = value;
        }
      }
      this.filtersUpdated(this.filters);
    },
    exportSearchTerms() {
      var dataToExport = [] as any[];
      for (let product of this.products) {
        if (product.filteredActionRows.some(x => x.isSelected)) {
          dataToExport = dataToExport.concat(product.searchTerms.map(x => ({
            sku: product.sku,
            asin: product.productId,
            searchTerm: x.searchTerm,
            impressions: x.impressions,
            clicks: x.clicks,
            spend: formatCurrency(x.cost, '-'),
            sales: formatCurrency(x.attributedSales30d, '-'),
            unitsSold: x.attributedConversions30d,
            acos: formatAcos(x.acos, 'N/A')
          })));
        }
      }
      if (dataToExport.length > 0) {
        jsonToCsv({
          data: dataToExport,
          filename: 'adjoli-negative-phrase-search-terms',
          headers: ["SKU", "ASIN", "Search Term", "Impressions", "Clicks", "Spend", "Sales", "Units Sold", "ACoS"]
        });
      }
    },
    async updateSuggestions(action: 'approve' | 'ignore', actionRows: ActionZoneRow[]) {
      if (actionRows.length === 0) {
        return [];
      }
      else if (actionRows.length > 1) {
        var result = confirm(`Are you sure you want to ${action} ${actionRows.length} suggestion(s)?`);
        if (!result) {
          return [];
        }
      }

      try {
        actionRows.forEach(x => x.isLoading = true);

        // Map the action rows to update models
        let updateModels = actionRows.map(actionRow => {
          var updateModel: ActionZoneUpdateModel = {
            type: this.actionType!,
            productId: actionRow.productId,
            dryRun: this.testMode,
          };
          if (this.isBudget) {

            updateModel.budgetSuggestion = {
              ...(actionRow as BudgetActionZoneRow),
              // Json serialization will fail with extra client side values
              product: undefined,
              selectionState: undefined,
            } as BudgetSuggestion;
          } else {
            updateModel.query = (actionRow as KeywordActionZoneRow).query.sanitized;
            updateModel.initialBid = (actionRow as KeywordActionZoneRow).initialBid;
          }
          return updateModel;
        });

        if (action === 'approve') {
          await ActionZoneService.approveRows(updateModels);
        } else if (action === 'ignore') {
          await ActionZoneService.ignoreRows(updateModels);
        }

        // Update the internal state of the action items without refreshing from the server
        for (var actionRow of actionRows) {
          actionRow.ignored = action === 'ignore';
          actionRow.approved = action === 'approve';
          if (this.isBudget) {
            (actionRow as BudgetActionZoneRow).lastActionDate = new Date();
          }
        }

        if (actionRows.length > 1) {
          notify(`${actionRows.length} suggestions ${action}d`);
        } else {
          notify(`Suggestion ${action}d`);
        }
      } finally {
        actionRows.forEach(x => x.isLoading = false);
        this.updateSelectionState(false);
      }
    },
    productSelected(selectedProduct: ActionZoneProductRow<KeywordActionZoneRow>, value: Nullable<boolean>) {
      for (var actionRow of selectedProduct.filteredActionRows) {
        actionRow.isSelected = value === true || value === null;
      }
    },
    showRowDetails(productRow: ActionZoneProductRow<KeywordActionZoneRow>, actionRow: ActionZoneRow) {
      modalService.show(ActionZoneSearchTermModal, {
        testMode: this.testMode,
        productRow: productRow,
        actionRow: actionRow,
      });
    },

    // Can be used during testing to avoid waiting for lambda to compute real data
    fillWithTestData() {
      this.products = generateTestData(this.isBudget);
    }
  }
});
</script>

<style lang="scss" scoped>
.p-datatable :deep(td) {
  vertical-align: middle;
}

.p-datatable :deep(.p-column-title) {
  white-space: nowrap;
}

:deep(.p-datatable-row-expansion)>td {
  padding: 0 !important;
}

:deep(.p-datatable-row-expansion) th {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
</style>

<style lang="scss">
.p-button-success {
  background-color: $secondary !important;
}

.p-button-warning {
  background-color: $warning !important;
  color: white !important;
}

.p-button-link {
  margin-top: -0.4rem !important;

  & .pi-search {
    font-weight: 600;
    color: black;
  }
}

.padding-placeholder {
  height: 40px;
}

span[data-pc-section="sort"] {
  margin-top: -2px;
}
</style>