<template>
  <ActionZoneTable actionType="budget-suggestion" :showModal="false" :showInitialBid="false" :isAsin="false"
    :isBudget="true">
  </ActionZoneTable>
</template>

<script lang="ts" setup>
import ActionZoneTable from "@/components/action-zone/ActionZoneTable.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>