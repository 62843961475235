import { subDays, startOfMonth, startOfQuarter, subMonths, endOfMonth, sub, startOfWeek } from "date-fns";

export interface IDateRange {
    start: Date;
    end: Date;
}

export interface IDateRangeSelection {
    label: string;
    value: IDateRange;
    prev: IDateRange; // assume it always exists
}

function genRange(
    label: string,
    end: Date,
    start: Date,
    prevStart: (end: Date) => Date,
    prevEnd?: Date
): IDateRangeSelection {
    prevEnd = prevEnd ?? subDays(start, 1);
    return {
        label: label,
        value: { start: start, end: end },
        prev: { start: prevStart(prevEnd), end: prevEnd },
    };
}

function* generateDateRanges(): Generator<IDateRangeSelection> {
    const today = subDays(new Date(), 1);
    const weekStart = startOfWeek(today);
    const lastMonth = startOfMonth(subMonths(today, 1));

    yield genRange("Custom", today, subDays(today, 5), (d) => subDays(d, 5));
    yield genRange("Last 7 days", today, subDays(today, 7), (d) => subDays(d, 7));
    yield genRange("Last 30 days", today, subDays(today, 30), (d) => subDays(d, 30));
    yield genRange("Last 90 days", today, subDays(today, 90), (d) => subDays(d, 90));
    yield genRange("Last week", subDays(weekStart, 1), subDays(weekStart, 7), x => subDays(x, 6));
    yield genRange("Last month", endOfMonth(lastMonth), startOfMonth(lastMonth), startOfMonth);
    yield genRange("Month to date", today, startOfMonth(today), startOfMonth, sub(today, { months: 1 }));
    yield genRange("Quarter to date", today, startOfQuarter(today), startOfQuarter, sub(today, { months: 3 }));
}

const dateRangeList = Array.from(generateDateRanges());
let dateRange: IDateRangeSelection = dateRangeList[2];

export const days90 = dateRangeList[3];
export const days7 = dateRangeList[1];

export default {
    getDateRange() {
        return dateRange;
    },
    setDateRange(value: IDateRangeSelection) {
        dateRange = { ...value };
    },
    getDateRangeList() {
        return dateRangeList;
    },
};
