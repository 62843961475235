import axios from "axios";

export interface IHistoryRecord {
    entityId: string;
    changeType: string;
    newValue: string;
    previousValue: string;
    timestamp: number;
    metadata: {
        campaignId: string;
        keywordType: string;
    };
}

export interface IHistoryResult {
    events: IHistoryRecord[];
}

export interface IDaypartingEntry {
    dayOfWeek: number;
    hourOfDay: number;
    impressions?: number;
    clicks?: number;
    cost?: number;
    attributedConversions_30d?: number;
    attributedSales_30d?: number;
    attributedUnitsOrdered_30d?: number;

    trafficPoints: number;
    conversionPoints: number;
}

export interface IBudgetHistoryItem {
    budgetScopeId: string;
    budget: number;
    budgetUsagePercentage: number;
    startTime: Date | string;
}

export default {
    async getBidHistory(keywordIds: string[]) {
        const request = axios.get<IHistoryResult>(`/api/history/bids`, { params: { keywordIds } });
        return (await request).data;
    },
    async getCampaignBudgetHistory(keywordIds: string[]) {
        const request = axios.get<IHistoryResult>(`/api/history/budget`, { params: { keywordIds } });
        return (await request).data;
    },
    async getOrderHeatMap(productId: string) {
        const request = axios.get<string[]>(`/api/history/orders/heatmap`, { params: { productId } });
        return (await request).data;
    },
    async getDaypartingData(productId: string) {
        const request = axios.get<IDaypartingEntry[]>(`/api/history/dayparting`, { params: { productId } });
        return (await request).data;
    },
    async getBudgetHistory(productId: string) {
        const request = axios.get<IBudgetHistoryItem[]>(`/api/history/budgetV2`, { params: { productId } });
        return (await request).data;
    }
};
