<template>
    <ActionZoneTable actionType="asin-suggestion" :showModal="false" :showInitialBid="true" :isAsin="true">
    </ActionZoneTable>
</template>
  
<script lang="ts" setup>
import ActionZoneTable from "@/components/action-zone/ActionZoneTable.vue";
</script>
  
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>