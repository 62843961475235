<template>
    <component :is="layout">
        <slot />
    </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AppLayoutTransition from "./AppLayoutTransition.vue";
import AppLayoutDefault from "./AppLayoutDefault.vue";
import AppLayoutLogin from "./AppLayoutLogin.vue";
import AppLayoutAdmin from "./AppLayoutAdmin.vue";

const defaultLayout = "AppLayoutDefault";
const mapping: Record<string, unknown> = {
    AppLayoutDefault: AppLayoutDefault,
    AppLayoutLogin: AppLayoutLogin,
    AppLayoutAdmin: AppLayoutAdmin,
    AppLayoutTransition: AppLayoutTransition
};

export default defineComponent({
    name: "AppLayout",
    computed: {
        layout() {
            if (this.$route.matched?.length > 0) {
                const layout = (this.$route.meta.layout as string) || defaultLayout;
                const component = mapping[layout];
                if (!component) throw new Error(`Layout '${layout}' not found.`);
                return component;
            } else {
                // This can happen when the route guard hasn't finished evaluating
                return AppLayoutTransition;
            }

        },
    },
});
</script>
