<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card>
            <q-card-section>
                <div class="q-pa-md">
                    <h1>
                        Day Parting
                        <small class="absolute mt-2 right-0 text-lg">4 Week Avg.</small>
                    </h1>

                    <div class="q-mb-md">
                        <SelectButton v-model="selectedMetricType" :options="metricTypeOptions" optionLabel="name"
                            optionValue="name" />
                    </div>

                    <q-markup-table :separator="'cell'">
                        <thead>
                            <tr>
                                <th></th>
                                <th v-for="weekDay of daysOfWeek">{{ formatDay(weekDay) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dayHour of hoursOfDay">
                                <td>{{ formatHour(dayHour) }}</td>
                                <td v-for="weekDay of daysOfWeek">{{
                                    calculateValue(selectedMetricType, lookupValue(weekDay.getDay(),
                                        dayHour.getHours()))
                                }}</td>
                            </tr>
                        </tbody>
                    </q-markup-table>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script  lang="ts" setup>
import SelectButton from "primevue/selectbutton";
</script>

<script lang="ts">
import type { IDaypartingEntry } from '@/services/history.service';
import { eachDayOfInterval, eachHourOfInterval, endOfDay, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';
import { defineComponent, type PropType } from 'vue';

type MetricType = 'Sales' | 'Orders' | 'Units Sold' | 'ACoS' | 'CVR' | 'Stats';

export default defineComponent({
    data() {
        const now = new Date();
        const weekOptions: { weekStartsOn: 1 } = { weekStartsOn: 1 };
        return {
            daysOfWeek: eachDayOfInterval({ start: startOfWeek(now, weekOptions), end: endOfWeek(now, weekOptions) }),
            hoursOfDay: eachHourOfInterval({ start: startOfDay(now), end: endOfDay(now) }),
            metricTypeOptions: [
                { name: "Sales" },
                { name: "Orders" },
                { name: "Units Sold" },
                { name: "ACoS" },
                { name: "CVR" },
                { name: "Stats" },
            ] as { name: MetricType }[],
            selectedMetricType: 'Sales' as MetricType,
        }
    },

    props: {
        dayPartData: { type: Object as PropType<IDaypartingEntry[]>, required: true }
    },

    emits: [
        'ok', 'hide'
    ],

    methods: {
        show() {
            (this.$refs.dialog as any).show();
        },

        hide() {
            (this.$refs.dialog as any).hide();
        },

        onDialogHide() {
            this.$emit('hide');
        },

        formatDay(date: Date) {
            return format(date, 'EEE');
        },

        formatHour(date: Date) {
            return format(date, 'ha');
        },

        lookupValue(dayOfWeek: number, hourOfDay: number) {
            // TODO: use lookup table instead of search
            const entry = this.dayPartData.find(a => a.dayOfWeek == dayOfWeek && a.hourOfDay == hourOfDay);
            return entry ?? null;
        },

        calculateValue(metricType: MetricType, entry: IDaypartingEntry | null) {
            if (entry == null) {
                return '';
            }

            switch (metricType) {
                case 'Sales':
                    return this.formatMoney(entry.attributedSales_30d! / entry.conversionPoints);
                case 'Orders':
                    return this.formatCount(entry.attributedConversions_30d! / entry.conversionPoints);
                case 'Units Sold':
                    return this.formatCount(entry.attributedUnitsOrdered_30d! / entry.conversionPoints);
                case 'CVR':
                    return entry.clicks != undefined && entry.clicks != 0 ?
                        this.formatPercentage(entry.attributedConversions_30d ?? 0 / entry.clicks) : '';
                case 'ACoS':
                    return entry.attributedSales_30d != undefined && entry.cost != undefined ?
                        this.formatPercentage(entry.cost / entry.attributedSales_30d) : '';
                case 'Stats':
                    return this.formatCount(entry.impressions) + '/' + this.formatCount(entry.clicks);
                default:
                    return '';
            }
        },

        formatMoney(value?: number) {
            if (value == null || !Number.isFinite(value)) return '';
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },

        formatCount(value?: number) {
            if (value == null || !Number.isFinite(value)) return '';
            return value >= 1_000_000 ? (value / 1_000_000).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) + 'M' : value.toLocaleString("en-US");
        },

        formatPercentage(value?: number) {
            if (value == null) return '';
            value = Math.min(value, 9.99); // max 999%

            return value.toLocaleString('en-US', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        },
    }
});
</script>

<style scoped>
tr td {
    text-align: right;
}

.q-dialog .q-dialog__inner>.q-card {
    max-width: 660px;
}
</style>
