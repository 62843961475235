<template>
    <h1>Profiles</h1>
    <LoadingIndicator v-if="loading" />
    <div class="profile-container hidden">
        <div v-for="profile in profiles" :key="profile.profileId" class="profile">
            <div>Profile ID: {{ profile.profileId }}</div>
            <div>Country Code: {{ profile.countryCode }}</div>
            <div>Daily Budget: {{ profile.dailyBudget }}</div>
            <button type="button" @click="queueDailyReports(profile)">Queue Daily Reports</button>
        </div>
    </div>

    <div style="max-width: 1200px">
        <q-splitter v-model="splitterRatio" style="min-width: 900px">
            <template v-slot:before>
                <q-tabs v-model="tab" vertical>
                    <q-tab v-for="profile in profiles" :name="profile.profileId" :label="profileTabName(profile)">
                        <sub :class="profileStatusColor(profile)">{{ profile.status }}</sub>
                    </q-tab>
                </q-tabs>
            </template>

            <template v-slot:after>
                <q-tab-panels v-model="tab" animated swipeable vertical transition-prev="jump-up" transition-next="jump-up"
                    @before-transition="onTabChange">
                    <q-tab-panel v-for="profile in profiles" :name="profile.profileId">
                        <div class="flex">
                            <h3 class="mt-0 mr-3">{{ profileTabName(profile) }}</h3>
                            <q-btn v-if="profile.status == 'Active'" color="primary" round flat style="top: -10px">
                                <i class="pi pi-cog text-xl"></i>
                                <q-menu>
                                    <q-list style="min-width: 100px">
                                        <q-item clickable v-close-popup @click="deactivateProfile(profile)">
                                            <q-item-section>
                                                Deactivate
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-menu>
                            </q-btn>
                        </div>

                        <div v-if="profile.status == 'Onboarding'">
                            <h4 class="mt-0">
                                Profile is currently being onboarded. {{ (profile.percentComplete ?? 1) * 100 }}% done!
                                <br>
                                <small>Please wait until it's finished.</small>
                            </h4>
                        </div>

                        <div v-else-if="profile.status == 'Inactive'">
                            <h4 class="mt-0">
                                Profile is inactive.
                            </h4>

                            <router-link :to="'/onboard?profileId=' + profile.profileId.toString()" class="no-underline">
                                <Button label="Activate Profile" class="ml-auto" />
                            </router-link>
                        </div>

                        <div v-else-if="customize">
                            <ProfileCustomizeOptions v-model="customize" :onboarding="false" :authorized="isAuthorized" />
                            <div v-if="isAuthorized" class="flex">
                                <Button @click="revertProfile(profile)" label="Undo Changes" icon="pi pi-undo"
                                    class="p-button-danger p-button-text -ml-2 mr-auto" />
                                <Button @click="saveProfile(profile)" label="Save" icon="pi pi-check-circle"
                                    class="ml-auto" />
                            </div>
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </template>
        </q-splitter>
    </div>
</template>

<script lang="ts" setup>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ProfileCustomizeOptions from "@/components/ProfileCustomizeOptions.vue";
import Button from "primevue/button";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import profileService from "@/services/profile.service";
import { ProfileStatus } from "@/models/profile-item";
import type { ProfileItem, ProfileCustomOptions } from "@/models/profile-item";
import advancedService from "@/services/advanced.service";
import userService from "@/services/user.service";
import { notify } from "@kyvg/vue3-notification";

interface Data {
    loading: boolean;
    profiles?: ProfileItem[];
    splitterRatio: number;
    tab: number;
    customize: ProfileCustomOptions | undefined;
    isAuthorized?: boolean;
    refreshInterval?: number;
}

export default defineComponent({
    data(): Data {
        const userInfo = userService.expectUserInfo();

        return {
            loading: true,
            profiles: undefined,
            splitterRatio: 20,
            tab: parseInt(userInfo.selectedProfileId),
            customize: undefined,
            isAuthorized: undefined,
            refreshInterval: undefined,
        };
    },
    async created() {
        this.profiles = await profileService.reloadProfiles();
        this.loading = false;

        this.loadProfileData(this.tab.toString());
    },
    methods: {
        async loadProfileData(profileId: string) {
            [this.customize, this.isAuthorized] = await Promise.all([
                profileService.getProfileSettings(profileId),
                profileService.checkIfAccountIsAuthorized(profileId)]);
        },
        async onTabChange(_newProfileId: string | number) {
            const selectedProfile = this.profiles!.find(a => a.profileId == this.tab)!;
            this.loadProfileData(this.tab.toString());

            // auto refresh for onboarding profile
            clearInterval(this.refreshInterval);
            if (selectedProfile.status == 'Onboarding') {
                this.refreshInterval = setInterval(async () => {
                    this.profiles = await profileService.reloadProfiles();
                }, 30 * 1000);
            }
        },
        async deactivateProfile(profile: ProfileItem) {
            const choice = await new Promise((resolve, _reject) => this.$q.dialog({
                title: 'Confirm',
                message: 'Are you sure you want to de-activate this profile?',
                cancel: true,
            }).onOk(() => resolve(true)).onCancel(() => resolve(false)));

            if (!choice) {
                return;
            }

            await profileService.deactivateProfile(profile.profileId.toString());
            window.location.reload();
        },
        async saveProfile(profile: ProfileItem) {
            await profileService.updateProfileSettings(profile.profileId.toString(), this.customize!);
            notify({ title: "Saved.", type: 'success' });
        },
        async revertProfile(profile: ProfileItem) {
            this.customize = await profileService.getProfileSettings(this.tab.toString());
            notify({ title: "Reverted" });
        },
        queueDailyReports: async function (profile: ProfileItem) {
            return advancedService.queueDailyReports(profile);
        },
        profileTabName(profile: ProfileItem) {
            return `${profile.accountInfo.name} - ${profile.countryCode}`;
        },
        profileStatusColor(profile: ProfileItem) {
            switch (profile.status) {
                case ProfileStatus.Active: return 'text-green';
                case ProfileStatus.Inactive: return 'text-blue';
                case ProfileStatus.Onboarding: return 'text-orange';
            }
        }
    },
});
</script>

<style scoped>
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    max-width: 400px;
}
</style>
