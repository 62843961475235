import axios from "axios";
import { ref, watch, type Ref } from "vue";

const cachedRefMap: { [key: string]: Ref<boolean> } = {};

export default {
    // A helper function for creating refs which are backed by local storage
    cachedRef(key: string) {
        if (key in cachedRefMap) {
            return cachedRefMap[key];
        }
        var cachedValue = this.getFromStorage<boolean>(key, false);
        var result = ref(cachedValue);
        watch(result, () => this.saveToStorage(key, result.value));
        return cachedRefMap[key] = result;
    },
    getFromStorage<T>(key: string, defaultValue: T) {
        try {
            var value = localStorage.getItem(key);
            if (value != null) {
                return JSON.parse(value);
            }
        } catch { }
        return defaultValue;
    },
    saveToStorage<T>(key: string, value: T) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch { }
    },
    async validate(jobIds: string[]) {
        const request = axios.post<boolean>(`/api/cache/validate`, jobIds);
        return (await request).data;
    },

    async getData<T>(jobId: string) {
        const request = axios.get<string>(`/api/cache/${encodeURIComponent(jobId)}`);
        const response = await request;
        // Download the data directly from the S3 bucket
        const downloadResponse = await axios.get<T>(response.data);
        return downloadResponse.data;
    },

    async waitAll(jobIds: string[], timeoutInSeconds = 900, pollIntervalInSeconds = 5) {
        // The polling promise
        let intervalId: number;
        const pollPromise = new Promise((resolve, _) => {
            const resolveWhenValid = async () => {
                const result = await this.validate(jobIds);
                if (result) {
                    resolve(result);
                }
            };
            intervalId = setInterval(resolveWhenValid, pollIntervalInSeconds * 1000);
            // Load data right away instead of waiting for the first interval
            resolveWhenValid();
        });

        try {
            // A promise that will timeout after the provided time
            const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(), timeoutInSeconds * 1000));
            await Promise.race([timeoutPromise, pollPromise]);
        } finally {
            // Always clear the interval
            clearInterval(intervalId!);
        }
    },
};
