export enum ProfileStatus {
    Active = "Active",
    Inactive = "Inactive",
    Onboarding = "Onboarding",
}

export interface ProfileItem {
    profileId: number;
    countryCode: string;
    dailyBudget: number;
    accountInfo: {
        name: string;
    };
    status: ProfileStatus;
    percentComplete?: number;
}

export interface ProfileCustomOptions {
    pauseExistingCampaigns: boolean;
    automateBudgetRebalance: boolean;
    automateKeywordAdditions: boolean;
    automateNegativeExactKeywordAdditions: boolean;
    defaultTargetAcos: number;
    defaultBidMax: number;
    defaultGrossMarginPercent: number;
    sellerCentralPortfolioName: string;
}

export function initEmptyProfileCustomOptions(): ProfileCustomOptions {
    return {
        pauseExistingCampaigns: true,
        automateBudgetRebalance: true,
        automateKeywordAdditions: true,
        automateNegativeExactKeywordAdditions: true,
        defaultTargetAcos: 18,
        defaultBidMax: 10.0,
        defaultGrossMarginPercent: 60.00,
        sellerCentralPortfolioName: 'Adjoli'
    };
}