import { isErrorType } from "@/models/error";
import { notify } from "@kyvg/vue3-notification";
import type { AxiosError } from "axios";
import type { App } from "vue";

function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError;
}

export function AxiosExceptionPlugin(app: App) {
    app.axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (isAxiosError(error)) {
                const errorCode = error.response?.status;
                if (errorCode && errorCode >= 400 && errorCode < 500) {
                    const data = error.response?.data;
                    if (typeof data == "object" && isErrorType(data)) {
                        notify({
                            title: data.message,
                            data: data.data,
                            type: "error",
                        });
                    }
                }
            }
            return Promise.reject(error);
        }
    );
}
