<template>
    <h1>Basic Settings</h1>

    <div>Name: {{ userInfo.name }}</div>
    <div>Email: {{ userInfo.email }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import userService from "@/services/user.service";
import type { UserDetails } from "@/models/user-details";

interface Data {
    userInfo: UserDetails;
}

export default defineComponent({
    data(): Data {
        return {
            userInfo: userService.expectUserInfo(),
        };
    },
});
</script>
