import type { ProductItem, ProductItemActivation, ProductItemPlus, ProductItemUpdate } from "@/models/product-item";
import type { TopKeyword } from "@/models/top-keyword";
import axios from "axios";
import type { AxiosError, AxiosResponse } from "axios";
import userService from "./user.service";
import cacheService from "./cache.service";
import type { DaypartingModel } from "@/models/dayparting";
import type { KeywordItem } from "@/models/keyword-item";

function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError;
}

async function catch404ToNull<T>(request: Promise<AxiosResponse<T>>) {
    try {
        const response = await request;
        return response.data;
    } catch (ex) {
        if (isAxiosError(ex)) {
            if (ex.response && ex.response.status === 404) return null;
        }
        throw ex;
    }
}

export default {
    async getProductHierarchy() {
        const request = axios.get<ProductItemPlus[]>(`/api/products/hierarchy`);
        return (await request).data;
    },
    async getProductDetails(asinId: string) {
        const request = axios.get<ProductItem>(`/api/products/hierarchy`, { params: { id: asinId } });
        return (await request).data;
    },
    async getProducts() {
        const userInfo = userService.expectUserInfo();
        const profileId = userInfo.selectedProfileId;

        const request = axios.get<ProductItem[]>(`/api/profiles/${profileId}/products`);
        return (await request).data;
    },
    async getTopKeywords(productId: string) {
        const request = axios.get<TopKeyword[]>(`/api/products/${productId}/top-keywords`);
        return await catch404ToNull(request);
    },
    async getTopKeywordsForProducts(productIds: string[]) {
        const request = axios.post<string>(`/api/products/top-keywords`, productIds);
        const jobId = (await request).data;
        // TODO: Consider replacing with direct invoke
        await cacheService.waitAll([jobId]);
        return await cacheService.getData<{ [key: string]: TopKeyword[] }>(jobId);
    },
    async getManagedTargets(productId: string) {
        const request = axios.get<KeywordItem[]>(`/api/products/${productId}/managed-targets`);
        let data = (await request).data;
        // The server doesn't set this property
        data.forEach(d => (d.isManaged = true));
        return data;
    },
    async activateProducts(products: ProductItemActivation[]) {
        const request = axios.post<string[]>(`/api/products/activate`, products);
        const jobIds = (await request).data;
        // TODO: Consider replacing with direct invoke
        await cacheService.waitAll(jobIds);
    },
    async updateProducts(products: ProductItemUpdate[]) {
        const request = axios.post<string[]>(`/api/products/update`, products);
        const jobIds = (await request).data;
        // TODO: Consider replacing with direct invoke
        await cacheService.waitAll(jobIds);
    },
    async updateSettings(products: ProductItem[]) {
        await axios.put(`/api/products/update-settings`, products);
    },
    async reset(products: ProductItem[]) {
        await axios.put(`/api/products/reset`, products);
    },
    async updateDayparting(model: DaypartingModel) {
        const request = axios.put<DaypartingModel>(`/api/products/dayparting`, model);
        return (await request).data;
    },
    async loadDayparting(asins: string[]) {
        const request = axios.post<DaypartingModel>(`/api/products/dayparting`, asins);
        return (await request).data;
    }
};
