import type { ProfileItem, ProfileCustomOptions } from "@/models/profile-item";
import axios from "axios";

export default {
    async onboard(profile: ProfileItem, profileConfig: ProfileCustomOptions) {
        await axios.post("/api/onboard", profileConfig, {
            params: {
                profileId: profile.profileId,
            }
        });
    },
};
