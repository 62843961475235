import type { UserDetails } from "@/models/user-details";
import axios from "axios";
import authService from "./auth.service";
import cacheService from "./cache.service";

let loaded = false;
let userInfo: UserDetails | null;

export let showDebugTools = cacheService.cachedRef('showDebugTools');

export default {
    async getUserInfo() {
        if (!loaded) await this.forceRefresh();
        return userInfo;
    },
    expectUserInfo(): UserDetails {
        if (userInfo === null) throw new Error("Expected user info to exist.");
        return userInfo;
    },
    async forceRefresh() {
        // TODO: prevent multiple calls
        const info = await authService.getUserInfo();
        loaded = true;
        userInfo = info;
    },
    forceLogout() {
        window.location.href = "/Auth/SignOut";
    },
    setProfileId(profileId: string | undefined) {
        axios.defaults.headers["api-profile-id"] = profileId;
    }
};

