import type { AmazonStateType } from "./product-item";

export interface KeywordItem {
    isManaged: boolean;
    state?: AmazonStateType;
    keywordText: string;
    
    // Used to improve the product targets UI
    expressionType?: TargetExpressionType
}

export enum TargetExpressionType
{
    AutoCloseMatch = 'AutoCloseMatch',
    AutoLooseMatch = 'AutoLooseMatch',
    AutoComplements = 'AutoComplements',
    AutoSubstitutes = 'AutoSubstitutes',
    ProductTargetExact = 'ProductTargetExact',
    KeywordTargetExact = 'KeywordTargetExact',
    KeywordTargetBroad = 'KeywordTargetBroad',
    KeywordTargetPhrase = 'KeywordTargetPhrase',
}

export function isAsin(keyword: string) : boolean {
    return /^b0\w{8}$/i.test(keyword);
}