import axios from "axios";
import { formatISO, parse } from "date-fns";
import type { IDateRange } from "./date-range.service";

const defaultDate = new Date(2010, 0, 1);

export interface IBidGraphItem {
    date: Date;

    impressions: number;
    clicks: number;
    orders: number;
    acos: number | null;
    bid: number;
}

class BidGraphItem implements IBidGraphItem {
    date!: Date;

    impressions: number;
    clicks: number;
    orders: number;
    acos: number | null;
    bid: number;

    constructor(data: IBidGraphItem, date: string) {
        this.date = parse(date, "yyyyMMdd", defaultDate);
        this.impressions = data.impressions;
        this.clicks = data.clicks;
        this.orders = data.orders;
        this.acos = data.acos;
        this.bid = data.bid;
    }
}

export type BidRecordTable = {
    [k: string]: IBidGraphItem;
};

export type BidRecordMap = Record<string, BidRecordTable>;

function mapBidRecordMap(data: BidRecordMap) {
    for (const [key, value] of Object.entries(data)) {
        data[key] = mapBidRecordTable(value);
    }
    return data;
}

function mapBidRecordTable(data: BidRecordTable) {
    for (const [date, value] of Object.entries(data)) {
        data[date] = new BidGraphItem(value, date);
    }
    return data;
}

function getData<T>(url: string, range: IDateRange, params = {}) {
    return axios.get<T>(url, {
        params: {
            start: formatISO(range.start, { representation: "date" }),
            end: formatISO(range.end, { representation: "date" }),
            ...params,
        },
    });
}

export default {
    async getKeywordBidMetrics(range: IDateRange, productId: string, keywordText: string, randomize = false) {
        // const response = await getData<BidRecordMap>(`/api/metrics/product/${productId}/${keywordText}/bids`, range, {
        //     randomize: randomize ? randomize : null,
        // });
        // return mapBidRecordMap(response.data);
        return Promise.resolve({});
    },
};
