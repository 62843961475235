import AdminVue from '@/views/Admin.vue';
import ProfilePage from "@/views/ProfilePage.vue";
import ProductPage from "@/views/ProductPage.vue";
import KeywordPageVue from "@/views/KeywordPage.vue";
import LoginVue from "@/views/Login.vue";
import SetupVue from "@/views/Setup.vue";
import SettingsVue from "@/views/Settings.vue";
import NotFoundVue from "@/views/NotFound.vue";
import ActionZoneVue from "@/views/ActionZone.vue";
import type { RouteRecordRaw } from "vue-router";

import SettingHome from "@/views/Settings/SettingHome.vue";
import SettingProfiles from "@/views/Settings/SettingProfiles.vue";
import SettingBilling from "@/views/Settings/SettingBilling.vue";
import SettingAdvanced from "@/views/Settings/SettingAdvanced.vue";
import DashboardPageVue from "@/views/DashboardPage.vue";
import OnboardVue from '@/views/Onboard.vue';
import DebugProductPage from '@/views/DebugProductPage.vue';

const settingsRoutes: RouteRecordRaw[] = [
    {
        path: "",
        name: "SettingHome",
        component: SettingHome,
    },
    {
        path: "profiles",
        name: "SettingProfiles",
        component: SettingProfiles,
    },
    {
        path: "billing",
        name: "SettingBilling",
        component: SettingBilling,
    },
    {
        path: "advanced",
        name: "SettingAdvanced",
        component: SettingAdvanced,
    },
];

export default [
    {
        path: "/login",
        name: "Login",
        component: LoginVue,
        meta: {
            layout: "AppLayoutLogin",
        },
    },
    {
        path: "/admin",
        name: "Admin",
        component: AdminVue,
        meta: {
            layout: "AppLayoutAdmin",
        },
    },
    {
        path: "/setup",
        name: "Setup",
        component: SetupVue,
        meta: {
            layout: "AppLayoutLogin", // TODO: actually a "no navbar" layout
        },
    },
    {
        path: "/onboard",
        name: "Onboard",
        component: OnboardVue,
        meta: {
            layout: "AppLayoutLogin", // TODO: actually a "no navbar" layout
        },
    },
    {
        path: "/Home",
        redirect: "/",
    },
    {
        path: "/",
        name: "Home",
        component: ProfilePage,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardPageVue,
    },
    {
        path: "/action-zone",
        name: "ActionZone",
        component: ActionZoneVue,
    },
    {
        path: "/group/:id",
        name: "Group",
        component: ProfilePage,
        props: true,
    },
    {
        path: "/product/:id",
        name: "Product",
        component: ProductPage,
        props: true,
    },
    {
        path: "/product/:id/debug",
        name: "ProductDebug",
        component: DebugProductPage,
        props: true,
    },
    {
        path: "/product/:productId/:keywordText",
        name: "Keyword",
        component: KeywordPageVue,
        props: true,
    },
    {
        path: "/product-activation",
        name: "ProductActivation",
        component: () => import("../views/ProductActivation.vue"),
        props: r => {
            var asins = r.query.asins;
            return { asins: typeof asins === 'string' ? [asins] : asins };
        }
    },
    {
        path: "/settings",
        name: "Settings",
        component: SettingsVue,
        children: settingsRoutes,
    },
    {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue"),
    },
    {
        path: "/dayparting",
        name: "Dayparting",
        component: () => import("../views/Dayparting.vue"),
        props: r => {
            var asins = r.query.asins;
            return { asins: typeof asins === 'string' ? [asins] : asins };
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFoundVue,
    },
] as Array<RouteRecordRaw>;
