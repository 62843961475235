<template>
    <div class="breadcrumb">
        <div v-if="crumbs.length == 0">Home</div>
        <div v-for="(crumb, key) in crumbs" :key="crumb">
            {{ crumb }}
            <span v-if="key != crumbs.length - 1">&gt;</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            crumbs: [] as string[],
        };
    },
    created() {
        this.$router.afterEach((to, _from) => {
            // Exclude the query string from the breadcrumb
            this.crumbs = to.path
                .split('/')
                .map(this.capitalizeFirstLetter)
                .filter(a => a != '');
        });
    },
    methods: {
        capitalizeFirstLetter(value: string) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    }
});
</script>

<style lang="scss" scoped>
.breadcrumb div {
    display: inline-block;
    margin-right: 0.25rem;
}
</style>
