<template>
  <DataTable :value="productRow.actionRows" :paginator="true" responsiveLayout="scroll" :rows="10"
    :rowsPerPageOptions="[5, 10, 25, 100]" :multiSortMeta="defaultSort" :sortMode="'multiple'" v-bind:filters="filters"
    filterDisplay="menu" v-on:update:filters="$emit('update:filters', $event)">
    <Column headerStyle="width: 3rem">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <Checkbox v-model="slotProps.data.isSelected" :binary="true" />
      </template>
    </Column>
    <Column v-if="!isAsin" headerStyle="width: 20rem" field="query.sanitized" filterField="query.sanitized"
      header="Sanitized" :sortable="true">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <div class="flex flex-nowrap">
          <div>{{ slotProps.data.query.sanitized }}</div>
          <AdjoliStateIcon class="ml-2" :state="slotProps.data.managedState" />
          <Button v-if="showModal" icon="pi pi-search" class="p-button-link" @click="showRowDetails(slotProps.data)" />
        </div>
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column v-if="!isAsin" headerStyle="width: 20rem" field="query.original" filterField="query.original"
      header="Original" :sortable="true">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column v-if="isAsin" headerStyle="width: 20rem" field="query.sanitized" filterField="query.sanitized" header="Asin"
      :sortable="true">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <div class="flex flex-nowrap">
          <AsinLink :asin="slotProps.data.query.sanitized" />
          <AdjoliStateIcon class="ml-2" :state="slotProps.data.managedState" />
        </div>
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column v-if="showInitialBid" field="initialBid" filterField="initialBid" header="Initial Bid" :sortable="true"
      dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatCurrency(slotProps.data.initialBid, '-') }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
      </template>
    </Column>
    <Column field="impressions" filterField="impressions" header="Impressions" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="decimal" />
      </template>
    </Column>
    <Column field="clicks" filterField="clicks" header="Clicks" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="decimal" />
      </template>
    </Column>
    <Column field="cost" filterField="cost" header="Spend" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatCurrency(slotProps.data.cost, '-') }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
      </template>
    </Column>
    <Column field="attributedSales30d" filterField="attributedSales30d" header="Sales" :sortable="true"
      dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatCurrency(slotProps.data.attributedSales30d, '-') }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
      </template>
    </Column>
    <Column field="attributedConversions30d" filterField="attributedConversions30d" header="Units Sold" :sortable="true"
      dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" />
      </template>
    </Column>
    <Column field="acos" filterField="acos" header="ACoS" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatAcos(slotProps.data.acos) }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-bind:model-value="filterModel.value ? filterModel.value * 100 : undefined"
          v-on:update:model-value="filterModel.value = $event / 100.0" mode="decimal" suffix="%" />
      </template>
    </Column>
    <Column headerStyle="width: 8rem">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <div class="flex flex-nowrap">
          <Button icon="pi pi-check" :loading="slotProps.data.isLoading"
            class="p-button-rounded p-button-success mr-1 p-button-xs"
            @click="$emit('suggestion:action', { action: 'approve', row: slotProps.data })"
            :disabled="state === 'approved'" />
          <Button icon="pi pi-times" :loading="slotProps.data.isLoading"
            class="p-button-rounded p-button-warning p-button-xs"
            @click="$emit('suggestion:action', { action: 'ignore', row: slotProps.data })"
            :disabled="state === 'ignored'" />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script lang="ts" setup>
import DataTable, { type DataTableFilterMeta, type DataTableSortMeta } from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import AdjoliStateIcon from "@/components/AdjoliStateIcon.vue";

</script>

<script lang="ts">
import type { ActionZoneProductRow, ActionZoneRow, KeywordActionZoneRow } from "@/models/action-zone";
import { defineComponent, type PropType } from "vue";
import { formatCurrency } from "@/services/kpi.service";
import AsinLink from "@/components/AsinLink.vue";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import ActionZoneSearchTermModal, { formatAcos } from "@/components/action-zone/ActionZoneSearchTermModal.vue";
import modalService from "@/services/modal.service";

export default defineComponent({
  data() {
    return {
      defaultSort: [
        { field: 'attributedSales30d', order: -1 }
      ] as DataTableSortMeta[],
    };
  },
  props: {
    renderKey: Number,
    actionType: String,
    showModal: Boolean,
    showInitialBid: Boolean,
    isAsin: Boolean,
    state: String,
    productRow: { type: Object as PropType<ActionZoneProductRow<KeywordActionZoneRow>>, required: true },
    filters: { type: Object as PropType<DataTableFilterMeta>, required: false },
  },
  created() {
    if (this.actionType === 'negative-phrase' || this.actionType === 'negative-exact') {
      this.defaultSort = [{ field: 'cost', order: -1 }];
    } else {
      this.defaultSort = [{ field: 'attributedSales30d', order: -1 }];
    }
  },
  methods: {
    showRowDetails(actionRow: ActionZoneRow) {
      modalService.show(ActionZoneSearchTermModal, {
        productRow: this.productRow,
        actionRow: actionRow,
      });
    }
  },
  emits: {
    ["update:filters"]: (value: DataTableFilterMeta) => true,
    ["suggestion:action"]: (value: { action: 'approve' | 'ignore', row: ActionZoneRow }) => true,
  }
});
</script>