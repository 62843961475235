<template>
    <div v-if="authorized == false" class="text-left">
        <h3>Authenticate to Seller Central</h3>
        <LoginButton login-url="/Auth/SellerLogin" class="amzn-login-btn" />
    </div>

    <ul v-if="authorized != false" class="list-none mt-0 pl-0">
        <li>
            <div>
                Pause existing campaigns when enabling a product?
                <Tooltip id="onboarding.customize.pauseExistingCampaigns" />

                <div v-if="!value.pauseExistingCampaigns" class="font-italic">
                    Leave existing campaigns as is
                </div>
            </div>
            <ToggleButton v-model="value.pauseExistingCampaigns" onLabel="Yes" offLabel="No" onIcon="pi pi-check"
                offIcon="pi pi-times" />
        </li>
        <li>
            <div>
                Automate budget rebalancing
                <Tooltip id="onboarding.customize.automateBudgetRebalance" />

                <div v-if="!value.automateBudgetRebalance" class="font-italic">
                    I want to approve all budget changes
                </div>
            </div>
            <ToggleButton v-model="value.automateBudgetRebalance" onLabel="Yes" offLabel="No" onIcon="pi pi-check"
                offIcon="pi pi-times" />

        </li>
        <li>
            <div>
                Automate keyword additions
                <Tooltip id="onboarding.customize.automateKeywordAdditions" />

                <div v-if="!value.automateKeywordAdditions" class="font-italic">
                    I want to approve keywords
                </div>
            </div>
            <ToggleButton v-model="value.automateKeywordAdditions" onLabel="Yes" offLabel="No" onIcon="pi pi-check"
                offIcon="pi pi-times" />
        </li>
        <li>
            <div>
                Automate negative exact keyword additions

                <div v-if="!value.automateNegativeExactKeywordAdditions" class="font-italic">
                    I want to approve negatives
                </div>
            </div>
            <ToggleButton v-model="value.automateNegativeExactKeywordAdditions" onLabel="Yes" offLabel="No"
                onIcon="pi pi-check" offIcon="pi pi-times" />
        </li>
        <li class="align-items-center">
            Default target ACoS

            <InputNumber v-model="value.defaultTargetAcos" :max="100" :min="0" suffix="%" />
        </li>
        <li class="align-items-center">
            Default bid maximum

            <InputNumber v-model="value.defaultBidMax" mode="currency" currency="USD" locale="en-US" />
        </li>
        <li class="align-items-center">
            Default gross margin percentage
            <InputNumber v-model="value.defaultGrossMarginPercent" :max="100" :min="5" :maxFractionDigits="2" />
        </li>
        <li class="align-items-center">
            Seller central portfolio name
            <InputText :readonly="!onboarding" :disabled="!onboarding" v-model="value.sellerCentralPortfolioName" :max="100"
                :min="5" />
        </li>
    </ul>
</template>

<script lang="ts" setup>
import Tooltip from "@/components/Tooltip.vue";
import ToggleButton from 'primevue/togglebutton';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import LoginButton from "./LoginButton.vue";
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ProfileCustomOptions } from "@/models/profile-item";

export default defineComponent({
    props: {
        modelValue: { type: Object as PropType<ProfileCustomOptions>, required: true },
        onboarding: { type: Boolean },
        authorized: { type: Boolean, default: undefined }
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value: ProfileCustomOptions) {
                this.$emit('update:modelValue', value)
            }
        }
    }
});
</script>

<style lang="scss" scoped>
li {
    display: flex;
    justify-content: space-between;

    .p-button {
        margin-left: 4rem;
        max-height: 2.5rem;
    }

    div {
        font-size: 1.125rem;
    }

    div:first-child {
        text-align: left;
    }

    .font-italic {
        font-size: 1rem;
    }
}

ul {

    .p-togglebutton,
    .p-inputnumber,
    .p-inputtext {
        margin-left: 1rem;
        margin-right: 0.5rem;
    }

    li>span {
        vertical-align: super;
    }

    li+li {
        margin-top: 1rem;
    }
}
</style>
