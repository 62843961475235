<template>
    <h1>Admin</h1>
    <TabView>
        <TabPanel header="Users">
            <AdminUsers></AdminUsers>
        </TabPanel>
        <TabPanel header="Jobs">
            <AdminJobs></AdminJobs>
        </TabPanel>
    </TabView>
</template>

<script lang="ts" setup>
import AdminJobs from '@/components/admin/AdminJobs.vue';
import AdminUsers from '@/components/admin/AdminUsers.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped></style>
