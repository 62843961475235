<template>
    <div class="flex justify-content-between">
        <h1 class="inline-block">Product Overview</h1>

        <DateRangePicker v-model="dateRange" />
    </div>

    <div>
        <ProductGraph :date-range="dateRange" :autocomplete="autocomplete" :top-products="topProducts"
            :product-data="productMetrics" :product-ids="childrenProductIds" />
    </div>

    <div>
        <div class="flex mt-6">
            <h2>
                <template v-if="id">Parent</template> Product Overview
                <Tooltip id="products.overview" />
            </h2>
        </div>

        <div>
            <ProductParentsTable :products="data" :groupings="id == null" v-model:campaign-type="campaignType" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import ProductGraph from "@/components/ProductGraph.vue";
import Tooltip from "@/components/Tooltip.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import ProductParentsTable from "@/components/ProductParentsTable.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import productService from "@/services/product.service";
import type { ProductItem, ProductItemPlus } from "@/models/product-item";
import metricsService from "@/services/metrics.service";
import type { IDateRange, IDateRangeSelection, IGraphItem, RecordMap } from "@/services/metrics.service";
import metrics2Service, { CampaignMetricType } from "@/services/metrics2.service";
import type { ProductItemMetric, ProductItemPlusMetric } from "@/models/metrics";

const nullMetric: IGraphItem = {
    date: null!,
    impressions: 0,
    clicks: 0,
    cost: 0,
    attributedConversions30d: 0,
    attributedUnitsOrdered30d: 0,
    attributedSales30d: 0,
    acos: null,
    conversionRate: null,
};

interface Data {
    dateRange: IDateRangeSelection;
    data?: ProductItemPlusMetric[];
    products?: ProductItem[];
    productHierarchy?: ProductItemPlus[];
    productMetrics?: RecordMap;
    campaignType: CampaignMetricType;
}

export default defineComponent({
    props: {
        id: {
            required: false,
            type: String,
            default: undefined,
        },
    },
    data(): Data {
        return {
            dateRange: metricsService.getDateRange(),
            data: undefined,
            productMetrics: undefined,
            productHierarchy: undefined,
            campaignType: CampaignMetricType.Product
        };
    },
    computed: {
        autocomplete(): string[] {
            const parents = this.productHierarchy?.map(a => a.asin);
            const children = this.productHierarchy?.filter(a => a.children).flatMap(a => a.children).map(a => a.asin);
            return this.productHierarchy ? [parents!, children!].flatMap(a => a) : [];
        },
        topProducts(): string[] {
            return metricsService.getTop5((this.productMetrics ?? {}) as RecordMap);
        },
        childrenProductIds(): string[] | undefined {
            return this.id ? this.productHierarchy?.map(a => a.asin) ?? undefined : undefined;
        }
    },
    watch: {
        id: {
            async handler() {
                this.productHierarchy = await productService.getProductHierarchy();

                if (this.id) {
                    const product = this.productHierarchy.find(a => a.asin == this.id);
                    this.productHierarchy = product?.children as ProductItemPlus[];
                }
            },
            immediate: true,
        }
    },
    mounted() {
        this.$watch(() => [this.dateRange, this.campaignType], this.getMetricData, { immediate: true });
        this.$watch(() => [this.productHierarchy, this.productMetrics], this.update);
    },
    methods: {
        update() {
            if (!this.productHierarchy || !this.productMetrics) {
                return;
            }

            this.data = this.productHierarchy as ProductItemPlusMetric[];

            this.data.forEach(a => {
                a.children?.forEach(this.copyMetrics);

                if (!this.copyMetrics(a) && a.children) {
                    metricsService.sumMetrics(a, a.children);
                }
            });
        },
        copyMetrics(item: ProductItemMetric) {
            const record = this.productMetrics![item.asin];
            const metric = record?.total as IGraphItem ?? nullMetric;

            metricsService.copyMetrics(item, metric);
            return metric != nullMetric;
        },
        async getMetricData() {
            this.productMetrics = await metrics2Service.getProductData(this.dateRange.value as IDateRange, this.campaignType);
        }
    },
});
</script>