<template>
    <DataTable :value="jobs" v-model:filters="filters" filterDisplay="menu" :globalFilterFields="filterFields"
        :sort-field="'lastExecutionDateUtc'" :sort-order="-1" :rows="10" :rowsPerPageOptions="[5, 10, 25, 100]"
        responsiveLayout="scroll" :paginator="true">
        <Column field="profileId" header="ProfileId" :sortable="true">
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="lastExecutionDateUtc" header="Last Ran" :sortable="true">
            <template #body="slotProps">{{ formatDate(slotProps.data) }}</template>
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="state" header="State" :sortable="true">
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="jobType" header="Type" :sortable="true">
            <template #body="slotProps">{{ formatJobType(slotProps.data.jobType) }}</template>
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="jobMethod" header="Method" :sortable="true">
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="runCount" header="Run Count" :sortable="true">
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="jobArguments" header="Arguments" :sortable="true">
            <template #body="slotProps">
                <Button v-if="slotProps.data.
                    lastError" icon="pi pi-exclamation-triangle" class="p-button-rounded p-button-danger p-button-xs"
                    @click="showArgumentDialog(slotProps.data)" />
            </template>
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
        <Column field="lastError" header="Last Error" :sortable="true">
            <template #body="slotProps">
                <Button v-if="slotProps.data.
                    lastError" icon="pi pi-exclamation-triangle" class="p-button-rounded p-button-danger p-button-xs"
                    @click="showErrorDialog(slotProps.data)" />
            </template>
            <template #filter="{ filterModel }">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Enter value" />
            </template>
        </Column>
    </DataTable>
    <Dialog header="Last Error" v-model:visible="showDialog" :style="{ width: '50vw' }">
        {{ dialogContent }}
    </Dialog>
</template>

<script lang="ts" setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';

</script>

<script lang="ts">
import type { JobRecord } from "@/models/admin";
import { defineComponent } from "vue";
import adminService from "@/services/admin.service";
import { format } from "date-fns";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import type { DataTableFilterMeta, DataTableOperatorFilterMetaData } from "primevue/datatable";


export default defineComponent({
    data() {
        return {
            jobs: [] as JobRecord[],
            showDialog: false,
            dialogContent: '',
            filterFields: ['profileId', 'lastExecutionDateUtc', 'state', 'jobType', 'jobMethod', 'runCount', 'jobArguments', 'lastError'],
            filters: {} as DataTableFilterMeta,
        };
    },
    async created() {
        // Setup the filters
        for (var field of this.filterFields) {
            this.filters[field] = {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
            } as DataTableOperatorFilterMetaData;
        }

        this.jobs = await adminService.getJobs();
    },
    methods: {
        formatDate(jobRecord: JobRecord) {
            if (!jobRecord.lastExecutionDateUtc) {
                return 'Not Started';
            }
            var d = new Date(jobRecord.lastExecutionDateUtc);
            return format(d, 'yyyy-MM-dd h:mm:ssaa');
        },
        formatJobType(value: string) {
            var components = value.split(".");
            return components[components.length - 1];
        },
        showErrorDialog(jobRecord: JobRecord) {
            this.showDialog = true;
            this.dialogContent = jobRecord.lastError;
        },
        showArgumentDialog(jobRecord: JobRecord) {
            this.showDialog = true;
            this.dialogContent = JSON.stringify(jobRecord.jobArguments);
        }
    }
});
</script>

<style scoped></style>
