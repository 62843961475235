<template>
    <div>
        <q-btn icon="event" color="primary" :label="computeLabel(model)" padding="none" align="left" class="pr-1">
            <q-popup-proxy ref="popupProxy" @before-show="initProxy" transition-show="jump-down" transition-hide="jump-up">
                <div class="date-range-modal flex flex-nowrap">
                    <Listbox v-model="model" :options="options" optionLabel="label" class="w-full md:w-14rem"
                        v-close-popup />
                    <div>
                        <Calendar :modelValue="proxyModel" @update:modelValue="update" inline :numberOfMonths="2"
                            :maxDate="maxDate" selectionMode="range" />
                    </div>
                </div>
            </q-popup-proxy>
        </q-btn>
    </div>
</template>

<script lang="ts" setup>
import { ref, type PropType, watchEffect } from 'vue';
import dateRangeService, { type IDateRangeSelection } from "@/services/date-range.service";
import { addDays, differenceInCalendarDays, format } from 'date-fns';
import Calendar, { type CalendarProps } from 'primevue/calendar';
import Listbox from 'primevue/listbox';
import type { QPopupProxy } from 'quasar';

const options = dateRangeService.getDateRangeList();
const popupProxy = ref<InstanceType<typeof QPopupProxy> | null>(null);
const maxDate = addDays(new Date(), -1);

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: { type: Object as PropType<IDateRangeSelection>, default: dateRangeService.getDateRange() },
    disabled: { type: Boolean, default: false }
});

const model = ref(props.modelValue);
watchEffect(() => {
    const value = model.value;
    emit('update:modelValue', value);

    // keep track of changes
    dateRangeService.setDateRange(value);
});

function computeLabel(model: IDateRangeSelection) {
    const formatValue = 'MMM d';

    if (model.label != 'Custom') {
        return model.label;
    }

    // same day
    if (model.value.start.getDate() == model.value.end.getDate()) {
        return format(model.value.start, 'MMMM d');
    }

    return format(model.value.start, formatValue) + " - " + format(model.value.end, formatValue);
};

type CalendarModelType = CalendarProps['modelValue'];
const proxyModel = ref<CalendarModelType>(undefined);

function initProxy() {
    const { start, end } = model.value.value;
    proxyModel.value = [start, end];
}

function update(newValue: CalendarModelType) {
    proxyModel.value = newValue;

    if (!Array.isArray(newValue)) {
        return;
    }

    const [from, to] = newValue;

    if (!(from && to)) {
        return;
    }

    if (!(from instanceof Date && to instanceof Date)) {
        return;
    }

    const dayRange = differenceInCalendarDays(to, from);
    const custom = options[0];
    custom.value = { start: from, end: to };
    custom.prev = { start: addDays(from, -dayRange - 1), end: addDays(from, -1) };

    model.value = { ...custom };
    popupProxy.value?.hide();
}
</script>

<style scoped>
.col {
    padding: 0;
}

:global(.date-range-modal .p-datepicker table td) {
    padding: 0.25rem !important;
}

:global(.date-range-modal .p-listbox) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

:global(.date-range-modal .p-calendar .p-datepicker) {
    border-left: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
