import { identity, sortBy } from "lodash-es";

export default {
    filterSuggestions<T>(suggestions: T[], query: string, predicateFn: (value: T) => string = identity) {
        const words = query.split(" ").map((a) => a.trim().toLowerCase());

        const contains = suggestions.filter((a) => {
            const value = predicateFn(a).toLowerCase();

            let lastIndex = 0;
            for (const w of words) {
                const index = value.indexOf(w, lastIndex);
                if (index == -1) {
                    return false;
                } else {
                    const spaceIndex = value.indexOf(" ", index);
                    lastIndex = spaceIndex == -1 ? value.length : spaceIndex;
                }
            }
            return true;
        });

        return sortBy(contains, (a) => {
            const value = predicateFn(a).toLowerCase();
            const bias = value.startsWith(query) ? 0 : 1;
            const startWordbias = value.startsWith(words[0]) ? 0 : 1;
            return bias.toString() + startWordbias.toString() + a;
        });
    },
};
