import type { SQSEventMessage } from "@/models/event";
import { notify } from "@kyvg/vue3-notification";

export default {
    initialize() {
        const eventSource = new EventSource("/api/sse/default");

        eventSource.addEventListener("message", (event) => {
            const message = event as MessageEvent;
            return notify(message.data);
        });

        eventSource.addEventListener("sqs", (event) => {
            const message = event as MessageEvent;
            const data = JSON.parse(message.data) as SQSEventMessage;
            const args = data.jobArguments.map((a) => JSON.parse(a));

            return notify({
                title: `Event Triggered: ${data.jobMethod}`,
                data: args,
            });
        });
    },
};
