import { FilterMatchMode, FilterOperator, FilterService } from "primevue/api";
import type { DataTableFilterMeta, DataTableFilterMetaData, DataTableOperatorFilterMetaData } from "primevue/datatable";

// Based on existing implementation of data table in primevue.
// https://github.com/primefaces/primevue/blob/8e7f749423d29ec69bad03f25c0e919c1d433c31/components/lib/datatable/DataTable.vue#L283
export function filterData<T>(data: T[], filters: DataTableFilterMeta) {
  let predicates = mapToPredicates(filters);
  return data.filter(x => predicates.every(p => p(x)));
}

function mapToPredicates<T>(filters: DataTableFilterMeta) {
  let predicates: ((item: T) => boolean)[] = [];
  for (let [field, filter] of Object.entries(filters)) {
    let filterMetaData = filter as DataTableFilterMetaData;
    let operatorMetaData = filter as DataTableOperatorFilterMetaData;
    if (operatorMetaData.operator === FilterOperator.AND) {
      predicates.push((item) => operatorMetaData.constraints.every(c => matchesConstraint(item, field, c)));
    }
    else if (operatorMetaData.operator === FilterOperator.OR) {
      predicates.push((item) => operatorMetaData.constraints.some(c => matchesConstraint(item, field, c)));
    }
    else if (filterMetaData.value != undefined) {
      predicates.push((item) => matchesConstraint(item, field, filterMetaData));
    }
    else {
      throw 'Unsupported filter';
    }
  }
  return predicates;
}

function matchesConstraint<T>(item: T, field: string, constraint: DataTableFilterMetaData) {
  let filterMatchMode = constraint.matchMode || FilterMatchMode.STARTS_WITH;
  var result = FilterService.filter([item], [field], constraint.value, filterMatchMode);
  return result.length > 0;
}