<template>
    <q-dialog ref="dialog" @hide="onDialogHide">
        <q-card>
            <q-card-section>
                <div class="q-pa-md">
                    <h1>
                        Budget History
                        <small class="absolute mt-2 right-0 text-lg">Last 60 days</small>
                    </h1>

                    <div class="q-mb-md">
                        <SelectButton v-model="selectedCampaignId" :options="campaignIds" />
                    </div>

                    <q-markup-table :separator="'cell'">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Budget Util</th>
                                <th>Total Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of rows">
                                <td>{{ formatDate(item.startTime) }}</td>
                                <td>{{ formatPercentage(item.budgetUsagePercentage) }}</td>
                                <td>{{ formatMoney(item.budget) }}</td>
                            </tr>
                        </tbody>
                    </q-markup-table>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script  lang="ts" setup>
import type { IBudgetHistoryItem } from "@/services/history.service";
import { formatValue, MetricType } from "@/services/kpi.service";
import { format } from "date-fns";
import { uniq } from "lodash";
import SelectButton from "primevue/selectbutton";
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    data() {
        return {
            selectedCampaignId: null as string | null
        }
    },

    props: {
        budgetData: { type: Object as PropType<IBudgetHistoryItem[]>, required: true }
    },

    emits: [
        'ok', 'hide'
    ],

    computed: {
        campaignIds() {
            return uniq(this.budgetData.map(a => a.budgetScopeId));
        },
        rows() {
            return this.budgetData.filter(a => a.budgetScopeId == this.selectedCampaignId);
        }
    },

    methods: {
        show() {
            (this.$refs.dialog as any).show();
        },

        hide() {
            (this.$refs.dialog as any).hide();
        },

        onDialogHide() {
            this.$emit('hide');
        },

        formatDate(value: string | Date) {
            const date = new Date(value);
            return format(date, 'P');
        },

        formatPercentage(value: number) {
            return formatValue(MetricType.Percentage, value / 100);
        },

        formatMoney(value: number) {
            return formatValue(MetricType.Money, value);
        }
    },
});
</script>

<style scoped>
tr td {
    text-align: right;
}

.q-dialog .q-dialog__inner>.q-card {
    max-width: 750px;
}
</style>
