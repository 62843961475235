import axios from "axios";
import { formatISO, parse } from "date-fns";
import { filter, groupBy, mapValues, meanBy, padStart, round, sumBy } from "lodash-es";
import type { IDateRange } from "./date-range.service";
import type { IGraphItem, RecordMap, RecordTable } from "./metrics.service";
import dashboardService, { type IOrderMetrics } from "./dashboard.service";

export type { IDateRange, IDateRangeSelection } from "./date-range.service";

interface IMetric {
    impressions: number;
    clicks: number;
    cost: number;
    orders: number;
    sales: number;
    conversions: number;
}

const GrossMarginSetting = 0.27;

const defaultDate = new Date(2010, 0, 1);
class GraphItem implements IGraphItem {
    date!: Date;

    impressions!: number;
    clicks!: number;
    cost!: number;
    attributedConversions30d!: number;
    attributedUnitsOrdered30d!: number;
    attributedSales30d!: number;

    // order metrics
    avgUnitPrice?: number;
    totalSales?: number;

    constructor(data: IMetric, date: string, orderMetric?: IOrderMetrics) {
        this.date = date == "total" ? null! : parse(date, "yyyyMMdd", defaultDate);
        this.impressions = data.impressions;
        this.clicks = data.clicks;
        this.cost = data.cost;
        this.attributedConversions30d = data.conversions;
        this.attributedUnitsOrdered30d = data.orders;
        this.attributedSales30d = data.sales;

        if (orderMetric) {
            this.avgUnitPrice = orderMetric.averageUnitPrice.amount;
            this.totalSales = orderMetric.totalSales.amount;
        }
    }

    get acos() {
        return this.attributedSales30d == 0 ? null : this.cost / this.attributedSales30d;
    }

    get conversionRate() {
        return this.clicks == 0 ? null : this.attributedConversions30d / this.clicks;
    }

    get organicSales() {
        return this.totalSales != undefined ? this.totalSales - this.attributedSales30d : undefined;
    }

    get grossProfit() {
        return this.avgUnitPrice != undefined ? this.avgUnitPrice * this.attributedUnitsOrdered30d * GrossMarginSetting : undefined;
    }

    get tacos() {
        return this.totalSales != undefined ? this.cost / this.totalSales : undefined;
    }
}

function mapRecordMap(data: RecordMap) {
    for (const [key, value] of Object.entries(data)) {
        data[key] = mapRecordTable(value);
    }
    return data;
}

function mapRecordTable(data: RecordTable, orderMetrics?: IOrderMetrics[]) {
    orderMetrics ??= [];
    const orderMap = convertOrderMetricsTable(orderMetrics);

    for (const [date, value] of Object.entries(data)) {
        data[date] = new GraphItem(value as any as IMetric, date, orderMap[date] ?? undefined);
    }

    const totalOrderMetric: IOrderMetrics | undefined = orderMetrics.length ? {
        startDate: 'total',
        averageUnitPrice: {
            amount: round(meanBy(orderMetrics, a => a.averageUnitPrice.amount), 2)
        },
        totalSales: {
            amount: round(sumBy(orderMetrics, a => a.totalSales.amount), 2)
        }
    } : undefined;

    data["total"] = new GraphItem(createTotal(Object.values(data)), "total", totalOrderMetric);
    return data;
}

function dateToKey(date: Date | string) {
    const parsedDate = new Date(date);
    return [
        parsedDate.getFullYear(),
        padStart((parsedDate.getMonth() + 1).toString(), 2, '0'),
        padStart((parsedDate.getDate()).toString(), 2, '0'),
    ].join('');
}

function convertOrderMetricsTable(orderMetrics: IOrderMetrics[]) {
    const groups = groupBy(orderMetrics, a => dateToKey(a.startDate));
    const orderMap = mapValues(groups, a => a[0]);
    orderMap.total = {
        averageUnitPrice: {
            amount: meanBy(Object.values(orderMap), a => a.averageUnitPrice.amount)
        },
        startDate: "",
        totalSales: {
            amount: sumBy(Object.values(orderMap), a => a.totalSales.amount),
        }
    };
    return orderMap;
}

function createTotal(items: IGraphItem[]): IMetric {
    return {
        impressions: sumBy(items, (a) => a.impressions),
        clicks: sumBy(items, (a) => a.clicks),
        cost: sumBy(items, (a) => a.cost),
        conversions: sumBy(items, (a) => a.attributedConversions30d),
        orders: sumBy(items, (a) => a.attributedUnitsOrdered30d),
        sales: sumBy(items, (a) => a.attributedSales30d),
    };
}


function createMetricTotal(items: IMetric[]): IMetric {
    return {
        impressions: sumBy(items, (a) => a.impressions),
        clicks: sumBy(items, (a) => a.clicks),
        cost: round(sumBy(items, (a) => a.cost), 2),
        conversions: sumBy(items, (a) => a.conversions),
        orders: sumBy(items, (a) => a.orders),
        sales: round(sumBy(items, (a) => a.sales), 2),
    };
}

function getData<T>(url: string, range: IDateRange, extraQueries: any = {}) {
    return axios.get<T>(url, {
        params: {
            start: formatISO(range.start, { representation: "date" }),
            end: formatISO(range.end, { representation: "date" }),
            ...extraQueries,
        },
    });
}

export enum CampaignMetricType {
    Product,
    Brand,
}

export default {
    async getOneProfileData(range: IDateRange, productIds?: string[], type?: CampaignMetricType) {
        const [response, orderInfo] = await Promise.all([
            getData<RecordMap>(`/api/metrics2/products`, range, { type }),
            (productIds ? Promise.resolve(undefined) : dashboardService.getOrderInfo(range)) // can't filter to order info for certain products
        ]);

        const values = filter(response.data, (_v, k) => !productIds || productIds.includes(k)).flatMap(a => Object.entries(a));
        const groups = groupBy(values, a => a[0]);
        const table = mapValues(groups, a => createMetricTotal(a.map(b => b[1] as any as IMetric)) as any as IGraphItem);

        return mapRecordTable(table as RecordTable, orderInfo);
    },
    async getOneProductData(range: IDateRange, productId: string, type?: CampaignMetricType) {
        const [response, orderInfo] = await Promise.all([
            getData<RecordMap>(`/api/metrics2/products`, range, { type }),
            dashboardService.getOrderInfo(range, productId)
        ]);

        const productData = response.data[productId] ?? {};
        return mapRecordTable(productData, orderInfo);
    },
    async getProductData(range: IDateRange, type?: CampaignMetricType) {
        const response = await getData<RecordMap>(`/api/metrics2/products`, range, { type });
        return mapRecordMap(response.data);
    },
    async getKeywordData(range: IDateRange, productId: string, type?: CampaignMetricType) {
        const response = await getData<RecordMap>(`/api/metrics2/keywords`, range, { type, productId });
        return mapRecordMap(response.data);
    },
    async getKWQueryData(range: IDateRange, productId: string) {
        const response = await getData<RecordMap>(`/api/metrics2/kw-query`, range, { productId });
        return mapRecordMap(response.data);
    },
    async getProductTargetData(range: IDateRange, productId: string) {
        const response = await getData<RecordMap>(`/api/metrics2/product-targets`, range, { productId });
        return mapRecordMap(response.data);
    },
    async getAutoTargetData(range: IDateRange, productId: string) {
        const response = await getData<RecordMap>(`/api/metrics2/auto-targets`, range, { productId });
        return mapRecordMap(response.data);
    },
};
