import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { Quasar, Dialog, type QuasarPluginOptions } from 'quasar';
import PrimeVue from "primevue/config";

import { VueCookieNext } from "vue-cookie-next";
import axios from "axios";
import VueAxios from "vue-axios";
import Notifications from "@kyvg/vue3-notification";
import Tooltip from "primevue/tooltip";

import notificationService from "./services/notification.service";
import { AxiosNoContentPlugin } from './middleware/axios-no-content-plugin';
import { AxiosExceptionPlugin } from "./middleware/axios-exception-plugin";
import { GlobalErrorHandlerPlugin } from './middleware/global-error-handler-plugin';

// CSS Section
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css"; //flexbox

import '@quasar/extras/material-icons/material-icons.css'; // Import icon libraries
import 'quasar/src/css/index.sass'; // Import Quasar css

import './assets/base.scss';

const app = createApp(App);

// external plugins
app.use(router);
app.use(VueCookieNext);
app.use(VueAxios, axios);
app.use(Notifications);
app.use(PrimeVue);
app.use(Quasar, {
    plugins: {
        Dialog
    },
} as QuasarPluginOptions);

// app plugins
app.use(AxiosNoContentPlugin);
app.use(AxiosExceptionPlugin);
app.use(GlobalErrorHandlerPlugin);

// Directives
app.directive("tooltip", Tooltip);

// provides
app.provide("axios", app.config.globalProperties.axios);

if (window.location.hostname == "localhost") {
    notificationService.initialize();
}

app.mount('#app');
