<template>
    <form id="login-with-amazon" method="post" :action="loginUrl">
        <input type="hidden" :value="xsrfToken" name="XSRF-TOKEN" />
        <button type="submit" style="border: none; background: none">
            <img border="0" alt="Login with Amazon"
                src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_312x64.png" width="156"
                height="32" />
        </button>
    </form>
</template>

<script lang="ts" setup>
import { useCookie } from "vue-cookie-next";

const cookie = useCookie();
const xsrfToken = cookie.getCookie("XSRF-TOKEN") as string;
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        loginUrl: {
            type: String,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
button[type="submit"] {
    cursor: pointer;
}
</style>
