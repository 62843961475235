<template>
    <div class="mb-3">
        <span>Copyright 2022 Adjoli </span>
        <a href="#">Privacy</a>
        <a href="#">Terms</a>
    </div>
</template>

<style lang="scss" scoped>
a {
    color: #ca8434;
    text-decoration: none;
}
</style>
