<template>
    <div class="shadow-3 box relative" :class="{ active, disabled }" :title="disabled ? 'Not implemented' : undefined">
        <div v-if="loading" class="loading flex absolute align-items-center justify-content-center">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem" />
        </div>

        <span>
            {{ title }}
            <Tooltip v-if="tooltip" :id="tooltip" />
        </span>
        <div class="metric">
            {{ formattedValue }}
            <!-- TODO: add loading indicator if undefined -->

            <span v-if="prevValue !== undefined" class="indicator" :class="indicatorClasses">
                {{ formattedPercentDiff }}
            </span>
        </div>

        <div v-if="prevValue !== undefined" class="metric-prev mt-2 text-base">
            {{ formattedPrevValue }}
            <small class="font-light text-xs">previous period</small>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Tooltip from "@/components/Tooltip.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import { MetricType, formatValue } from "@/services/kpi.service";

export default defineComponent({
    props: {
        title: { type: String, required: true },
        value: { type: Number, required: false, default: undefined },
        prevValue: { type: Number, required: false, default: undefined },
        type: { type: String as () => MetricType, required: true },
        active: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        tooltip: { type: String, default: null },
        reverseIndicator: { type: Boolean, default: false }
    },
    computed: {
        formattedValue(): string {
            return formatValue(this.type, this.value);
        },
        formattedPrevValue(): string {
            return formatValue(this.type, this.prevValue);
        },
        percentDiff(): number {
            return ((this.value ?? 0) / (this.prevValue ?? 0)) - 1;
        },
        formattedPercentDiff(): string {
            return Math.abs(this.percentDiff).toLocaleString('en-US', {
                style: 'percent',
                maximumFractionDigits: 0
            });
        },
        indicatorClasses() {
            const up = this.percentDiff >= 0;
            return {
                'indicator-up': up,
                'indicator-down': !up,
                'reverse': this.reverseIndicator
            };
        }
    },
});
</script>

<style lang="scss" scoped>
.box {
    cursor: pointer;

    &:hover {
        background-color: rgb(254 192 94);
        color: white;

        &:not(.active) {
            .metric {
                color: white;
            }
        }
    }

    &.active {
        background-color: #2ca1aa;
        color: white;
    }

    &.disabled {
        cursor: not-allowed;
    }

    min-width: 200px;
    min-height: 97px;
    padding: 1rem;
    text-align: left;
    font-size: 1.1rem;

    .metric {
        color: #fec05e;
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }
}

.loading {
    margin: -1rem;
    width: 100%;
    height: 100%;
    background-color: #ffffff80;

    i {
        color: white;
    }
}

.box:not(.active):not(:hover) {
    .metric-prev {
        color: var(--gray-600) !important;
    }
}

.indicator {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-left: 0.25rem;
    float: right;

    &.indicator-up:not(.reverse),
    &.indicator-down.reverse {
        color: var(--green-400);
    }

    &.indicator-down:not(.reverse),
    &.indicator-up.reverse {
        color: #f21c0d;
    }

    &.indicator-up {
        &::before {
            content: "↑";
        }
    }

    &.indicator-down {
        &::before {
            content: "↓";
        }
    }
}
</style>
