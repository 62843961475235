<template>
    <table>
        <caption>Queue Events</caption>
        <thead>
            <tr>
                <th>Date Created</th>
                <th>Method</th>
                <th class="limit-length">Parameters</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data" :key="item.id">
                <td :title="formatLongDate(item.date)">{{ formatDate(item.date) }}</td>
                <td :title="identifier(item)">{{ item.message.jobMethod }}</td>
                <td :title="formatArgs(item.message.jobArguments)">
                    <DebugObjectTable :data="args(item.message.jobArguments)" />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts" setup>
import DebugObjectTable from "./DebugObjectTable.vue";
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { SQSEvent } from "@/models/event";
import { formatRelative } from "date-fns";

export default defineComponent({
    props: {
        data: { type: Array as PropType<SQSEvent[]>, required: true },
    },
    methods: {
        formatDate(date: Date) {
            return this.capitalize(formatRelative(date, new Date()));
        },
        formatLongDate(date: Date) {
            return date.toISOString();
        },
        formatArgs(args: string[]) {
            return args.join("\n");
        },
        identifier(event: SQSEvent) {
            const firstDash = event.id.indexOf("-");
            const firstHash = event.id.substring(0, firstDash);
            return event.message.jobMethod + "-" + firstHash;
        },
        args(args: string[]) {
            return args.map((a) => JSON.parse(a));
        },
        capitalize(s: string) {
            return s[0].toUpperCase() + s.substring(1);
        },
    },
});
</script>

<style scoped>
.limit-length {
    overflow: hidden;
    width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
