<template>
    <div class="flex flex-nowrap">
        <div class="nav min-h-screen">
            <img src="@/assets/adjoli-logo-white-teal-orange.png" height="80" />
            <div class="px-4">
                <hr />
                <NavBar />
            </div>
        </div>
        <div class="flex-grow-1">
            <div class="header flex justify-content-between align-items-center pl-3 pr-4 py-1">
                <BreadCrumb />
                <UserMenu />
            </div>
            <div class="content px-6 pt-4 pb-8">
                <slot />
            </div>
            <div class="footer px-6 static bottom-0 w-full">
                <AppFooter />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import NavBar from "./components/NavBar.vue";
import BreadCrumb from "./components/BreadCrumb.vue";
import UserMenu from './components/UserMenu.vue';
import AppFooter from "./components/AppFooter.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss">
.nav {
    background-color: #fec05e;
    border-right: 1px solid #bababa;
    min-width: 240px;
}

.header {
    background-color: #f9f4ed;
}

hr {
    border-top: none;
    border-bottom: 1px solid white;
    margin-top: 0;
}
</style>
