import axios from "axios";
import { formatISO } from "date-fns";
import type { IDateRange } from "./date-range.service";

export type { IDateRange, IDateRangeSelection } from "./date-range.service";

export interface IOrderMetrics {
    averageUnitPrice: {
        amount: number;
    };
    totalSales: {
        amount: number;
    };
    startDate: Date | string;
    // DateInterval: [Date, Date];
}

function getData<T>(url: string, range: IDateRange, extraQueries: any = {}) {
    return axios.get<T>(url, {
        params: {
            start: formatISO(range.start, { representation: "date" }),
            end: formatISO(range.end, { representation: "date" }),
            ...extraQueries,
        },
    });
}

export default {
    async getProfileOrderInfo(range: IDateRange) {
        const response = await getData<IOrderMetrics[]>(`/api/dashboard/orders`, range);
        return response.data;
    },
    async getProductOrderInfo(range: IDateRange, productId: string) {
        const response = await getData<IOrderMetrics[]>(`/api/dashboard/orders`, range, { productId });
        return response.data;
    },
    async getOrderInfo(range: IDateRange, productId?: string) {
        return productId ? this.getProductOrderInfo(range, productId) : this.getProfileOrderInfo(range);
    }
};
