<template>
  <DataTable :value="productRow.actionRows" :paginator="true" responsiveLayout="scroll" :rows="10"
    :rowsPerPageOptions="[5, 10, 25, 100]" :multiSortMeta="defaultSort" :sortMode="'multiple'" v-bind:filters="filters"
    filterDisplay="menu" v-on:update:filters="$emit('update:filters', $event)">
    <Column headerStyle="width: 3rem">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <Checkbox v-model="slotProps.data.isSelected" :disabled="!allowActions" :binary="true" />
      </template>
    </Column>
    <Column headerStyle="width: 16rem" field="campaignType" filterField="campaignType" header="Campaign Type"
      :sortable="true">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <div class="flex flex-nowrap">
          <div>{{ formatCampaignType(slotProps.data.campaignType) }}</div>
          <AdjoliStateIcon class="ml-2" :state="slotProps.data.managedState" />
        </div>
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column field="suggestedBudget" filterField="suggestedBudget" header="Suggested Budget" :sortable="true"
      dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        {{ formatCurrency(slotProps.data.campaignBudget) }}
        <i class="pi pi-arrow-right" :style="{ color: budgetArrowColor(slotProps.data) }" />
        {{ formatCurrency(slotProps.data.suggestedBudget) }}
      </template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="decimal" />
      </template>
    </Column>
    <Column headerStyle="width: 20rem" field="dailyBudgetUtilization" header="Daily Budget Utilization" :sortable="true"
      dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <div>
          <span>Min: {{ formatPercent(slotProps.data.dailyBudgetUtilization.min) }}</span>
          &nbsp;
          <span>Max: {{ formatPercent(slotProps.data.dailyBudgetUtilization.max) }}</span>
          &nbsp;
          <span>Avg: {{ formatPercent(slotProps.data.dailyBudgetUtilization.avg) }}</span>
        </div>
      </template>
    </Column>
    <Column field="clicks" filterField="clicks" header="Clicks" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="decimal" />
      </template>
    </Column>
    <Column field="cost" filterField="cost" header="Spend" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatCurrency(slotProps.data.cost, '-') }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
      </template>
    </Column>
    <Column field="attributedSales30d" filterField="attributedSales30d" header="Sales" :sortable="true"
      dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatCurrency(slotProps.data.attributedSales30d, '-') }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
      </template>
    </Column>
    <Column field="acos" filterField="acos" header="ACoS" :sortable="true" dataType="numeric">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatAcos(slotProps.data.acos) }}</template>
      <template #filter="{ filterModel }">
        <InputNumber v-bind:model-value="filterModel.value ? filterModel.value * 100 : undefined"
          v-on:update:model-value="filterModel.value = $event / 100.0" mode="decimal" suffix="%" />
      </template>
    </Column>
    <Column headerStyle="width: 12rem" field="lastActionDate" v-if="!allowActions" filterField="lastActionDate"
      header="Last Action" :sortable="true" dataType="date">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">{{ formatDate(slotProps.data.lastActionDate) }}</template>
      <template #filter="{ filterModel }">
        <Calendar id="calendar-12h" v-model="filterModel.value" showTime hourFormat="12" />
      </template>
    </Column>
    <Column headerStyle="width: 8rem" v-if="allowActions">
      <template #header>
        <div class="padding-placeholder"></div>
      </template>
      <template #body="slotProps">
        <div class="flex flex-nowrap">
          <Button icon="pi pi-check" :loading="slotProps.data.isLoading"
            class="p-button-rounded p-button-success mr-1 p-button-xs"
            @click="$emit('suggestion:action', { action: 'approve', row: slotProps.data })" />
          <Button icon="pi pi-times" :loading="slotProps.data.isLoading"
            class="p-button-rounded p-button-warning p-button-xs"
            @click="$emit('suggestion:action', { action: 'ignore', row: slotProps.data })" />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script lang="ts" setup>
import DataTable, { type DataTableFilterMeta, type DataTableSortMeta } from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import AdjoliStateIcon from "@/components/AdjoliStateIcon.vue";
import Button from "primevue/button";
</script>

<script lang="ts">
import { CampaignType, type ActionZoneProductRow, type ActionZoneRow, type BudgetActionZoneRow } from "@/models/action-zone";
import { defineComponent, type PropType } from "vue";
import { formatCurrency } from "@/services/kpi.service";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import ActionZoneSearchTermModal, { formatAcos } from "@/components/action-zone/ActionZoneSearchTermModal.vue";
import modalService from "@/services/modal.service";
import format from "date-fns/format";
import isDate from 'date-fns/isDate';

export default defineComponent({
  data() {
    return {
      defaultSort: [
        { field: 'lastActionDate', order: -1 },
        { field: 'campaignType', order: 1 }
      ] as DataTableSortMeta[],
    };
  },
  props: {
    renderKey: Number,
    actionType: String,
    showModal: Boolean,
    showInitialBid: Boolean,
    isAsin: Boolean,
    state: String,
    productRow: { type: Object as PropType<ActionZoneProductRow<BudgetActionZoneRow>>, required: true },
    filters: { type: Object as PropType<DataTableFilterMeta>, required: false }
  },
  computed: {
    allowActions() {
      return this.state === 'pending';
    }
  },
  methods: {
    budgetArrowColor(actionRow: BudgetActionZoneRow) {
      var campaignBudget = actionRow.campaignBudget ?? 0;
      var suggestedBudget = actionRow.suggestedBudget ?? 0;
      if (campaignBudget < suggestedBudget) {
        return 'green';
      }
      else if (campaignBudget > suggestedBudget) {
        return 'red';
      } else {
        return 'black';
      }
    },
    showRowDetails(actionRow: ActionZoneRow) {
      modalService.show(ActionZoneSearchTermModal, {
        productRow: this.productRow,
        actionRow: actionRow,
      });
    },
    formatCampaignType(campaignType: CampaignType) {
      return {
        [CampaignType.Auto]: 'Auto',
        [CampaignType.BroadKeywords]: 'Broad Keywords',
        [CampaignType.ExactKeywords]: 'Exact Keywords',
        [CampaignType.PhraseKeywords]: 'Phrase Keywords',
        [CampaignType.ProductTargeting]: 'Product Targeting',
      }[campaignType];
    },
    formatPercent(value: number) {
      return (value * 100).toFixed(0).toString() + '%';
    },
    formatDate(date: Date | string) {
      if (!date) {
        return '-';
      }
      if (!isDate(date)) {
        date = new Date(date);
      }
      return format(date as Date, 'Pp');
    },
  },
  emits: {
    ["update:filters"]: (value: DataTableFilterMeta) => true,
    ["suggestion:action"]: (value: { action: 'approve' | 'ignore', row: ActionZoneRow }) => true,
  }
});
</script>