<template>
    <Dialog :modal="true" v-model:visible="visible" header="Budget History">
        <SelectButton v-model="selectedCampaign" :options="campaignOptions">
            <template #option="slotProps">
                <div>{{ slotProps.option }}</div>
            </template>
        </SelectButton>

        <DataTable :value="items">
            <Column field="date" header="Date">
                <template #body="slotProps">{{ formatDate(slotProps.data.date) }}</template>
            </Column>
            <Column field="inBudget" header="Budget">
                <template #body="slotProps">{{ formatBudgetStatus(slotProps.data) }}</template>
            </Column>
            <Column field="timeInBudget" header="Duration">
                <template #body="slotProps">
                    {{ (slotProps.index == 0 && slotProps.data.timeInBudget == 0) ? 'Ongoing' :
                    formatCustomDuration(slotProps.data.timeInBudget)
                    }}
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SelectButton from "primevue/selectbutton";
</script>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { uniq } from "lodash-es";
import { differenceInMinutes, format, formatDuration } from "date-fns";
import historyService, { type IHistoryRecord } from "@/services/history.service";

enum EventType {
    Budget = 'IN_BUDGET',
    Status = 'STATUS',
}

interface IEvent {
    event: EventType;
    campaignId: string;
    campaignStatus: string;
    inBudget: boolean;
    timeInBudget: number;
    date: Date;
}

export default defineComponent({
    data() {
        return {
            visible: true,
            selectedCampaign: null as string | null,
            campaignOptions: [] as string[] | undefined,
            items: undefined as IEvent[] | undefined,
            allItems: undefined as IHistoryRecord[] | undefined,
        }
    },
    props: {
        keywordIds: { type: Object as PropType<string[]>, required: true }
    },
    async created() {
        const bids = await historyService.getCampaignBudgetHistory(this.keywordIds);
        this.allItems = bids.events;

        const metas = uniq(bids.events.map(a => a.entityId));
        this.campaignOptions = metas;

        this.selectedCampaign = metas.length ? metas[0] : null;

    },
    watch: {
        selectedCampaign(campaignId: string) {
            this.items = this.allItems?.filter(a => a.entityId == campaignId).map(a => {
                return {
                    event: a.changeType as EventType,
                    campaignId: a.entityId,
                    campaignStatus: a.changeType == EventType.Status ? a.newValue : '',
                    inBudget: a.changeType == EventType.Budget ? JSON.parse(a.newValue) : false,
                    timeInBudget: 0,
                    date: new Date(a.timestamp)
                };
            });

            let outOfBudgetDate: Date | null = null;
            this.items?.filter(a => a.event == EventType.Budget).forEach(item => {
                if (!item.inBudget) {
                    outOfBudgetDate = item.date as Date;
                } else if (outOfBudgetDate != null) {
                    item.timeInBudget = differenceInMinutes(outOfBudgetDate, item.date as Date);
                    outOfBudgetDate = null;
                }
            });
        },
    },
    methods: {
        formatDate(date: Date) {
            return format(date, 'Pp');
        },
        formatBudgetStatus(event: IEvent) {
            switch (event.event) {
                case EventType.Budget: return event.inBudget ? 'In Budget' : 'Out of Budget';
                case EventType.Status: return event.campaignStatus;
                default: return '';
            }
        },
        formatCustomDuration(value: number) {
            const hours = Math.floor(value / 60);
            const minutes = value - (hours * 60);
            return value > 24 * 60 ? '> 24 Hours' : formatDuration({ hours, minutes });
        }
    }
});
</script>