<template>
    <Badge v-tooltip="text ? text : fromKey(id)" value="i" class="surface-800" />
</template>

<script lang="ts" setup>
import Badge from "primevue/badge";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import Tooltip from 'primevue/tooltip';
import tooltipService from "@/services/tooltip.service";

export default defineComponent({
    directives: {
        tooltip: Tooltip,
    },
    props: {
        text: { type: String, default: undefined },
        id: { type: String, required: true },
    },
    methods: {
        fromKey(identifier: string) {
            return tooltipService(identifier);
        }
    }
});
</script>

<style scoped>
.p-badge {
    margin-left: 0.25rem;
    height: 1rem;
    vertical-align: top;
    line-height: 1rem;
    min-width: 1rem;
    cursor: default;
}
</style>
