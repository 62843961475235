<template>
    <div class="mb-3">
        <div class="flex justify-content-between">
            <div>
                <h1 class="inline-block mr-3">{{ productId }} - {{ keywordText }}</h1>

                <Button icon="pi pi-bars" type="button" class="p-button-outlined" style="vertical-align: top"
                    @click="showMenu" />
                <Menu ref="menuRef" :model="menuItems" :popup="true" />
            </div>

            <div>
                <DateRangePicker v-model="dateRange" />

                <!-- <label class="block">
                    Example Data
                    <input v-model="randomize" type="checkbox" />
                </label> -->
            </div>
        </div>
    </div>

    <div class="flex flex-center">
        <div>
            <label v-if="currentBid">Bid: {{ formatMoney(currentBid) }}</label>
            <label>Unit Price: {{ formatMoney(price) }}</label>
            <label>Target: {{ formatPercentage(targetAcos) }}</label>
        </div>

        <SelectButton v-model="matchType" :options="matchTypeOptions" />

        <q-slider class="ml-3" style="max-width: 150px" v-model="orderDiff" marker-labels :min="0" :max="6" />

        <i v-if="multiCampaignType" class="ml-5 pi pi-exclamation-triangle text-2xl"
            title="Multiple campaigns for match type"></i>
    </div>
    <div class="mt-4">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th v-for="day of dailyDays" :class="borderClass(day)">{{ formatDay(day) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Impressions</td>
                    <td v-for="stat of dailyStats" :class="borderClass(stat?.startTime)">
                        <span v-if="stat">{{ formatCount(stat.impressions) }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Clicks</td>
                    <td v-for="stat of dailyStats" :class="borderClass(stat?.startTime)">
                        <span v-if="stat">{{ formatCount(stat.clicks) }}</span>
                    </td>
                </tr>
                <tr>
                    <td>CVR</td>
                    <td v-for="stat of dailyStats" :class="borderClass(stat?.startTime)">
                        <span v-if="stat">{{ formatPercentage(calcConversionRate(stat)) }}</span>
                    </td>
                </tr>
                <tr>
                    <td>Bid</td>
                    <td v-for="stat of dailyBids" :class="borderClass(stat?.startTime)">
                        <span v-if="stat">{{ formatMoney(stat.item2) }}</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <table class="mt-4">
            <thead>
                <tr>
                    <th></th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Impressions</td>
                    <td>{{ formatCount(sumBy(dailyStats, 'impressions')) }}</td>
                </tr>
                <tr>
                    <td>Clicks</td>
                    <td>{{ formatCount(sumBy(dailyStats, 'clicks')) }}</td>
                </tr>
                <tr>
                    <td>Orders</td>
                    <td>{{ formatCount(sumBy(dailyStats, 'attributedUnitsOrdered_30d')) }}</td>
                </tr>
                <tr>
                    <td>ACoS</td>
                    <td>{{ formatPercentage(calcAcosTotal(dailyStatValues)) }}</td>
                </tr>
                <tr>
                    <td>CVR</td>
                    <td>{{ formatPercentage(calcConversionRateTotal(dailyStatValues)) }}</td>
                </tr>
                <tr>
                    <td>CBid<sub>avg</sub></td>
                    <td>{{ formatMoney(calcBid(dailyStatValues)) }}</td>
                </tr>
                <tr>
                    <td>CBid<sub>min</sub></td>
                    <td>{{ formatMoney(calcBid(dailyStatValues, -orderDiff)) }}</td>
                </tr>
                <tr>
                    <td>CBid<sub>max</sub></td>
                    <td>{{ formatMoney(calcBid(dailyStatValues, orderDiff)) }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- <div>
        <SelectButton v-model="chartType" :options="chartTypeOptions" />
    </div>-->

    <div class="mb-3">
        <div>
            <SelectButton class="inline-block" v-model="chartType" :options="chartTypeOptions" />

            <label v-if="chartType == 'Time Series'" class="font-bold line-height-4 ml-3 vertical-top">
                Weekly
                <input type="checkbox" v-model="weeklyPlot" />
            </label>
        </div>

        <Chart :options="chartOptions" />
        <Chart v-if="chartOptions2" :options="chartOptions2" />

        <Chart style="height: 100px" v-if="chartOptions3 && chartType == 'Time Series'" :options="chartOptions3" />
    </div>

    <table>
        <thead>
            <tr>
                <th>Week</th>
                <th v-for="weekDay of weeklyWeeks">{{ formatDay(weekDay) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Impressions</td>
                <td v-for="stat of weeklyImpressions">
                    <span v-if="stat">{{ formatCount(stat) }}</span>
                </td>
            </tr>
            <tr>
                <td>Clicks</td>
                <td v-for="stat of weeklyClicks">
                    <span v-if="stat">{{ formatCount(stat) }}</span>
                </td>
            </tr>
            <tr>
                <td>CTR</td>
                <td v-for="stat of weeklyCTR">
                    <span v-if="stat">{{ formatPercentage(stat) }}</span>
                </td>
            </tr>
            <tr>
                <td>CVR</td>
                <td v-for="stat of weeklyCVR">
                    <span v-if="stat">{{ formatPercentage(stat) }}</span>
                </td>
            </tr>
        </tbody>
    </table>

    <span></span>
</template>

<script lang="ts" setup>
import Chart from "@/components/Chart.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import SelectButton from "primevue/selectbutton";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { min, max, minBy, maxBy, sumBy, round } from "lodash-es";
</script>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { format, parse, eachDayOfInterval, startOfWeek } from "date-fns";
import metricsService, { type IDateRange } from "@/services/metrics.service";
import bidMetricsService from "@/services/bidMetrics.service";
import type { BidRecordMap, BidRecordTable, IBidGraphItem } from "@/services/bidMetrics.service";
import Highcharts, { type PlotAreaOptions, type XAxisOptions, type YAxisOptions } from "highcharts";
import { regressionLoess, regressionLinear, regressionPoly } from "d3-regression";
import { cloneDeep, sum, uniqBy, groupBy, zipWith, throttle } from "lodash-es";
import modalService from "@/services/modal.service";
import BidHistoryModal from "@/components/modals/BidHistoryModal.vue";
import BudgetHistoryModal from "@/components/modals/BudgetHistoryModal.vue";
import type { MenuItem } from 'primevue/menuitem';
import type MenuComponent from 'primevue/menu';
import targetMetricService, { type IKWDailyStat } from "@/services/targetMetric.service";
import productService from "@/services/product.service";
import userService from "@/services/user.service";
import profileService from "@/services/profile.service";

const highchartDefault: Highcharts.Options = {
    chart: {
        type: "scatter"
    },
    title: {
        text: '',
    },
    tooltip: {
    },
    xAxis: {
        title: {}
    },
    yAxis: {
        title: {}
    },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
    },
    plotOptions: {
        scatter: {},
        area: {
            pointIntervalUnit: "day",
        },
        column: {
            // pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0,
            shadow: false
        }
    },
    series: [],
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 500,
                },
                chartOptions: {
                    legend: {
                        layout: "horizontal",
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
            },
        ],
    },
};

const defaultDate = new Date(2010, 0, 1);
const loess = regressionLoess();
const linear = regressionLinear();
const poly = regressionPoly();

function linearFromOrigin(data: any[]) {
    const xmax = Math.max(...data.map(a => a[0]));
    const xsum = sum(data.map(a => a[0]));
    const ysum = sum(data.map(a => a[1]));
    const slope = ysum / xsum;

    return [[0, 0], [xmax, xmax * slope]];
}

enum ChartType {
    acos_bid = 'ACoS/Bid',
    bid = 'Bid',
    acos = 'ACoS',
}

enum ChartTypeOption {
    Scatter = 'Scatter',
    TimeSeries = 'Time Series',
}

enum MatchTypeOption {
    Broad = 'BROAD',
    Phrase = 'PHRASE',
    Exact = 'EXACT',
}

const menuRef = ref<MenuComponent | null>(null)

export default defineComponent({
    props: {
        productId: {
            required: true,
            type: String,
        },
        keywordText: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            price: 999,
            targetAcos: 0.2,
            currentBid: undefined as number | undefined,
            orderDiff: 2,
            metrics: {} as BidRecordMap,
            dailyStatValues: [] as IKWDailyStat[],
            dailyBidValues: [] as any[],
            dateRange: metricsService.getDateRange(),
            chartOptions: cloneDeep(highchartDefault),
            chartOptions2: cloneDeep(highchartDefault),
            chartOptions3: cloneDeep(highchartDefault),
            randomize: false,
            weeklyPlot: false,
            chartType: ChartTypeOption.Scatter,
            chartTypeOptions: Object.values(ChartTypeOption),
            matchType: MatchTypeOption.Broad,
            matchTypeOptions: Object.values(MatchTypeOption),
            mouseMoveThrottle: throttle(() => this.mouseMove, 50),
            menuItems: [
                {
                    label: 'Information',
                    items: [
                        {
                            label: 'Bid History',
                            command: () => this.getBidHistories()
                        },
                        {
                            label: 'Budget History',
                            command: () => this.getBudgetHistories()
                        }
                    ]
                }] as MenuItem[],
        };
    },
    async created() {
        [this.price, this.targetAcos] = await Promise.all([
            targetMetricService.getProductPrice(this.productId) ?? 999,
            this.getTargetAcos()
        ]);
        await this.updateData();
    },
    mounted() {
        this.$watch(() => [this.dateRange, this.randomize], this.updateData);
        this.$watch(() => [this.dateRange, this.matchType], this.getKWData, { immediate: true });
        this.$watch(() => [this.chartType, this.weeklyPlot, this.dailyStatValues, this.dailyBidValues], () =>
            this.chartType == ChartTypeOption.Scatter ? this.generateScatterPlot() : this.generateTimeSeriesPlot());
    },
    computed: {
        dailyDays() {
            const dates = this.dailyStatValues.map(a => a.startTime).concat(this.dailyBidValues.map(a => a.item1));
            const minDate = min(dates);
            const maxDate = max(dates);
            return (minDate == undefined || maxDate == undefined) ? [] : eachDayOfInterval({
                start: minDate,
                end: maxDate,
            });
        },
        dailyStats() {
            const days = this.dailyDays;
            return days.map(d => this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime()));
        },
        dailyBids() {
            const days = this.dailyDays;
            return days.map(d => this.dailyBidValues.find(a => a.item1.getTime() == d.getTime()));
        },
        weeklyWeeks() {
            return uniqBy(this.dailyDays.map(a => startOfWeek(a, { weekStartsOn: 1 })), a => a.getTime());
        },
        weeklyImpressions() {
            return this.calWeeklySum(a => a.impressions);
        },
        weeklyClicks() {
            return this.calWeeklySum(a => a.clicks);
        },
        weeklyCTR() {
            const impressionArray = this.calWeeklySum(a => a.impressions)
            const clicksArray = this.calWeeklySum(a => a.clicks);
            return zipWith(impressionArray, clicksArray, (impressions, clicks) => clicks / impressions);
        },
        weeklyCVR() {
            const clicksArray = this.calWeeklySum(a => a.clicks);
            const conversionArray = this.calWeeklySum(a => a.attributedConversions_30d)
            return zipWith(clicksArray, conversionArray, (clicks, conversions) => clicks == 0 ? null : conversions / clicks);
        },
        multiCampaignType() {
            return uniqBy(this.dailyStatValues, a => a.campaignId).length > 1;
        }
    },
    methods: {
        async getTargetAcos() {
            const productInfo = await productService.getProductDetails(this.productId);
            if (productInfo.targetAcos) {
                return productInfo.targetAcos / 100;
            }

            const userInfo = userService.expectUserInfo();
            const settings = await profileService.getProfileSettings(userInfo.selectedProfileId);
            return settings?.defaultTargetAcos ? settings.defaultTargetAcos / 100 : 0.2;
        },
        async updateData() {
            this.metrics = await bidMetricsService.getKeywordBidMetrics(this.dateRange.value as IDateRange, this.productId, this.keywordText, this.randomize);
            // this.updateGraphs();
        },
        async getKWData() {
            this.dailyStatValues = await targetMetricService.getDailyStats(this.dateRange.value, this.productId, this.keywordText, this.matchType);
            this.dailyBidValues = await targetMetricService.getBids(this.dateRange.value, this.productId, this.keywordText, this.matchType);

            if (this.dailyStatValues.length) {
                const kwId = this.dailyStatValues[0].keywordId;
                this.currentBid = (await targetMetricService.getKeywordCurrentBid(this.productId, kwId)).bid;
            } else {
                this.currentBid = undefined;
            }
        },
        calcBid(stats: IKWDailyStat[], orderOffset: number = 0) {
            const unitPrice = this.price;
            const ratio = this.targetAcos * unitPrice;

            const cvr = Math.min(1, Math.max(0, this.calcConversionRateTotal(stats, orderOffset)));
            return cvr * ratio;

        },
        calcAcosTotal(stats: IKWDailyStat[]) {
            const sales = sum(stats.map(a => a.attributedSales_30d));
            const spend = sum(stats.map(a => a.cost));
            return spend == 0 ? 9.99 : (spend / sales);
        },
        calcConversionRateTotal(stats: IKWDailyStat[], orderOffset: number = 0) {
            const clicks = sum(stats.map(a => a.clicks));
            const conversions = sum(stats.map(a => a.attributedConversions_30d ?? 0)) + orderOffset;
            return clicks == 0 ? 0 : conversions / clicks;
        },
        calcConversionRate(stat: IKWDailyStat) {
            return stat.clicks == 0 ? undefined : (stat.attributedConversions_30d ?? 0) / stat.clicks;
        },
        calWeeklySum(fn: (stat: IKWDailyStat) => number) {
            const groupings = groupBy(
                this.dailyStats.filter(a => a != undefined),
                a => startOfWeek(a!.startTime, { weekStartsOn: 1 }).getTime());

            return Object.values(groupings).map(g => sum(g.map(a => fn(a!))));
        },
        calWeeklySumDup(fn: (stat: IKWDailyStat) => number) {
            const groupings = groupBy(
                this.dailyStats.filter(a => a != undefined),
                a => startOfWeek(a!.startTime, { weekStartsOn: 1 }).getTime());

            return Object.values(groupings).flatMap(g => {
                const stat = sum(g.map(a => fn(a!)));
                return g.map(a => [a!.startTime.getTime(), stat]);
            });
        },
        formatDay(date: Date) {
            return format(date, 'LLL d');
        },
        formatMoney(value?: number) {
            if (value == null) return '';
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },
        formatCount(value?: number) {
            if (value == null) return '';
            return value >= 1_000_000 ? (value / 1_000_000).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) + 'M' : value.toLocaleString("en-US");
        },
        formatPercentage(value?: number) {
            if (value == null) return '';
            value = Math.min(value, 9.99); // max 999%

            return value.toLocaleString('en-US', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        },
        borderClass(date?: Date) {
            return date?.getDay() == 0 ? 'border-right-1' : null;
        },
        async getBidHistories() {
            const keywordIds = Object.keys(this.metrics).map(a => a.split('_')[1]);
            modalService.show(BidHistoryModal, { keywordIds });
        },
        async getBudgetHistories() {
            const keywordIds = Object.keys(this.metrics).map(a => a.split('_')[1]);
            modalService.show(BudgetHistoryModal, { keywordIds });
        },
        generateScatterPlot() {
            const chartOptions = this.chartOptions;
            const chartOptions2 = this.chartOptions2!;

            const days = this.dailyDays;
            let lastBid: any | undefined;
            const array = days.map(d => {
                const stat = this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime());
                const bid = this.dailyBidValues.find(a => a.item1.getTime() == d.getTime());

                if (bid) {
                    lastBid = bid;
                }

                return [lastBid?.item2, stat?.impressions];
            }).filter(a => a[0] != undefined && a[1] != undefined);

            const array2 = days.map(d => {
                const stat = this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime());
                return [stat?.clicks, stat?.attributedConversions_30d];
            }).filter(a => a[0] != undefined && a[0] != 0);

            chartOptions.series = this.getScatterPlotSeries('impressions/bid', array);
            chartOptions2.series = this.getScatterPlotSeries('orders/clicks', array2, linearFromOrigin);

            const formatPercentage = this.formatPercentage;
            const fitSeries = chartOptions2.series[1] as Highcharts.SeriesLineOptions;
            fitSeries.dataLabels = {
                enabled: true,
                formatter: function () {
                    if (this.series.name == 'orders') return '';

                    const fraction = (this.y ?? 0) / (Number(this.x) ?? 0);
                    return !Number.isFinite(fraction) ? '' : formatPercentage(fraction);
                }
            };

            chartOptions.chart!.type = "scatter";
            chartOptions.title!.text = "Impressions/day/Bid";

            chartOptions.xAxis = {
                title: {
                    text: "Bid",
                },
                type: "linear",
                crosshair: false,
                min: 0,
                max: 4,
            }

            chartOptions.yAxis = {
                type: 'linear',
                title: {
                    text: "Impressions/day"
                },
                min: 0,
                max: Math.max(...array.map(a => a[1])) * 1.2
            };

            chartOptions2.xAxis = {
                type: 'linear',
                title: {
                    text: "Clicks",
                },
                min: 0,
                max: Math.max(5, ...array2.map(a => a[0]! * 1.2)),
            };
            chartOptions2.yAxis = {
                type: 'linear',
                title: {
                    text: "Orders",
                },
                min: 0,
                max: 5,
            };
            chartOptions2.title!.text = "Orders/Clicks";
        },
        generateTimeSeriesPlot() {
            const chartOptions = this.chartOptions;
            const chartOptions2 = this.chartOptions2!;

            const weeklyStats = true;

            const days = this.dailyDays;
            const impressionsArray = days.map(d => {
                const stat = this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime());
                return [stat?.startTime.getTime(), stat?.impressions];
            }).filter(a => a[0] != undefined);

            const impressionArray2 = this.calWeeklySumDup(a => a.impressions);
            const bidArray = this.dailyBidValues.map(a => [a.item1.getTime(), a.item2]);

            chartOptions.title!.text = "Impressions/week/Bid";
            chartOptions.chart!.type = "column";
            chartOptions.series =
                [
                    this.getBarPlotSeries('impressions', this.weeklyPlot ? impressionArray2 : impressionsArray)[0],
                    { ...this.getNormalPlotSeries("bid", bidArray)[0], yAxis: 1 }
                ];

            chartOptions.xAxis = {
                type: 'datetime',
                min: this.dailyStatValues[0].startTime.getTime(),
                max: this.dailyStatValues[this.dailyStatValues.length - 1].startTime.getTime(),
            };

            chartOptions.yAxis = [
                {
                    title: {
                        text: 'Impressions'
                    },
                    min: null,
                    max: null,
                },
                {
                    title: {
                        text: 'Bid $'
                    },
                    opposite: true,
                    min: 0,
                    max: 5
                }
            ];

            const ordersArray = days.map(d => {
                const stat = this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime());
                return [stat?.startTime.getTime(), stat?.attributedConversions_30d];
            }).filter(a => a[0] != undefined);

            const ordersArray2 = this.calWeeklySumDup(a => a.attributedConversions_30d);

            const clicksArray = days.map(d => {
                const stat = this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime());
                return [stat?.startTime.getTime(), (stat?.clicks ?? 0) - (stat?.attributedConversions_30d ?? 0)];
            }).filter(a => a[0] != undefined);

            const clicksArray2 = this.calWeeklySumDup(a => a.clicks - a.attributedConversions_30d);

            chartOptions2.chart!.type = "column";
            chartOptions2.series = [
                this.getBarPlotSeries('clicks', this.weeklyPlot ? clicksArray2 : clicksArray)[0],
                this.getBarPlotSeries('orders', this.weeklyPlot ? ordersArray2 : ordersArray)[0]
            ];
            chartOptions2.plotOptions!.column!.stacking = 'normal';

            chartOptions2.xAxis = {
                type: 'datetime',
                min: this.dailyStatValues[0].startTime.getTime(),
                max: this.dailyStatValues[this.dailyStatValues.length - 1].startTime.getTime(),
            };
            chartOptions2.yAxis = {
                title: {
                    text: 'Amount'
                },
                min: null,
                max: null,
            };

            // Deviation Plot
            const expectedAcos = this.calcConversionRateTotal(this.dailyStatValues, 0);
            const diffArray = days.map(d => {
                const stat = this.dailyStatValues.find(a => a.startTime.getTime() == d.getTime());
                if (stat == undefined) return undefined;

                const expectedOrders = (stat?.clicks ?? 0) * expectedAcos;
                return round((stat?.attributedConversions_30d ?? 0) - expectedOrders, 2);
            }).filter(a => a != undefined);

            const diffGroupings = groupBy(
                this.dailyStats.filter(a => a != undefined),
                a => startOfWeek(a!.startTime, { weekStartsOn: 1 }).getTime());

            const diffArray2 = Object.values(diffGroupings).flatMap(g => {
                const clicks = sum(g.map(a => a?.clicks ?? 0));
                const conversion = sum(g.map(a => a?.attributedConversions_30d ?? 0));
                const stat = round(conversion - (clicks * expectedAcos), 2);
                return g.map(a => [a!.startTime.getTime(), stat]);
            });

            const chartOptions3 = this.chartOptions3;
            chartOptions3.chart!.type = "column";
            chartOptions3.series = this.getBarPlotSeries("diff", this.weeklyPlot ? diffArray2 : diffArray);
            chartOptions3.plotOptions!.column!.color = '#00bb00';
            chartOptions3.plotOptions!.column!.negativeColor = '#ff2020';
            chartOptions3.plotOptions!.column!.borderColor = 'silver';

            const yAxis3 = (chartOptions3.yAxis as YAxisOptions);
            yAxis3.startOnTick = false;
            yAxis3.endOnTick = false;
            yAxis3.tickPositions = [0];

            const xAxis3 = (chartOptions3.xAxis as XAxisOptions);
            xAxis3.labels = { enabled: false };
            xAxis3.tickPositions = [];
        },
        // updateGraphs() {
        //     if (this.chartType == ChartTypeOption.Scatter) {
        //         // this.chartOptions2 = null;
        //         this.updateGraph(ChartType.acos_bid);
        //     } else if (this.chartType == ChartTypeOption.TimeSeries) {
        //         this.chartOptions2 = cloneDeep(highchartDefault);
        //         this.updateGraph(ChartType.bid, this.chartOptions);
        //         this.updateGraph(ChartType.acos, this.chartOptions2);
        //     }
        // },
        // updateGraph(chartType: ChartType, chartOptions?: Highcharts.Options) {
        //     chartOptions = chartOptions ?? this.chartOptions;

        //     const groupings = Object.entries(this.metrics) as [string, BidRecordTable][];

        //     let getX: (v: IBidGraphItem, date: string) => any;
        //     let getY: (v: IBidGraphItem, date: string) => any;
        //     switch (chartType) {
        //         case ChartType.acos_bid: // TODO: bid -> impressions | clicks | sales
        //             getX = v => v.bid;
        //             getY = v => v.acos;
        //             break;
        //         case ChartType.acos:
        //             getX = (v, d) => parse(d, "yyyyMMdd", defaultDate).getTime();
        //             getY = v => v.acos;
        //             break;
        //         case ChartType.bid:
        //             getX = (v, d) => parse(d, "yyyyMMdd", defaultDate).getTime();
        //             getY = v => v.bid;
        //             break;
        //     }

        //     chartOptions.series = groupings.flatMap(([name, data]) => {
        //         const array = Object.entries(data).map(([date, value]) => ([
        //             getX(value, date),
        //             getY(value, date),
        //         ]));

        //         return chartType == ChartType.acos_bid ?
        //             this.getScatterPlotSeries(name, array) :
        //             this.getNormalPlotSeries(name, array);
        //     });
        //     this.updateChartOptions(chartType, chartOptions);
        // },
        getScatterPlotSeries(name: string, data: any[], regressionFunc = loess) {
            const series: Highcharts.SeriesScatterOptions = {
                name: name,
                type: "scatter",
                data: data,
            };

            const result = regressionFunc(data);
            const series2: Highcharts.SeriesLineOptions = {
                name: `${name} - fit`,
                type: "line",
                data: result,
                dashStyle: 'Dot',
                label: {
                    enabled: false,
                }
            };

            return [series, series2];
        },
        getNormalPlotSeries(name: string, data: any[]) {
            const series: Highcharts.SeriesLineOptions = {
                name: name,
                type: "line",
                data: data,
                dashStyle: 'Solid',
            };

            return [series];
        },
        getBarPlotSeries(name: string, data: any[]) {
            const series: Highcharts.SeriesColumnOptions = {
                name: name,
                type: "column",
                data: data,
                dashStyle: 'Solid',
            };

            return [series];
        },
        updateChartOptions(chartType: ChartType, chartOptions?: Highcharts.Options) {
            chartOptions = chartOptions ?? this.chartOptions;

            const value = chartType;
            const startDate = this.dateRange.value.start;
            const endDate = this.dateRange.value.end;

            const xaxis = chartOptions.xAxis as Highcharts.XAxisOptions;
            const yaxis = chartOptions.yAxis as Highcharts.YAxisOptions;
            chartOptions.title!.text = value.toString();

            switch (value) {
                case ChartType.acos_bid:
                    xaxis.title!.text = "Bid";
                    yaxis.title!.text = "ACoS";
                    xaxis.type = "linear";
                    xaxis.crosshair = false;
                    xaxis.min = 0, xaxis.max = 4;
                    yaxis.min = 0, yaxis.max = 1;
                    break;
                case ChartType.bid:
                case ChartType.acos:
                    xaxis.title!.text = "Date";
                    yaxis.title!.text = value.toString();
                    xaxis.type = "datetime";
                    xaxis.crosshair = true;
                    xaxis.min = startDate.getTime(), xaxis.max = endDate.getTime();
                    if (value == ChartType.bid) {
                        yaxis.min = 0, yaxis.max = 4;
                    } else if (value == ChartType.acos) {
                        yaxis.min = 0, yaxis.max = 1;
                    }
                    break;
            }
        },
        mouseMove: throttle((event: any) => {
            // if (!this.chartOptions2) {
            //     return;
            // }

            for (const chart of Highcharts.charts) {
                if (!chart) {
                    continue;
                }

                // Find coordinates within the chart
                const newEvent = chart.pointer.normalize(event);
                const point = chart.series[0].searchPoint(newEvent, true);
                if (point) {
                    point?.select(event);
                    chart.xAxis[0].drawCrosshair(newEvent, point);
                    chart.tooltip.refresh(point, newEvent);
                }
            }
        }, 100),
        showMenu(event: Event) {
            menuRef.value?.toggle(event);
        }
    },
});
</script>

<style scoped>
th,
td {
    text-align: right;
}
</style>
