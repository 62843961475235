import type { PlotOptions } from "@observablehq/plot";
import * as Plot from "@observablehq/plot";
import { h, withDirectives, type PropType, defineComponent } from "vue";

export default defineComponent({
  props: {
    options: { type: Object as PropType<PlotOptions>, required: true }
  },
  render() {
    const options = this.options;
    return withDirectives(h("div"), [
      [
        {
          mounted(el: HTMLElement) {
            el.append(Plot.plot(options));
          },
          beforeUpdate(el: HTMLElement) {
            el.replaceChildren(Plot.plot(options));
          }
        }
      ]
    ]);
  }
});