import type { SQSEvent } from "@/models/event";
import type { ProfileItem } from "@/models/profile-item";
import axios from "axios";

function fixDate(property: Date): Date {
    return property && new Date(property);
}

export default {
    async triggerDailyJobs() {
        await axios.post("/api/advanced/triggerDailyJobs");
    },
    async processHistoricalData(days: number) {
        await axios.post("/api/advanced/processHistoricalData", { days });
    },
    async redoCache() {
        await axios.post("/api/advanced/redoCache");
    },
    async triggerBidUpdates() {
        await axios.post("/api/advanced/triggerBidUpdates");
    },
    async fixSkew() {
        await axios.post("/api/advanced/fixSkew");
    },
    async triggerDayparting() {
        await axios.post("/api/advanced/triggerDayparting");
    },
    async queueDailyReports(profile: ProfileItem) {
        await axios.post("/api/reports/daily", profile);
    },
    async querySQSEvents() {
        const response = await axios.get<SQSEvent[]>("/api/advanced/events");
        return response.data.map((a) => {
            a.date = fixDate(a.date);
            return a;
        });
    },
};
