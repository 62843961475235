<template>
    <div class="flex flex-nowrap align-items-start">
        <div v-if="productImage" class="p-6 wip mr-4">
            <img :src="productImage">
        </div>
        <KPIList v-model:attribute="attribute" :metrics="metrics" :is-loading="isLoading" />
    </div>

    <MetricsChart :title="chartTitle" :attribute="attribute" :type="metricItemType" :data="data" :date-range="dateRange"
        :annotations="annotations" />

    <div class="mt-6">
        <Button v-if="topKeywords" label="Top Sales" title="Select top keywords by sales" class="p-button-outlined"
            @click="selectTop()" />

        <AutoComplete v-model="selectedKeywords" placeholder="Select keywords..." :multiple="true"
            :suggestions="filteredAutoComplete" @complete="searchKeywords($event)"
            @item-select="addKeyword($event.value)" @item-unselect="removeKeyword($event.value)" />

        <div class="inline ml-3">
            <Button title="Clear" icon="pi pi-times" class="p-button-rounded p-button-outlined"
                @click="clearKeywords()" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import metricsService from "@/services/metrics.service";
import type { IDateRangeSelection, IGraphItem, RecordMap } from "@/services/metrics.service";
import kpiService, { commonKPIs } from "@/services/kpi.service";
import KPIList from "./KPIList.vue";
import MetricsChart, { type IDataGrouping, type ChartAnnotation } from "./MetricsChart.vue";
import AutoComplete, { type AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import Button from 'primevue/button';
import searchService from "@/services/search.service";
import { isEqual } from "lodash-es";
import metrics2Service from "@/services/metrics2.service";
import axios from "axios";
import { parse } from "date-fns";

export default defineComponent({
    components: {
        KPIList,
        MetricsChart,
        AutoComplete,
        Button,
    },
    props: {
        id: { type: String, required: true },
        productImage: { type: String, required: false },
        dateRange: { type: Object as PropType<IDateRangeSelection>, required: true },
        autocomplete: { type: Array as PropType<string[]>, default: () => [] },
        topKeywords: { type: Array as PropType<string[]>, required: false, default: undefined },
        keywordData: { type: Object as PropType<RecordMap>, required: true, default: () => ({}) },
    },
    data() {
        return {
            isLoading: false,
            attribute: "impressions",
            metrics: Object.values(commonKPIs),
            data: [] as IDataGrouping[],
            selectedKeywords: [] as string[],
            filteredAutoComplete: [] as string[],
            productData: [] as IGraphItem[],
            lastProductData: [] as IGraphItem[],
            annotations: [] as ChartAnnotation[],
        };
    },
    computed: {
        chartTitle() {
            let identifier: string;
            if (this.selectedKeywords.length == 0) {
                identifier = "Product";
            } else if (isEqual(this.selectedKeywords, this.topKeywords)) {
                identifier = "Top Keywords";
            } else {
                identifier = "Keywords";
            }

            return `${identifier} by ${this.attribute}`;
        },
        metricItemType() {
            return Object.values(commonKPIs).find(a => a.attribute == this.attribute)?.type;
        }
    },
    watch: {
        id: {
            async handler() {
                const now = new Date();
                const annotationData = await axios.get<{ date: string, message: string }[]>(`/api/products/${this.id}/annotations`);
                this.annotations = annotationData.data.map(a => ({ date: parse(a.date.split("T")[0], "yyyy-MM-dd", now), text: a.message }));
            },
            immediate: true
        },
        keywordData() { this.updateGraph(); },
        dateRange: {
            async handler() {
                // const data = await metricsService.getProductData(this.dateRange.value, this.id);
                const data = await metrics2Service.getOneProductData(this.dateRange.value, this.id);
                this.productData = metricsService.convertToGraphData(data);

                // const lastData = await metricsService.getProductData(this.dateRange.prev!, this.id);
                const lastData = await metrics2Service.getOneProductData(this.dateRange.prev, this.id);
                this.lastProductData = metricsService.convertToGraphData(lastData);

                this.updateGraph();

                kpiService.updateCommonKPIs(data.total, lastData.total);
                this.metrics = { ...this.metrics }; // force kpi's to refresh
            },
            immediate: true
        },
        selectedKeywords: {
            handler(keywords: string[]) {
                this.toggleProductData(keywords.length == 0);
            },
            immediate: true
        }
    },
    methods: {
        async updateGraph() {
            if (this.selectedKeywords.length == 0) {
                this.toggleProductData(true);
            } else {
                this.selectedKeywords.forEach(a => this.addKeyword(a))
            }
        },
        searchKeywords(event: AutoCompleteCompleteEvent) {
            this.filteredAutoComplete = searchService.filterSuggestions(this.autocomplete, event.query);
        },
        async toggleProductData(show: boolean) {
            const key = `Product ${this.id}`;
            const lastKey = `Product ${this.id} - Last`;
            if (show) {
                this.data = [{ name: key, data: this.productData }, { name: lastKey, data: this.lastProductData }];
            } else {
                this.data = [...this.data.filter(a => a.name != key && a.name != lastKey)];
            }
        },
        async addKeyword(keyword: string) {
            const productData = (this.keywordData ?? {})[keyword];
            const data = metricsService.convertToGraphData(productData ?? {});

            this.removeKeyword(keyword);
            this.data.push({
                name: keyword,
                data,
            });
        },
        removeKeyword(keyword: string) {
            this.data = [...this.data.filter(a => a.name != keyword)];
        },
        selectTop() {
            this.selectedKeywords = this.topKeywords ?? [];
            this.updateGraph();
        },
        clearKeywords() {
            this.selectedKeywords = [];
            this.updateGraph();
        }
    },
});
</script>

<style scoped></style>
