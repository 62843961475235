<template>
    <Dialog :modal="true" v-model:visible="visible" :closable="true" :class="'product-settings-modal'">
        <template #header>
            <div>
                <span class="text-2xl font-medium" style="color: #ffa03e">Override Product Options</span>
            </div>
        </template>
        <div>
            <Message
                v-if="status === undefined || maxBid === undefined || targetAcos === undefined || grossMargin === undefined"
                class="text-xl mb-4" severity="warn">Some settings differ between selected products
            </Message>
            <div class="flex white-space-nowrap flex-nowrap">
                <div class="flex flex-column">
                    <label class="pl-0 pt-0" for="status">
                        Status
                        <i v-if="status === undefined" class="pi pi-exclamation-triangle warn"></i>
                    </label>
                    <div class="flex">
                        <Dropdown v-model="status" :options="statuses" optionLabel="name" placeholder="Select a Status"
                            class="w-full md:w-14rem" />
                    </div>
                </div>
                <div class="flex flex-column ml-4">
                    <label class="pl-0 pt-0" for="targetAcos">
                        Target ACoS
                        <i v-if="targetAcos === undefined" class="pi pi-exclamation-triangle warn"></i>
                    </label>
                    <InputNumber inputId="targetAcos" v-model="targetAcos" mode="decimal" :minFractionDigits="2" suffix="%"
                        v-tooltip.bottom="'What is an ACos?'" class="small-input" />
                </div>
                <div class="flex flex-column ml-4">
                    <label class="pl-0 pt-0" for="maxBid">
                        Max Bid
                        <i v-if="maxBid === undefined" class="pi pi-exclamation-triangle warn"></i>
                    </label>
                    <InputNumber inputId="maxBid" v-model="maxBid" mode="decimal" :minFractionDigits="2" prefix="$"
                        v-tooltip.bottom="'What is a Max Bid?'" class="small-input" />
                </div>
                <div class="flex flex-column ml-4">
                    <label class="pl-0 pt-0" for="grossMargin">
                        Gross Margin
                        <i v-if="grossMargin === undefined" class="pi pi-exclamation-triangle warn"></i>
                    </label>
                    <InputNumber inputId="grossMargin" v-model="grossMargin" mode="decimal" :minFractionDigits="2"
                        suffix="%" v-tooltip.bottom="'What is Gross Margin?'" class="small-input" />
                </div>
            </div>
            <div class="flex justify-content-end mt-4">
                <Button @click="cancel()" label="Cancel" icon="pi pi-ban" class="p-button-secondary" :loading="saving" />
                <Button @click="save()" label="Save" icon="pi pi-save" class="ml-2" :loading="saving" />
            </div>
        </div>
    </Dialog>
</template>


<script lang="ts" setup>
import { type ProductItem, AmazonStateType } from "@/models/product-item";
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Dialog from "primevue/dialog";
import Message from 'primevue/message';
import Button from 'primevue/button';
import ModalService from "@/services/modal.service";
import ProductService from '@/services/product.service';

</script>


<script lang="ts">
import { defineComponent, type PropType } from "vue";

interface ProductStatus {
    name: string;
    value: AmazonStateType;
}

// null: value is not set
// undefined: there is a conflict
type TriState<T> = T | null | undefined;

var enabledStatus: ProductStatus = { name: 'Managed by Adjoli', value: AmazonStateType.enabled };
var pausedStatus: ProductStatus = { name: 'Pause Adjoli Management', value: AmazonStateType.paused };

export default defineComponent({
    data() {
        return {
            visible: true,
            targetAcos: null as TriState<number>,
            maxBid: null as TriState<number>,
            grossMargin: null as TriState<number>,
            status: null as TriState<ProductStatus>,
            statuses: [enabledStatus, pausedStatus] as ProductStatus[],
            saving: false,
        };
    },
    props: {
        products: { type: Object as PropType<ProductItem[]>, required: true }
    },
    async created() {
        this.setState(x => this.statuses.find(y => y.value === x.state), x => this.status = x);
        this.setState(x => x.targetAcos, x => this.targetAcos = x);
        this.setState(x => x.grossMargin, x => this.grossMargin = x);
        this.setState(x => x.maxBid, x => this.maxBid = x);
    },
    methods: {
        setState<T>(
            selector: (a: ProductItem) => T,
            valueSetter: (a: TriState<T>) => void) {
            var allStatuses = new Set(this.products.map(selector));
            if (allStatuses.size > 1) {
                valueSetter(undefined);
            } else if (allStatuses.size === 1) {
                valueSetter(allStatuses.values().next().value);
            } else {
                valueSetter(null);
            }
        },
        cancel() {
            if (!this.saving) {
                ModalService.close();
            }
        },
        async save() {
            if (this.saving) return;
            this.saving = true;
            try {

                // Send the updated settings to the server
                await ProductService.updateSettings(this.products.map(x => ({
                    ...x,
                    state: this.status!.value,
                    targetAcos: this.targetAcos ?? undefined,
                    grossMargin: this.grossMargin ?? undefined,
                    maxBid: this.maxBid ?? undefined,
                })));

                // Save the settings to the local instance
                this.products.forEach(x => {
                    x.state = this.status!.value;
                    x.targetAcos = this.targetAcos;
                    x.grossMargin = this.grossMargin;
                    x.maxBid = this.maxBid;
                });

                // Finally close the modal
                ModalService.close();

            } finally {
                this.saving = false;
            }
        }
    }
});
</script>

<style lang="scss">
.p-component .small-input>input {
    width: 8rem;
}
</style>