<template>
    <h1>{{ title }}</h1>
    <LoginButton :login-url="loginUrl" class="amzn-login-btn" />
</template>

<script lang="ts" setup>
import LoginButton from "@/components/LoginButton.vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";
import userService from "@/services/user.service";
import { OnboardStep, layoutStep } from "@/services/layout.service";

interface Data {
    title: string;
    loginUrl: string;
}
export default defineComponent({
    data(): Data {
        return {
            title: "",
            loginUrl: "",
        };
    },
    async created() {
        const userInfo = await userService.getUserInfo();
        if (!userInfo) {
            this.title = "Login to Amazon";
            this.loginUrl = "Auth/Login";

            layoutStep.value = OnboardStep.Login;
        } else {
            throw new Error(
                "Login page should not have been accessed in this state."
            );
        }
    },
});
</script>

<style scoped>
.amzn-login-btn {
    display: inline-block;
    margin-left: 20px;
}
</style>
