import type { UserDetails } from "@/models/user-details";
import axios from "axios";

export default {
    async getUserInfo() {
        try {
            const response = await axios.get<UserDetails>("/Auth/GetUserInfo");
            return response.data;
        } catch (error) {
            return null;
        }
    },
};
