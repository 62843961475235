import type { ActionZoneProductRow, ActionZoneRow, ActionZoneUpdateModel } from "@/models/action-zone";
import axios from "axios";

export default {
    async getProductRows(type: string, showAll: boolean) {
        const request = axios.get<ActionZoneProductRow<ActionZoneRow>[]>(`/api/action-zone?type=${type}&showAll=${showAll}`);
        var response = await request;
        // Include client side values before returning data
        response.data.forEach(productRow => {
            for (var actionRow of productRow.actionRows) {
                actionRow.productId = productRow.productId;
                actionRow.isSelected = false;
            }
            productRow.filteredActionRows = [];
        });
        return response.data;
    },
    async ignoreRows(models: ActionZoneUpdateModel[]) {
        await axios.post(`/api/action-zone/ignore`, models);
    },
    async approveRows(models: ActionZoneUpdateModel[]) {
        await axios.post(`/api/action-zone/approve`, models);
    },
};
