<template>
    <div class="shadow-3" v-if="chartOptions">
        <Chart :options="chartOptions" :inline="true" />
    </div>
</template>

<script lang="ts" setup>
</script>

<script lang="ts">
import type Highcharts from "highcharts";
import { defineComponent, type PropType } from 'vue';
import type { IGraphItem, RecordTable } from "@/services/metrics.service";
import Chart from "@/components/Chart.vue";
import { formatValue, MetricType } from "@/services/kpi.service";


interface Data {
    chartOptions?: Highcharts.Options;
}

function initChart(): Highcharts.Options {
    return {
        chart: {
            type: 'area',
            width: 700,
            height: 250,
            style: {
                fontFamily: 'Inter, Helvetica, Arial, sans-serif',
            }
        },
        plotOptions: {
            area: {
                label: { enabled: false }
            }
        },
        legend: {
            enabled: false,
        },
        series: [],
        xAxis: {
            labels: {
                enabled: false,
            },
            tickLength: 0,
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                enabled: false,
            },
        },
        tooltip: {
            valueDecimals: 2
        }
    };
}

export enum DataAttribute {
    AdSales = 'adSales',
    AdSpend = 'adSpend',
    ACOS = 'acos',
    ROAS = 'roas',
}

function getValue(item: IGraphItem, type: DataAttribute) {
    switch (type) {
        case DataAttribute.AdSales: return item.attributedSales30d;
        case DataAttribute.AdSpend: return item.cost;
        case DataAttribute.ACOS: return item.acos;
        case DataAttribute.ROAS: return item.cost == 0 ? 0 : item.attributedSales30d / item.cost;
    }
}

export default defineComponent({
    components: {
        Chart,
    },
    props: {
        type: { type: String as PropType<DataAttribute>, required: true },
        seriesData: { type: Object as PropType<RecordTable>, required: true },
        prevSeriesData: { type: Object as PropType<RecordTable>, required: true },
    },
    data(): Data {
        return {
            chartOptions: undefined,
        };
    },
    mounted() {
        this.$watch(() => [this.seriesData, this.prevSeriesData, this.type], () => (this.chartOptions = this.calcChartOptions()), { immediate: true });
    },
    methods: {
        calcChartOptions() {
            const chart = initChart();
            const seriesArray = Object.entries(this.seriesData).filter(a => a[0] != "total");

            const seriesData = seriesArray.map(([_key, item]) => [item.date.getTime(), getValue(item, this.type)]);
            const series: Highcharts.SeriesAreaOptions = {
                type: 'area',
                data: seriesData,
            };

            chart.title = { text: this.title, align: "left" };
            chart.subtitle = this.calSubtitle();
            chart.series = [series];
            return chart;
        },
        calSubtitle(): Highcharts.SubtitleOptions {
            const item = this.seriesData['total'];
            const prevItem = this.prevSeriesData['total'];

            const totalValue = getValue(item, this.type);
            const prevtotalValue = getValue(prevItem, this.type);

            const diff = ((totalValue ?? 0) / (prevtotalValue ?? 0)) - 1;
            const formattedDiff = Math.abs(diff).toLocaleString('en-US', {
                style: 'percent',
                maximumFractionDigits: 0
            });

            const negateDiff = [DataAttribute.AdSpend, DataAttribute.ACOS].includes(this.type);
            const up = negateDiff ? !(diff > 0) : (diff > 0);
            const diffColor = up ? '#6ebe71' : 'red';

            return {
                text: `
                <span style="font-size: 1rem">
                    <span style="color: #fec05e; font-size: 1.5rem">${this.formatValue(totalValue)}</span> 
                    <span style="color: ${diffColor}">${(diff > 0) ? '↑' : '↓'}${formattedDiff}</span>
                    <span style="color: #9b9b9b; font-size: 1rem">${this.formatValue(prevtotalValue)}<span style="font-size: 0.75rem">&nbsp;previous&nbsp;period</span></span> 
                </span>`,
                align: "left",
                style: {
                    fontSize: '1.5rem'
                }
            }
        },
        formatValue(value: number | null) {
            return formatValue(this.formatType, value ?? undefined);
        },
    },
    computed: {
        title() {
            switch (this.type) {
                case DataAttribute.AdSales: return 'Ad Sales';
                case DataAttribute.AdSpend: return 'Ad Spend';
                case DataAttribute.ACOS: return 'ACoS';
                case DataAttribute.ROAS: return 'ROAS';
            }
        },
        formatType() {
            switch (this.type) {
                case DataAttribute.AdSales: return MetricType.Money;
                case DataAttribute.AdSpend: return MetricType.Money;
                case DataAttribute.ACOS: return MetricType.Percentage;
                case DataAttribute.ROAS: return MetricType.Percentage;
            }
        }
    }
});
</script>
    