<template>
    <template v-if="!isObject(one(data))">{{ one(data) }}</template>
    <table v-else>
        <thead>
            <tr>
                <th v-for="(item, index) in keys(one(data))" :key="index">{{ item }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td v-for="(item, index) in values(one(data))" :key="index">{{ item }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const toString = new Object().toString;

export default defineComponent({
    props: {
        data: { type: Object, required: true },
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        one(object: any) {
            return Array.isArray(object) && object.length == 1 ? object[0] : object;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        keys(object: any) {
            return Object.keys(object);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values(object: any) {
            return Object.values(object);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isObject(object: any) {
            return object.toString == toString || Array.isArray(object);
        },
    },
});
</script>

<style scoped>
table {
    border: 1px solid black;
}
</style>
